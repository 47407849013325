import { getStoreInfo, getAdSource } from '@/projects/mall/api/http.js';

export default {
  namespaced: true,
  state: {
    // 公共设置属性
    commonProperty: {},
    // 主题色配置
    themeStyle: {
      // 登录logo图片地址
      logoUrl: 'https://img.jk.com/mall/default_logo.png',
      // 标签背景颜色
      labelBackgroundColour: '#ECFAF1',
      // 标签边框颜色
      labelFrameColour: '#DBF5E5',
      // 标签文字颜色
      labelWordColour: '#44CC77',
      // 按钮一背景颜色
      firstButtonBackgroundColour: '#44CC77',
      // 按钮一边框颜色
      firstButtonFrameColour: '#3CB469',
      // 按钮一文字颜色
      firstButtonWordColour: '#FFFFFF',
      // 按钮二背景颜色
      secondButtonBackgroundColour: '#ECFAF1',
      // 按钮二边框颜色
      secondButtonFrameColour: '#DBF5E5',
      // 按钮二文字颜色
      secondButtonWordColour: '#44CC77',
      // 提示横幅背景色
      tipBackgroundColour: '#ECFAF1',
      // 提示横幅正文颜色
      tipWordColour: '#333333',
      // 提示辅助文字颜色
      tipAuxiliaryWordColour: '#999999',
      // 提示高亮文字颜色
      tipHighlightWordColour: '#FFA00C',
      // 文字常规样式颜色
      wordNormalColour: '#44CC77',
      // 图标颜色
      iconColour: '#44CC77',
      // 控件常规样式颜色
      controlNormalColour: '#44CC77',
      // tab1图片地址
      tabFirstUrl: 'https://img.jk.com/mall/home_tab1.png',
      // tab2图片地址
      tabSecondUrl: 'https://img.jk.com/mall/home_tab2.png',
      // tab3图片地址
      tabThirdUrl: 'https://img.jk.com/mall/home_tab3.png',
      // tab4图片地址
      tabFourthUrl: 'https://img.jk.com/mall/home_tab4.png',

      // 登录logo图片地址
      '--logoUrl': 'https://img.jk.com/mall/default_logo.png',
      // 标签背景颜色
      '--labelBackgroundColour': '#ECFAF1',
      // 标签边框颜色
      '--labelFrameColour': '#DBF5E5',
      // 标签文字颜色
      '--labelWordColour': '#44CC77',
      // 按钮一背景颜色
      '--firstButtonBackgroundColour': '#44CC77',
      // 按钮一边框颜色
      '--firstButtonFrameColour': '#3CB469',
      // 按钮一文字颜色
      '--firstButtonWordColour': '#FFFFFF',
      // 按钮二背景颜色
      '--secondButtonBackgroundColour': '#ECFAF1',
      // 按钮二边框颜色
      '--secondButtonFrameColour': '#DBF5E5',
      // 按钮二文字颜色
      '--secondButtonWordColour': '#44CC77',
      // 提示横幅背景色
      '--tipBackgroundColour': '#ECFAF1',
      // 提示横幅正文颜色
      '--tipWordColour': '#333333',
      // 提示辅助文字颜色
      '--tipAuxiliaryWordColour': '#999999',
      // 提示高亮文字颜色
      '--tipHighlightWordColour': '#FFA00C',
      // 文字常规样式颜色
      '--wordNormalColour': '#44CC77',
      // 图标颜色
      '--iconColour': '#44CC77',
      // 控件常规样式颜色
      '--controlNormalColour': '#44CC77',
      // tab1图片地址
      '--tabFirstUrl': 'https://img.jk.com/mall/home_tab1.png',
      // tab2图片地址
      '--tabSecondUrl': 'https://img.jk.com/mall/home_tab2.png',
      // tab3图片地址
      '--tabThirdUrl': 'https://img.jk.com/mall/home_tab3.png',
      // tab4图片地址
      '--tabFourthUrl': 'https://img.jk.com/mall/home_tab4.png'
    },
    pageSource: '',
    storeInfos: {},
    defaultSearchAdvert: []
  },
  actions: {
    async getStoreInfos({ commit }, params) {
      try {
        const { data = {} } = await getStoreInfo(params);
        console.log(data, 'data');
        commit('UPDATE_STORE_INFO', data.length ? data[0] : {});
      } catch (error) {
        commit('UPDATE_STORE_INFO', {});
      }
    },
    async getDefaultSearchAdvert({ state, commit }) {
      console.log('getDefaultSearchAdvert', state.defaultSearchAdvert, !state.defaultSearchAdvert);
      try {
        if (!state.defaultSearchAdvert || state.defaultSearchAdvert.length == 0) {
          // 2-h5,3-app，5-支付宝，7-微信小程序
          const params = {
            platform: 2,
            pageCode: 'SEARCH',
            adCode: '24'
          };
          const { data = [] } = await getAdSource(params);
          const defaultList =
            data.filter(a => a.code == 24).length > 0
              ? data.filter(a => a.code == 24)[0].adSourceVOList.splice(0, 1)
              : [];
          commit('SET_DEFAULT_SEARCH_ADVERT', defaultList);
        }
      } catch (error) {
        commit('SET_DEFAULT_SEARCH_ADVERT', []);
      }
    }
  },
  mutations: {
    // isDefaultTheme: 是否使用默认主题色配置
    SET_COMMON_PROPERTY(state, { data = {}, isDefaultTheme }) {
      state.commonProperty = data;
      if (!isDefaultTheme) {
        Object.keys(state.themeStyle).forEach(key => {
          if (!data[key]) {
            switch (key) {
              case 'logoUrl':
                data[key] = 'https://img.jk.com/mall/default_logo.png';
                break;
              case 'tabFirstUrl':
                data[key] = 'https://img.jk.com/mall/home_tab1.png';
                break;
              case 'tabSecondUrl':
                data[key] = 'https://img.jk.com/mall/home_tab2.png';
                break;
              case 'tabThirdUrl':
                data[key] = 'https://img.jk.com/mall/home_tab3.png';
                break;
              case 'tabFourthUrl':
                data[key] = 'https://img.jk.com/mall/home_tab4.png';
                break;
            }
          }
          if (!key.startsWith('--')) {
            state.themeStyle[key] = data[key];
            state.themeStyle[`--${key}`] = data[key];
          }
        });
      }
    },
    SET_PAGESOURCE(state, payload) {
      console.warn(payload, 'SET_PAGESOURCE');
      state.pageSource = payload;
    },
    UPDATE_STORE_INFO(state, payload) {
      console.log(payload, 'UPDATE_STORE_INFO');
      state.storeInfos = { ...payload };
    },
    SET_DEFAULT_SEARCH_ADVERT(state, payload) {
      console.log(payload, 'SET_DEFAULT_SEARCH_ADVERT');
      state.defaultSearchAdvert = payload;
    }
  }
};
