const  dsBridge = window.dsBridge;

export default {
    //同步调用
    callSyn(name, data, callback) {
        if(callback){
            var res = dsBridge.call(name, data);
            callback(res);
        }else{
            dsBridge.call(name, data);
        }
    },
    //异步调用
    callASyn(name, data, callback) {
        dsBridge.call(name, data, callback);
    },
    registermethod(tag, callback) {
        dsBridge.register(tag, callback);
    }
}