export default {
  namespaced: true,
  state: {
    homePageFloor: null,
    searchInfo: {
      placeholder: null,
      link: null
    },
    bannerData: {},
    isSearchInputShow: false
  },
  mutations: {
    SET_HOMEPAGE_FLOOR: (state, data) => {
      state.homePageFloor = data;
    },
    // 暂存首页搜索跳转信息
    SET_SEARCH_INFO: (state, data) => {
      state.searchInfo.placeholder = data?.placeholder || null;
      state.searchInfo.link = data?.link || null;
    },
    // 清除首页搜索跳转信息
    CLEAR_SEARCH_INFO: state => {
      state.searchInfo.placeholder = null;
      state.searchInfo.link = null;
    },
    // 存储banner数据
    SAVE_BANNER_DATA: (state, payload) => {
      state.bannerData = { ...payload };
      state.isSearchInputShow = payload?.templateVariableObj?.isShowSearch;
      console.log(payload, state.bannerData, 'SAVE_BANNER_DATA');
    }
  },
  getters: {
    GET_HOMEPAGE_FLOOR: state => state.homePageFloor
  }
};
