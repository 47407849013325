<!--全局loading统一成toast样式 @韩晶晶 20230510-->
<template>
  <div class="jk-loading"></div>
</template>

<script>
export default {
  data() {
    return {
      toastInstance: null,
    }
  },
  mounted() {
    this.toastInstance = this.$toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true
    })
  },
  destroyed() {
    this.toastInstance.clear();
  }
}
</script>

<!--&lt;!&ndash;具体效果可参考疾病中心页&ndash;&gt;-->
<!--<template>-->
<!--  <div class="jk-loading">-->
<!--    <jk-empty>-->
<!--      <div :class="['jk-empty__image', !isFail && 'loading']">-->
<!--&lt;!&ndash;        <jk-image class="display-inline-block" :width="60" :height="60" :src="src" alt="" />&ndash;&gt;-->
<!--        <van-loading />-->
<!--      </div>-->
<!--      <div class="jk-empty__description">{{ isFail ? '加载失败，请稍后再试～' : '正在努力加载' }}</div>-->
<!--    </jk-empty>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--  import '@/assets/style/jk-loading.scss';-->

<!--  import { JkEmpty } from 'jk-ui';-->

<!--  import logo from '@/assets/img/common/loading.png';-->
<!--  import logoHys from '@/assets/img/common/loading-hys.png';-->
<!--  import empty from '@/assets/img/emptyImg/server_error.png';-->
<!--  import { isHysApp } from '@/utils/index';-->
<!--  import { Loading } from "vant";-->

<!--  export default {-->
<!--    name: 'JkLoading',-->
<!--    props: {-->
<!--      // loading-->
<!--    },-->
<!--    data() {-->
<!--      return {-->
<!--        empty,-->
<!--        logo,-->
<!--        logoHys,-->
<!--        finishedLoad: false,-->
<!--        isFail: false,-->
<!--        processWidth: 0,-->
<!--        startTime: new Date().getTime(),-->
<!--      };-->
<!--    },-->
<!--    components: {-->
<!--      JkEmpty,-->
<!--      [Loading.name]: Loading,-->
<!--    },-->
<!--    computed: {-->
<!--      src() {-->
<!--        if(isHysApp()){-->
<!--          return logoHys;-->
<!--        }else {-->
<!--          return this.isFail ? empty : logo;-->
<!--        }-->
<!--      },-->
<!--    },-->
<!--  };-->
<!--</script>-->
<!--<style lang="scss" scoped>-->
<!--@import "@/assets/style/jk-empty/index.scss";-->

<!--</style>-->
