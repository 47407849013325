export default [
  {
    path: '/afterSales/afterSalesList',
    name: 'afterSalesList',
    component: () => import('@mall/views/afterSales/afterSalesList'),
    meta: {
      title: '售后订单'
    }
  },
  {
    path: '/afterSales/chooseServiceType',
    name: 'chooseServiceType',
    component: () => import('@mall/views/afterSales/chooseServiceType'),
    meta: {
      title: '选择服务类型'
    }
  },
  {
    path: '/afterSales/applyAfterSales',
    name: 'applyAfterSales',
    component: () => import('@mall/views/afterSales/applyAfterSales'),
    meta: {
      title: '申请退款'
    }
  },
  {
    path: '/afterSales/afterSalesDetail',
    name: 'afterSalesDetail',
    component: () => import('@mall/views/afterSales/afterSalesDetail'),
    meta: {
      title: '售后详情'
    }
  },
  {
    path: '/afterSales/afterSalesEvaluate',
    name: 'afterSalesEvaluate',
    component: () => import('@mall/views/afterSales/afterSalesEvaluate'),
    meta: {
      title: '售后评价服务'
    }
  },
  {
    path: '/afterSales/afterSalesEvaluateDetail',
    name: 'afterSalesEvaluateDetail',
    component: () => import('@mall/views/afterSales/afterSalesEvaluateDetail'),
    meta: {
      title: '售后评价服务'
    }
  },
  {
    path: '/afterSales/consultHistory',
    name: 'consultHistory',
    component: () => import('@mall/views/afterSales/consultHistory'),
    meta: {
      title: '协商历史'
    }
  },
  {
    path: '/afterSales/afterSalesSingleList',
    name: 'afterSalesSingleList',
    component: () => import('@mall/views/afterSales/afterSalesSingleList'),
    meta: {
      title: '售后订单'
    }
  },
  {
    path: '/afterSales/returnLogistics',
    name: 'returnLogistics',
    component: () => import('@mall/views/afterSales/returnLogistics'),
    meta: {
      title: '填写退货物流'
    }
  }
];
