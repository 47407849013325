export default [
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import('@mall/views/insurance/index.vue'),
    meta: {
      title: '门诊险直赔'
    }
  },
  {
    path: '/insuranceList',
    name: 'insuranceList',
    component: () => import('@mall/views/insurance/list.vue'),
    meta: {
      title: '直赔记录'
    }
  },
  {
    path: '/categoryCart',
    name: 'categoryCart',
    component: () => import('@mall/views/insurance/categoryCart.vue'),
    meta: {
      title: '便捷用药'
    }
  }
];
