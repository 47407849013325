<template>
  <jk-ui-image
    :src="src"
    :alt="alt"
    :fit="fit"
    :error-src="errorSrc"
    :height="height"
    :width="width"
    :radius="radius"
    :round="round"
    :vertical-align="verticalAlign"
  ></jk-ui-image>
</template>

<script>
import { jkImage as jkUiImage } from 'jk-ui';
export default {
  name: 'JkImage',
  components: {
    jkUiImage
  },
  props: {
    // 图片链接
    src: {
      type: String,
      default: ''
    },
    // 替代文本
    alt: {
      type: String,
      default: ''
    },
    // 图片填充模式
    fit: {
      type: String,
      default: 'cover'
    },
    // 失败时的图片地址
    errorSrc: {
      type: String,
      default: 'https://img.jk.com/mall/default_bg_img.png'
    },
    // 图片的高度
    height: {
      type: [Number, String],
      default: ''
    },
    // 图片的宽度
    width: {
      type: [Number, String],
      default: ''
    },
    // 圆角大小
    radius: {
      type: [Number, String],
      default: '8px'
    },
    // 图片为圆形
    round: {
      type: Boolean,
      default: false
    },
    verticalAlign: {
      type: String,
      default: 'baseline'
    }
  }
};
</script>
