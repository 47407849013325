export default [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@mall/views/login/LoginView.vue'),
    meta: { title: '登录注册' }
  },
  {
    path: '/home',
    name: 'homePage',
    component: () => import('@mall/views/shopingMall/components/homePage/index.vue'),
    meta: { title: '' }
  },
  {
    path: '/index',
    name: 'shopingMall',
    component: () => import('@mall/views/shopingMall/index.vue'),
    meta: { title: '商城首页' }
  },
  {
    path: '/NotFound',
    name: 'NotFound',
    component: () => import('@mall/views/404/index.vue'),
    meta: { title: '' }
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('@mall/views/middlePage/transfer.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@mall/views/cart/index.vue'),
    meta: { title: '购物车' }
  },
  {
    path: '/paywait',
    name: 'paywait',
    component: () => import('@mall/views/order/paywait.vue'),
    meta: { title: '等待支付' }
  },
  {
    path: '/guidanceLoad',
    name: 'guidanceLoad',
    component: () => import('@mall/views/guidanceLoad.vue'),
    meta: { title: '下载引导' }
  },
  {
    path: '/guidanceLoadServer',
    name: 'guidanceLoadServer',
    component: () => import('@mall/views/guidanceLoadServer.vue'),
    meta: { title: '医生端下载引导' }
  },
  {
    path: '/invoiceDetails',
    name: 'invoiceDetails',
    component: () => import('@mall/views/order/invoiceDetails.vue'),
    meta: { title: '发票详情' }
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import('@mall/views/userCenter/index.vue'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/onlineService',
    name: 'onlineService',
    component: () => import('@mall/views/userCenter/onlineService.vue'),
    meta: {
      title: '在线客服'
    }
  },
  {
    path: '/sharePage',
    name: 'SharePage',
    component: () => import('@mall/views/sharePage/index.vue'),
    meta: {
      title: ' '
    }
  },
  {
    path: '/allergy',
    name: 'allergy',
    component: () => import('@mall/views/prescriptionInfo/allergy.vue'),
    meta: { title: '过敏史' }
  },
  {
    path: '/serviceProtocal',
    name: 'ServiceProtocal',
    component: () => import('@mall/views/serviceProtocal.vue'),
    meta: { title: '' }
  },
  {
    path: '/cms',
    name: 'cms',
    component: () => import('@mall/views/cms/index.vue'),
    meta: { title: '' }
  },
  {
    path: '/oneClassList',
    name: 'OneClassList',
    component: () => import('@mall/views/category/oneClassList.vue'),
    meta: { title: '' }
  },
  {
    path: '/seckillActive',
    name: 'seckillActive',
    component: () => import('@mall/views/seckillActive/index.vue'),
    meta: { title: '限时秒杀' }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@mall/views/login/register'),
    meta: { title: '登录注册' }
  },
  {
    path: '/balance/pay',
    name: 'balancePay',
    component: () => import('@mall/views/order/balancePay.vue'),
    meta: { title: '收银台' }
  }
];
