/**
 * input、textarea的兼容性适配
 */
(function () {
  const blurFunc = function (tagName) {
    var form = document.querySelectorAll(tagName);
    for (var i = 0; i < form.length; i++) {
      form[i].blur();
    }
  };
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    window.addEventListener('touchend', event => {
      if (event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT') {
        blurFunc('textarea');
        blurFunc('input');
      }
    });
  }
})();
