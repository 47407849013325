export default [
  {
    path: '/middle/page',
    name: 'middle',
    component: () => import('@mall/views/middlePage/page.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/middlePage',
    name: 'MiddlePage',
    component: () => import('@mall/views/middlePage/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/middlePages',
    name: 'middlePages',
    component: () => import('@mall/views/thirdPages/MiddlePages.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/searchMiddleware',
    name: 'SearchMiddleware',
    component: () => import('@mall/views/searchMiddleware/index.vue'),
    meta: { title: '搜索' }
  },
  {
    path: '/dingTalk',
    name: 'dingTalk',
    component: () => import('@mall/views/middlePage/dingTalk.vue'),
    meta: { title: '' }
  },
  // 健医、星藤、星藤车险卡渠道
  {
    path: '/middle/nosign/page',
    name: 'nosignPage',
    component: () => import('@mall/views/middlePage/nosign/page.vue'),
    meta: { title: '' }
  },
  // 德华安顾渠道
  {
    path: '/middle/dhag/page',
    name: 'dhagMiddlePage',
    component: () => import('@mall/views/middlePage/tripartite/dhagMiddlePage.vue'),
    meta: { title: '' }
  }
];
