import Vue from 'vue';
import Router from 'vue-router';
import routerGuard from './router_guard';

Vue.use(Router);

const webpackContext = require.context('./route_modules', true, /.js$/);

const moudulesRoutes = webpackContext.keys().map(item => {
  const route = webpackContext(item);
  return route;
});

const routes = [];

moudulesRoutes.forEach(item => {
  routes.push(...item.default);
});

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

routerGuard(router);

export default router;
