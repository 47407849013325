import { getUserCommonProperty, getSystemConfig, unionLoginFn, getChannelCode } from './router_utils';
import { isLogin, storeInsuranceInfo, versions } from '@/utils/index';
import store from '@mall/store';
import * as dd from 'dingtalk-jsapi';

export default router => {
  // eslint-disable-next-line complexity
  return router.beforeEach(async (to, from, next) => {
    if (!versions().isDingTalk) {
      /* 路由发生变化修改页面title */
      if (to.meta && to.meta.title) {
        setTimeout(() => {
          document.title = to.meta.title;
        }, 0);
      }
    } else {
      dd.biz.navigation.setTitle({
        title: to.meta.title, // 控制标题文本，空字符串表示显示默认文本
        onSuccess: function (result) {},
        onFail: function (err) {}
      });
    }
    const {
      appid: appKey,
      mark: type,
      mobile,
      terminal,
      time,
      username: userId,
      thirdUserId,
      memberTypeCode = '',
      loginType = '',
      thirdAppType = '',
      insuranceOrderId = '',
      returnUrl = '',
      page_source = '',
      tokenKey = '',
      outOrderCode = '',
      thirdAppSource = '',
      channelCode = '',
      appId = '',
      distributorId = '',
      hideTitle = '',
      cardNo = '', // 卡号
      grpContNo = '', // 团体保单号
      customerNo = '', // 客户号
      policyCode = '', // 个人保单号
      forbiddenLogin = ''
    } = to.query;
    if (sessionStorage.getItem('forbiddenLogin') != 1) {
      sessionStorage.setItem('forbiddenLogin', forbiddenLogin);
    }
    // 三方来源
    thirdAppSource && localStorage.setItem('thirdAppSource', thirdAppSource);
    // 存储登录类型，区分长短链
    // localStorage.setItem('linkType', tokenKey ? 'tokenLogin' : 'unionLogin');
    localStorage.oldSetItemLocal('linkType', tokenKey ? 'tokenLogin' : 'unionLogin');

    page_source && store.commit('common/SET_PAGESOURCE', page_source);
    // thirdAppType ? localStorage.setItem('thirdAppType', thirdAppType) : '';
    localStorage.setItem('outOrderCode', outOrderCode);

    // 兼容三方app跳转过来时，🉐️多个标题问题
    const titleSwitch =
      (hideTitle != 'undefined' && hideTitle != 'null' && hideTitle) || localStorage.getItem('hideTitle') || '';
    titleSwitch ? localStorage.setItem('hideTitle', titleSwitch) : localStorage.removeItem('hideTitle');
    // 保存新奥燃气险字段
    storeInsuranceInfo(to);
    console.log('tokenKey:' + tokenKey);
    if (!store.state.getUserCommonPropertyCount && to.name != 'dhagMiddlePage') {
      await getUserCommonProperty(to);
      store.commit('CHANGE_COUNT', 1);
    }
    // 处理服务商品channelCode存储，用于后面页面都带上
    if (to.name === 'ServiceCommodityDetail' && channelCode) {
      localStorage.setItem('serviceGoodsChannelCode', channelCode);
      // const tokenExpiresTime = 7 * 24 * 60 * 60;
      // setCookies('serviceGoodsChannelCode', to.query.channelCode, tokenExpiresTime, 'jk.com');
      store.commit('commodity/SET_CHANNEL_CODE', channelCode);
    }

    // 处理服务商品带appKey参数跳登录页
    if (to.name === 'ServiceCommodityDetail' && appId) {
      localStorage.setItem('appId', appId);
    }

    if (distributorId) {
      // 解决服务商品未登录到登录页导致地推参数丢失问题
      sessionStorage.setItem('jk-distributor-id', distributorId ?? '');
      sessionStorage.setItem('jk-distributor-code', distributorId ?? '');
    }
    if (to.name === 'NotFound' || to.name == 'login' || to.name == 'register' || to.name == 'dingTalk') return next();
    // loginType    登录方式: 1-系统自动登录, 2-用户手动登录
    if (loginType == 2) {
      // returnUrl是跳转外部链接用的
      returnUrl && store.commit('cache/SET_RETURNURL', returnUrl);
      if (isLogin()) {
        next();
      } else {
        localStorage.setItem('appId', appKey);
        getChannelCode(to, next);

        // if (to.path.indexOf('serviceCommodity/') > -1) {
        //   const returnUrlSpecial = `${to.path}?${param(to.query)}`;
        //   next({ path: '/login', query: { backUrl: returnUrlSpecial } });
        // } else {
        //   next({ path: '/login', query: { backUrl: to.path } });
        // }
      }
    } else if (loginType == 3) {
      // returnUrl是跳转外部链接用的
      returnUrl && store.commit('cache/SET_RETURNURL', returnUrl);
      // 游客&免登陆  eg：惠军...
      const params = { appKey, time, loginType, userId: thirdUserId || userId, thirdAppType };
      getSystemConfig(params, next);
      // next()
    } else if (tokenKey) {
      // 远盟项目传递保单id参数
      localStorage.setItem('insuranceOrderId', insuranceOrderId);
      unionLoginFn('tokenLogin', tokenKey, next, to);
    } else if (appKey && time && (mobile || thirdUserId)) {
      // returnUrl是跳转外部链接用的
      returnUrl && store.commit('cache/SET_RETURNURL', returnUrl);
      // 远盟项目传递保单id参数
      localStorage.setItem('insuranceOrderId', insuranceOrderId);
      // 存储联合登录的url路径参数备用
      sessionStorage.setItem('unionLoginParams', JSON.stringify(to.query));
      // 如果参数中存在appId和sign参数，则联合登录
      const params = {
        appKey,
        type,
        mobile,
        terminal,
        time,
        userId: thirdUserId || userId,
        memberTypeCode,
        cardNo,
        grpContNo,
        customerNo,
        policyCode
      };
      unionLoginFn('unionLogin', params, next, to);
    } else {
      next();
    }
  });
};
