export default [
  {
    path: '/goods/:id.html',
    name: 'goods',
    component: () => import('@mall/views/goods/index.vue'),
    meta: { title: '商品详情页' }
  },
  {
    path: '/goods/licenseBook',
    name: 'LicenseBook',
    component: () => import('@mall/views/goods/licenseBook.vue'),
    meta: { title: '' }
  }
];
