import Vue from 'vue';
import Vuex from 'vuex';
import cache from './cache';
import groupBuy from './groupBuy';
import common from './common';
import myHealth from './myHealth.js';
import commodity from './commodity.js';
import floor from './floor.js';
import order from './order.js';

Vue.use(Vuex);

const getters = {};

const state = {
  ifLoading: false, // 是否加载中
  showConfirmWrapper: false, // 显示确认框
  uid: '',
  ifHasLogin: Boolean(localStorage.getItem('jk-token')) || false,
  cateIdx: '', // 分类页id
  user_longitude: sessionStorage.getItem('user_longitude'), // 用户定位地址经度
  user_latitude: sessionStorage.getItem('user_latitude'), // 用户定位地址纬度
  areaCode: sessionStorage.getItem('areaCode'), // 用户定位code
  orderPageSource: '',
  orderFlag: '', // 历史订单标识
  internalPurchaseInfo: {}, // 内购会员权益说明
  showHomeAmapLocation: true, // 首页定位是否显示
  showHomeCoupon: true, // 首页广告位弹窗是否显示
  globalAutoLoading: true, // 配置接口请求完毕后，全局loading是否自动结束
  getUserCommonPropertyCount: 0 // 调用getUserCommonProperty的次数
};

const mutations = {
  CHANGE_COUNT(state, num) {
    state.getUserCommonPropertyCount = num;
  },
  CHANGE_ORDERFLAG(state, flag) {
    state.orderFlag = flag;
  },
  CHANGE_LOADING(state, flag) {
    state.ifLoading = flag;
  },
  HANDLE_GLOBAL_LOADING_STATUS(state, payload) {
    state.globalAutoLoading = payload;
  },
  CHANGE_CONFIRM(state, flag) {
    state.showConfirmWrapper = flag;
  },
  SET_UID(state, val) {
    state.uid = val;
  },
  SET_CATEIDX(state, val) {
    state.cateIdx = val;
  },
  SET_LOGINSTATUS(state, val) {
    state.ifHasLogin = val;
  },
  SET_USERLOCATION(state, data) {
    state.user_longitude = data.lng;
    state.user_latitude = data.lat;
    state.user_latitude = data.areaCode;
    sessionStorage.setItem('user_longitude', data.lng);
    sessionStorage.setItem('user_latitude', data.lat);
    sessionStorage.setItem('areaCode', data.areaCode);
  },
  CHANGE_SOURCETYPE(state, flag) {
    state.orderPageSource = flag;
  },
  SET_INTERNALPURCHASEINFO(state, data) {
    state.internalPurchaseInfo = data || {};
  },
  // 首页的定位弹窗是否弹起
  SHOW_HOME_AMAPLOCATION(state, flag) {
    state.showHomeAmapLocation = flag;
  },
  // 首页广告位弹窗是否显示
  SHOW_HOME_COUPON(state, flag) {
    state.showHomeCoupon = flag;
  }
};

const actions = {};

const modules = {
  cache,
  groupBuy,
  common,
  myHealth,
  commodity,
  floor,
  order
};

const store = new Vuex.Store({
  getters,
  state,
  mutations,
  actions,
  modules
});

export default store;
