import qs from 'qs';
import api from "./axios";
import { orderApi } from "./axios";
// export const hotHospital = () => {
//   return api.post('medical/appointment/hospital/hot');
// };
// 常见疾病
export const commonDisease = () => {};
// 定位中全部城市带字母分类A、B、C
export const allCity = () => {
  return api.get("basic/sys/standardArea/allCity");
};
// 前缀匹配城市
export const preMatch = (name) => {
  return api.get(`basic/sys/standardArea/queryCityByPrefix?name=${name}`);
};
export const hotHospital = (areaCode, latitude, longitude) => {
  return api.post(
    `medical/appointment/hospital/hot?areaCode=${areaCode}&latitude=${latitude}&longitude=${longitude}`
  );
};
export const searchByKeyword = (params) => {
  return api.post("medical/appointment/hospital/searchByKeyword", params);
};

export const getHospitalType = (dictName) => {
  return api.get(`basic/sys/dict/search/${dictName}`);
};

export const hospitalOrder = (params) => {
  return api.post(`medical/appointment/hospital/order`, params); //按医院挂号
};

export const orderByDept = (params) => {
  return api.post(`medical/appointment/hospital/orderByDept`, params); //按科室挂号
};

export const orderByDisease = (params) => {
  return api.post(`medical/appointment/hospital/orderByDisease`, params); //按疾病挂号
};

export const searchHot = () => {
  return api.get(`medical/appointment/searchHistory/hot/query`); //热门搜索list
};

export const searchHistory = (params) => {
  return api.post(`medical/appointment/searchHistory/page`, params); //历史搜索list
};

export const deleteByCutomerUserId = () => {
  return api.post(`medical/appointment/searchHistory/deleteByCutomerUserId`); //热门搜索list
};

//医院主页数据
// export const doctorHot = (id) => {
//   return api.post(`medical/appointment/doctor/hot/${id}`); //热门医生list
// };
export const doctorHot = (orgCode, branchId) => {
  return api.post(
    `medical/appointment/doctor/hot?branchId=${branchId}&orgCode=${orgCode}`
  ); //热门医生list
};

// export const hospitalInfo = (id) => {
//   return api.post(`medical/appointment/hospital/info/${id}`); //医院主页信息list
// };
// export const hospitalInfo = (id, branchId) => {
//   return api.post(`medical/appointment/hospital/info?branchId=${branchId}&id=${id}`); //医院主页信息list
// };
export const hospitalInfo = (id, branchId) => {
  return api.post(
    `medical/appointment/hospital/info?branchId=${branchId}&id=${id}`
  ); //医院主页信息list
};
export const service = (orgCode, branchId) => {
  return api.post(
    `medical/appointment/hospital/service?branchId=${branchId}&orgCode=${orgCode}`
  );
};

// export const characteristic = (id) => {
//   return api.post(`medical/appointment/dept/characteristic/${id}`); //医院主页特色科室
// };
export const characteristic = (id, branchId) => {
  return api.post(
    `medical/appointment/dept/characteristic?branchId=${branchId}&id=${id}`
  ); //医院主页特色科室
};

//科室相关
// export const deptListByOrg = (id) => {
//   return api.post(`medical/appointment/dept/deptListByOrg?orgId=${id}`); //医院下所有科室
// };
export const deptListByOrg = (id, branchId) => {
  return api.post(
    `medical/appointment/dept/deptListByOrg?orgId=${id}&branchId=${branchId}`
  ); //医院下所有科室
};

export const deptHot = () => {
  return api.post(`medical/appointment/dept/hot`); //热门科室  按科室挂号
};

export const deptList = () => {
  return api.post(`medical/appointment/dept/deptList`); //按科室挂号页面科室列表(预约挂号)
};

//按疾病挂号
export const queryAllBodyAndCommonDiseases = () => {
  return api.post(`medical/appointment/disease/queryAllBodyAndCommonDiseases`); //按科室挂号页面科室列表(预约挂号)
};

export const queryFirstBodyAndCommonDiseases = () => {
  return api.post(
    `medical/appointment/disease/queryFirstBodyAndCommonDiseases`
  ); //按科室挂号页面科室列表(预约挂号)
};

export const queryCommonDisease = () => {
  return api.post(`medical/appointment/disease/queryCommonDisease`); //按科室挂号页面科室列表(预约挂号)
};

export const shedules = (params) => {
  return api.post(
    // `medical/appointment/dept/shedules?page=${params.page}&size=${params.size}&standardDeptId=${params.standardDeptId}&standardOrgCode=${params.standardOrgCode}&visitDate=${params.visitDate}`
    `medical/appointment/dept/shedules`,params
  ); //按科室挂号页面科室列表(预约挂号)
};

export const partner = (params) => {
  let newParams = "";
  for (const key in params) {
    const value = params[key];
    newParams += value ? `${key}=${value}&` : "";
  }
  newParams = newParams ? newParams.slice(0, newParams.length - 1) : newParams;
  return api.get(`mall/partner/${params["partnerUserId"]}?${newParams}`);
};

export const hotCity = () => {
  // return api.get(`medical/appointment/hospital/hot`);
  return api.get(`medical/appointment/hospital/hotCity`);
};

export const standardDown = (params) =>
  api.get(`/basic/sys/standardArea/parentWithoutUrban?parentCode=` + params);

// //获取省市区地址数据
// export const getAreaData = () => api.get(`/basic/sys/standardArea/all`);
//获取省市区地址数据
export const getAreaData = (level) =>
  api.get(`/basic/sys/standardArea/${level}`);

//获取医生主页
export const doctorInfoDetail = (standardOrgCode, standardDeptId, standardDoctorId, partnerUserId) =>
  api.get(`/mall/partner/${partnerUserId}?standardOrgCode=${standardOrgCode}&standardDeptId=${standardDeptId}&standardDoctorId=${standardDoctorId}`);
//获取排版列表
export const doctorSchedule = (standardOrgCode, standardDeptId, standardDoctorId, partnerUserId) =>
  api.get(`/mall/partner/${partnerUserId}?standardOrgCode=${standardOrgCode}&standardDeptId=${standardDeptId}&standardDoctorId=${standardDoctorId}`);
// 号源管理
export const scheduleInfo = (params) => api.post("/item/appointment/schSource/query", params);
//获取默认就诊人
// export const defaultPatient = () => api.get('/health/archive/patient/appointmentDefault')
export const defaultPatient = () => api.get("/medical/appointment/defaultPatient");
//获取是否需要就诊卡
export const hospitalRule = (params) => api.post("/medical/appointment/queryRuleConfig", params);
// 确认挂号-创建订单
export const createBook = (params) => api.post("/medical/appointment/createOrder", params);
//获取就诊人列表
export const patientList = () => api.get("/health/archive/patient/appointmentList");
//新增和修改就诊人
export const addAndUpdatePatient = (params) => api.post("/health/archive/createAppointmentPatient", params);
//获取就诊人列表
export const patientCardList = (params) => api.post("/health/card/queryCards", params);
//新增就诊卡
export const addCard = (params) => api.post("/health/card/add", params);
// 编辑就诊卡
export const editCard = (cardId, patientId) => api.get(`/health/card/queryCardInfo?id=${cardId}&patientId=${patientId}`);
// 删除就诊卡
export const delCard = (params) => api.post("/health/card/delete", params);
// 分页查询患者订单列表
export const orderList = (params) => api.post("/medical/appointment/page", params);
// 预约挂号详情
export const orderDetail = (id) => api.get(`/medical/appointment/orderInfo?id=${id}`);
// 预约挂号取消原有获取
export const cancelReason = () => api.get("/medical/appointment/queryCancelReasons");
// 取消预约挂号（退号）
export const cancleOrder = (id, reason, refundInstruction) => api.get(`/medical/appointment/cancel?id=${id}&reason=${reason || ""}&refundInstruction=${refundInstruction || ''}`);
//出诊医院的查询
export const doctorVisitHospital = (params) => api.post("/medical/doctor/appointment/doctorVisitHospital", params);
// 获取医生排班信息
export const doctorSchedules = (params) => api.post("/medical/doctor/appointment/doctorSchedules", params);
// 联想搜索预约挂号
export const likeSearch = (params) => api.post("/medical/appointment/hospital/associate", params);
// 预约挂号数量统计
export const registerCount = (appointmentStatus) => api.get(`/medical/appointment/queryAppointmentOrderCount?appointmentStatus=${appointmentStatus || ""}`);
// 转换code3位转为12位
export const transCode = (cityCode) => api.get(`/basic/sys/standardArea/cityCode?cityCode=${cityCode} `);

/*===========预约康复、预约体检接口========*/
//预约康复医院列表获取
// 预约体检数量统计
export const examinationCount = (params) => api.post(`/medical/examination/pageOrderCount`, params);
export const hospitalList = (params) => api.post("/medical/examination/query", params);
//获取体检记录
export const healthRecord = (params) => api.post("/medical/examination/pageOrderList", params);
//获取一次性登录码
export const loginCode = (params) => api.post("/medical/examination/loginAuthCode/create", params);
// 退款
// export const rejectSpend = (params) => api.post('/order/revokeOrder?accessToken=' + 111 + '&nonce=' + 22 + '&sign=' + 333, params)
export const rejectSpend = (params) =>
  orderApi.post(
    "/orderApi/open-api/V2/order/revokeOrder?accessToken=" +
      111 +
      "&nonce=" +
      22 +
      "&sign=" +
      333,
    params
  );

// export const queryAppointmentOrderCount = (appointmentStatus) =>
//   api.get(
//     `/medical/appointment/queryAppointmentOrderCount?appointmentStatus=${appointmentStatus}`
//   );
export const allOrderCountData = (tabStatus) =>
  api.get(`/medical/appointment/queryAppointmentOrderCount?tabStatus=${tabStatus}`);

export const searchAll = (params) => {
  return api.post(`medical/appointment/hospital/searchAll`, params);
};
export const searchHospitalDept = (params) => {
  return api.post(`medical/appointment/hospital/searchHospitalDept`, params);
};
export const searchDoctor = (params) => {
  return api.post(`medical/appointment/hospital/searchDoctor`, params);
};
export const standardDisease = (channelType) => {
  return api.get(`basic/sys/standardDisease/common?channelType=${channelType}`);
};
// export const rejectSpend = (params) => orderApi.post("/orderApi/open-api/V2/order/revokeOrder?accessToken=" + 111 + "&nonce=" + 22 + "&sign=" + 333, params);
//预约挂号医生主页
// 医生基本信息
export const regDoctorInfo = (params) => {
  return api.post(`medical/doctor/appointment/queryStandardDoctorBaseInfo`, params);
};
// 医生出诊医院
export const regDsoctorVisitHospital = (params) => {
  return api.post(`medical/doctor/appointment/doctorVisitHospital`, params);
};
//排班+推荐医生
export const regDoctorSchedules = (params) => {
  return api.post(`medical/doctor/appointment/doctorSchedules`, params);
};
//预约服务订单查询默认就诊人
export const defaultPatientInfo = (params) => {
  return api.post(`medical/reservation/queryDefaultPatientInfo`, params);
};
// 创建预约服务单
export const createReservationOrder = (params) => {
  return api.post(`medical/reservation/createOrder`, params);
};
//预约服务列表
// export const reservationOrderList = (params) => {
//   return api.post(`medical/reservation/reservationOrderPage`, params);
// };
export const reservationOrderList = (params) => {
  return api.post(`medical/reservation/reservationOrder`, params);
};
//预约服务订单详情

// export const reservationOrderDetail = (id) => {
//   return api.get(`medical/reservation/queryReservationOrderInfo?id=${id}`);
// };
export const reservationOrderDetail = (id) => {
  return api.get(`medical/reservation/getReservationOrderInfo?id=${id}`);
};
// 取消服务订单
export const cancelAppointmentOrder = (params) => {
  return api.post(`medical/reservation/cancel`,params);
};
// 查询订单状态
export const getOrderStatus = (id) => {
  return api.get(`transaction/order/queryOrderStatus?orderId=${id}`)
}

//评价列表
export const commentList = (params) => {
  return api.post(`mall/order/partnerOrder/partner/listCommentByDoctorIdOnMall?doctorId=${params.doctorId}&labelId=${params.labelId}&page=${params.page}&size=${params.size}&supplierId=${params.supplierId}`);
};
//用户端在医生主页查看职业详情
export const titleDetails = (params) => {
  return api.post(`user/partner/profession/queryByCustomer`,params);
};
//根据预设病情描述信息创建并返回
export const IllnessDescription = (params) => {
  return api.post(`/mall/patient/consultation/addConsultationByPre`,params);
};

//医生主页其他推荐医生
export const getDoctorRecommend = (params) => {
  return api.post(`/mall/partner/queryDoctorRecommend`,params);
};
//医生主页专家团队对接
export const getDoctorTeamRecommend = (params) => {
  return api.post(`/medical/doctor/appointment/doctorTeamRecommend`,params);
};

// 微信openId
export const fetchWxOpenId = (params) => api.get(`auth/wx/auth/user?${qs.stringify(params)}`);

// 转诊单H5支付宝支付
export const aliWapPay = (params) => api.post(`payment/pay/app/wapPay`, params);



