import Vue from 'vue';
import axios from 'axios';
import { Toast } from 'vant';
import { tokenHandle, logout } from '@/utils/index';
import { genApiSign } from 'ddjk-utils/dist/sign';

const event = new Vue();

const jkAppId = 124;

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 8e3
});
const orderApi = axios.create({
  timeout: 8e3
});
const source = axios.CancelToken.source();

// 把所有请求为pending状态的保存起来
const pendingRequests = new Map();

api.interceptors.request.use(
  config => {
    let headers = tokenHandle.setConfig(config);
    headers = tokenHandle.localRequest(headers);
    headers = tokenHandle.setChannel(headers);
    config.headers = headers;
    console.log(headers, 'headers4');

    // 用当前请求拼的key 作为唯一标识符。
    const requestKey = `${config.url}/${JSON.stringify(config.params)}/${JSON.stringify(config.data)}&request_type=${
      config.method
    }`;
    // 过滤除post请求以外。
    if (config.method === 'post' && pendingRequests.has(requestKey)) {
      // 取消当前请求
      config.cancelToken = new axios.CancelToken(cancel => {
        cancel(`重复的请求被主动拦截,${requestKey}`);
      });
    } else {
      pendingRequests.set(requestKey, config);
      config.requestKey = requestKey;
    }
    config.headers['jk-app-id'] = config.headers['jk-app-id'] || jkAppId;

    const signObj = genApiSign(process.env.VUE_APP_SALT);
    Object.keys(signObj).forEach(key => {
      config.headers[key] = signObj[key];
    });
    return config;
  },
  error => {
    // 出现网络波动的错误就清空pendingRequests对象
    pendingRequests.clear();

    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    console.log('axios success', response);
    // 如果顺利到响应拦截，说明请求已经结束pending状态 需手动删除
    const requestKey = response.config.requestKey;
    pendingRequests.delete(requestKey);

    tokenHandle.setResponse(response);
    console.log('response', response);
    if (
      response.data.code.search &&
      (response.data.code.search('5') == 0 || response.data.code.search('120104') == 0)
    ) {
      // 服务返回5xx
      event.$emit('showMask', '', '服务器加载异常，稍后重试', '5');
      // hideMask
    }
    // if(response.data.code.search("200") == -1) {
    //   event.$emit('showMask','','网络异常，点击屏幕重试','2')
    // }
    return response;
  },
  error => {
    console.log('axios error: ', error);

    // 先判断是否因取消重复请求抛出的error
    if (axios.isCancel(error)) {
      return;
      // return Promise.reject({message:'cancel1'})
    }
    pendingRequests.clear();

    if (error) {
      // event.$emit('showMask','服务器加载异常，稍后重试')
      event.$emit('showMask', '', '网络异常，点击屏幕重试', '2');
    }
    const res = error.response;
    if (!res) {
      const { message } = error || {};
      if (message.includes('Network')) {
        // Toast.fail('网络连接失败');
        console.warn('网络连接失败');
      }
      if (message.includes('timeout')) {
        Toast.fail('请求超时');
      }
    } else if (res.status === 400) {
      Toast.fail('400 Bad');
    } else if (res.status === 401) {
      if (localStorage.getItem('systemChannel')) {
        if (window.wx.miniProgram) {
          window.wx.miniProgram.navigateTo({ url: '/pages/index/index' });
        }
      } else {
        logout();
      }
    } else if (res.status === 403) {
      Toast.fail('请求被拒绝');
    } else if (res.status === 500) {
      Toast.fail('服务器内部错误');
    } else {
      Toast.fail('未知错误');
    }
    return Promise.reject(error);
  }
);

export { orderApi, source };

export default api;
