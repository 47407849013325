import qs from 'qs';
import { api, api_ody } from './axios';

let userInfo = localStorage.getItem('userInfo');
userInfo = userInfo && userInfo != 'undefined' ? JSON.parse(userInfo) : {};
const ut = Object.keys(userInfo).length ? userInfo['ut'] : '';

const getLonAndLat = name => {
  // const lat = sessionStorage.getItem('user_latitude') || '';
  // const lon = sessionStorage.getItem('user_longitude') || '';
  const value = sessionStorage.getItem(name) || '';
  return value;
};
// 楼层
export const getPageFloor = params => api.post(`/ad-whale-web/dolphin/getPageFloor`, params);

// 获取微信授权签名
export const getSignature = url => api.get(`/auth/wx/createJsapiSignature?url=${url}`);

// 楼层广告位
export const getAdSource = params => api_ody.post(`/ad-whale-web/dolphin/getAdSource`, params);

// 首页弹窗接口合并(广告位/新人弹窗)
export const getMallPop = params => api.post(`/ddjk-market/market/realTime/getMallPop`, params);

// 扫一扫查询药品信息(分页)
export const scanGoods = params => api.post(`/mall/search/customerSearch/scanGoods`, params);

// 全部分类
// export const getCategory = () => api.get(`/mall/search/classify/searchClasses`)
export const getCategory = params => api_ody.post('/back-product-web2/extra/category/queryCategory', params);

// 通过类目树ID获取全部分类
// /extra/category/listCategoryTree
export const getCategoryById = params => api_ody.post('/back-product-web2/extra/category/listCategoryTree', params);

// 常购药品
export const frequentlyPurchasedDrugs = params =>
  api.post(`/mall/homepage/customerHomepage/frequentlyPurchasedDrugs`, params);

// 根据分类查询药品详情
export const searchClassifyGoods = params => api.post(`/mall/search/classify/searchClassifyGoods`, params);

/** *** --start 地址接口 *****/
// 获取省市区地址数据
export const getAreaData = () => api.get(`/basic/sys/standardArea/all`);

// 获取父级code查询地区
export const getAreaByCode = params => api.get('/basic/sys/standardArea/parentCode', { params: params });

// 新增、修改地址
export const editAddress = params => api.post('/item/customer-address', params);

// 根据地址Id查询收获地址
export const getAddressById = params => api.get('/item/customer-address', { params: params });

// 查询用户收获地址
export const getAddressList = () => api.post('/item/customer-address/findAddressByCustId');

// 根据收货地址id删除地址
export const deleteAddressById = params => api.post('/item/customer-address/addresss/delete?id=' + params.id);

/** *** --end *****/

// 字典
export const dict = params => api.get(`/basic/sys/dict/search/` + params);

// 查询用户下就诊人列表，只包含基础数据
export const patientList = params =>
  api.get(`/health/archive/patient/mallList?applicableSkuType=${params.applicableSkuType}`);

// 新增修改个人基本信息（新建返回就诊人ID）
export const createBasicInfo = params => api.post(`/health/archive/createBasicInfo`, params);

// 查询个人基本信息
export const queryBasicInfo = params => api.get(`/health/archive/queryBasicInfo?id=${params.id}`);

// 保存上传处方
export const uploadPrescription = params => api.post(`/health/prescription/uploadPrescription/ocrAndAdd`, params);

// 新增修改用药人健康数据
export const createPatientHealthData = params => api.post(`/health/archive/createPatientHealthData`, params);

// 运营后台查询电子处方详情
export const queryPrescription = params =>
  api.get(`/health/prescription/online/infoByCode?prescriptionOnlineCode=${params.prescriptionOnlineCode}`);

/** *** --start 购物车接口 *****/
// 获取购物车列表
export const getCartList = params => api.get(`/mall/customershopCart`, params);

// 查询健康档案完善度
export const completePercent = params => api.get(`/health/archive/completePercent/query?id=${params}`);

// 查询就诊人详情
export const queryPatientDetail = params => api.get(`/health/archive/queryDetail?id=${params.id}`);

// 为您推荐
export const getRecommendList = params =>
  api.post('/searchmain/item/recommondList', params, {
    headers: {
      'jk-app-id': '124'
    }
  });

/**
 * 判断是否存在服务过的医生
 * @param {*} id 就诊人ID
 */
export const checkExistServicesDoctor = params => api.get(`/medical/archive/partnerExist?patientId=${params.id}`);

// 获取验证码
export const getSmsCaptcha = params => {
  return api.post(`/open-mall/open/mall/auth/sendSmsCaptcha`, params);
};

/**
 * 登录
 */
export const loginWithSmsCode = params => {
  return api.post(`/open-mall/open/mall/auth/smsCaptchaLogin`, params);
};

// 根据渠道服务编码查询配置信息接口（这里主要获取物流轨迹信息）
export const getApplicationConfigInfo = params => {
  return api.post(`/open-mall/open/mall/config/getApplicationConfigInfo`, params);
};

/** *** 图片上传*****/
export const createOssToken = () => api.post('/basic/oss/createOssToken');

/**
 * 拼团首页-大家都在拼
 * @param {*} params
 */
export const fetchGroupBuyListData = params => {
  return api.post('/ddjk-market/search/customerSearch/everybodyGroupwork', params, {
    headers: {
      ut
    }
  });
};

// 24小时拼团预告列表--商品列表
export const trailerList = params => {
  return api.post('/ddjk-market/search/customerSearch/noticeGroupwork', params);
};

/**
 * 拼团首页-24小时推荐
 * @param {*} params
 * @returns
 */
export const fetch24HourRecommend = params => {
  return api.post('/ddjk-market/search/customerSearch/noticeGroupwork', params, {
    headers: {
      ut
    }
  });
};

/**
 * 登出
 * @returns
 */
export const logout = () => {
  return api.post('/auth/login/logout');
};

/**
 * o2o店铺搜索
 * @returns
 */
export const searchOneHour = params => {
  return api.post('/searchmain/item/searchOneHourClass', params);
};

/**
 * 首页搜索商品主接口
 * @returns
 */
export const homeKeywordSearch = params => {
  return api.post('/searchmain/global/search/open/item', params);
};

/**
 * 首页精选服务一级列表接口
 * @returns
 */
export const oneClassProductSearch = params => {
  return api.post('searchmain/item/search', params, {
    headers: {
      'jk-app-id': '124'
    }
  });
};

// 商城首页列表
export const getShoppingMallList = params => {
  return api.post('searchmain/item/guessYourNeedAll', params, {
    headers: {
      'jk-app-id': '124'
    }
  });
};
// 商城精选服务
export const shopSelected = params => {
  return api.post('searchmain/item/search', params, {
    headers: {
      'jk-app-id': '124'
    }
  });
};
// 商城热销商品
export const hotSaleGoods = params => {
  return api.post('ddjk-market/search/customerSearch/hotSaleGoods', params, {
    headers: {
      'jk-app-id': '124'
    }
  });
};
// 奔思 快速购药商品信息
export const goodList = params => {
  return api.post('searchmain/item/search', params, {
    headers: {
      'jk-app-id': '124'
    }
  });
};

// 电子处方单去购药搜索结果
export const getPharmacyList = params => {
  return api.post('ddjk-market/search/customerSearch/searchPrescription', params, {
    headers: {
      'jk-user-lat': getLonAndLat('lan'),
      'jk-user-lon': getLonAndLat('lon')
    }
  });
};
// 获取渠道配置前台类目树id
export const getFrontCategoryId = () => {
  return api_ody.fetch(`/cms-web/api/config/getFrontCategoryId?pageType=1`);
};
// 获取渠道配置前台类目树id -- 新接口 -- 蔡翔霖
export const fetchFrontCategoryId = () => {
  return api.post('/cms/channel/category/getFrontCategoryId');
};
// 获取奔思类目树子节点
export const getCategoryList = params => {
  return api_ody.post('/back-product-web2/extra/category/listCategoryTree.do', params);
};

export const sensorsBindRelation = params => {
  return api.post('/basic/collector/sensorsBindRelation', params);
};

// 标记内购会员弹窗是否已经显示
export const writeInternalPurchasePopup = params => {
  return api.post('/ddjk-market/promotion/purchase/writeInternalPurchasePopup', params);
};

// 登录页将用户与当前内购id绑定
export const bindPurchaseIdentity = params => {
  return api.post('/ddjk-market/promotion/bindPurchaseIdentity', params);
};

// 商城首页内购会员弹窗的数据
export const getInternalPurchasePopup = params => {
  return api.post('/ddjk-market/promotion/purchase/getInternalPurchasePopup', params);
};

// 跟句id查询会员内购身份
export const getPurchaseIdentyInfo = params => {
  return api.get('/ddjk-market/promotion/getPurchaseIdentityInfo', { params: params });
};

// 获取短链token
export const temporarySaveToken = params => {
  console.log(params, 'temporarySaveToken');
  return api.post('/auth/miniapp/temporarySaveToken', params);
};

// 获取短token2
// /auth/cloud/access-token
export const getShortToken = params =>
  api.post('/auth/cloud/access-token', qs.stringify(params), {
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  });

// 获取我的页面订单数量
export const getSummaryData = params => {
  return api.post('/oms-api/order/so/my/summary', params);
};

// 商品推荐
export const orderDetailRecommend = params => {
  return api.post('/ddjk-market/search/recommend/orderDetailRecommend', params);
};

// 获取我的页面订单数量
export const getHysUserInfo = params => {
  const info = {
    ...params,
    coonType: 5
    // "mobile": "13476239844",
    // "userId": 2103180000007075
  };
  return api.post('/ddjk-market/hys/order/getHysUserInfo', info);
};

/** ************* 好药师历史订单相关接口 ******************/

/**
 * 订单--获取订单列表
 */
export const f_listOrder = params => {
  return api.post('/ddjk-market/hys/order/list', {
    ...params,
    coonType: 5
  });
};

/**
 * 订单--获取订单详情信息
 */
export const f_getOrder = params => {
  return api.post('/ddjk-market/hys/order/detail', {
    ...params,
    coonType: 5
  });
};

// 惠军注册用户
export const huijunRegisterBySmsForMall = params => {
  return api.post('/open-mall/open/mall/auth/thirdSmsCaptchaLogin', params);
};

/**
 * 获取appid和loginType
 */
export const getLoginInfo = data => {
  return api.get(`/open-mall/open/mall/common/getApplicationByChannel?channelCode=${data}`);
};

// 获取常见过敏史信息
export const queryUsualAllergyInfo = params => {
  return api.post('/ddjk-market/allergy/queryUsualAllergyInfo', params);
};
// 查询用户在当前渠道的过敏史信息
export const queryAllergy = params => {
  return api.post('/ddjk-market/allergy/queryAllergyByChannelCode', params);
};
// 保存用户已选过敏史信息
export const saveAllergyInfo = params => {
  return api.post('/ddjk-market/allergy/saveAllergyInfo', params);
};
// 搜索过敏史信息
export const searchAllergyInfo = params => {
  return api.post('/ddjk-market/allergy/searchAllergyInfo', params);
};
// 获取开放商城-好药师M站跳转链接
export const getOpenMallLinkUrl = params => {
  return api.post('/open-mall/open/mall/auth/v2/thirdUnionLink', params, {
    headers: {
      timestamp: new Date().getTime()
    }
  });
};

// 获取商品库存阈值
export const getProdStockThreshold = params => {
  return api.post(`/ddjk-market/market/realTime/getProdStockThreshold`, params);
};

export const getProductByPage = params => {
  return api.post('/ddjk-market/market/realTime/getProductByPage', params);
};

/**
 * 获取店铺的客服信息
 * @param {*} params
 * @returns
 * { "storeIds": [] }
 */
export const getStoreInfo = params => {
  return api.post('/ddjk-market/market/storeInfo/getStoreInfoDetail', params);
};

// 获取跳转链接，内部服务调用
export const getHyperLink = params => {
  return api.post('/open-mall/open/mall/auth/getHyperLink', params);
};

// 根据authCode获取钉钉用户信息
export const getDtUserInfo = params => {
  return api.post('/auth/dingTalk/getDtUserInfo', params);
};

// 推送订单，跳转三方收银台
export const pushOrderDetailToThird = params => {
  return api.post('/open-mall/open/mall/order/pushOrderDetailToThird', params);
};

// 查询三方健医支付结果，同步订单中心
export const thirdPayStatus = params => {
  return api.get(
    `/open-mall/open/mall/order/query/third/pay/status?orderCode=${params.orderCode}&appKey=${params.appKey}`
  );
};
