/* eslint-disable */
import senesordata from '@/utils/sensors/index.js'

let obj;

const  track = (trackName,params) => {
    if(obj){
        obj.track(trackName,params);
    }else{
        return false;
    }
}

export default function (Vue) {
    senesordata(Vue);
    obj = Vue.prototype.$sensors;
    return {track};
}