/* eslint-disable no-proto */
import { getQueryVariable } from '@/utils';
/** 1.路径appid更新时，更新缓存中的appid 2.取localstorage中的appid防止页面刷新造成appId丢失 */
// const appId = getQueryVariable('appid') || getQueryVariable('appId') || localStorage.getItem('appId') || ''
// // const prefix = appInfo[appId]?.appMark

// const prefix = appId
// console.log('hahahhahahhhhahah======'+appId)
// localStorage.setItem('appId', appId) // appid
// localStorage.setItem('appMark', prefix) // app缓存隔离标记

/**
 * 排除三方key值，高德、vConsole、听云
 */
const isCustomKey = key => {
  return !/^_AMap_/.test(key) && !/^vConsole/.test(key) && !/^TY_/.test(key);
};

export const initStorageMethods = (key = '') => {
  // 德华安顾APP存在多个渠道访问，特殊处理德华安顾中间页初始化initStorageMethods方法，清掉之前缓存
  if (isClearInitStorage() && !key) {
    localStorage.clear();
    sessionStorage.clear();
  }

  console.log('执行initStorageMethods');

  const appId = key || getQueryVariable('appid') || getQueryVariable('appId') || localStorage.getItem('appId') || '';
  const prefix = appId;
  localStorage.setItem('appId', appId); // appid
  localStorage.setItem('appMark', prefix); // app缓存隔离标记
  console.warn(prefix);
  const oldSetItemLocal = localStorage.setItem.bind(localStorage);
  const oldGetItemLocal = localStorage.getItem.bind(localStorage);
  const oldSetItemSession = sessionStorage.setItem.bind(sessionStorage);
  const oldGetItemSession = sessionStorage.getItem.bind(sessionStorage);

  localStorage.__proto__.oldSetItemLocal = oldSetItemLocal;
  localStorage.__proto__.oldGetItemLocal = oldGetItemLocal;
  sessionStorage.__proto__.oldSetItemSession = oldSetItemSession;
  sessionStorage.__proto__.oldGetItemSession = oldGetItemSession;

  localStorage.setItem = function (key, value) {
    if (prefix && isCustomKey(key)) {
      const localData = JSON.parse(oldGetItemLocal(prefix) || '{}');
      localData[key] = value;
      oldSetItemLocal(prefix, JSON.stringify(localData));
    } else {
      oldSetItemLocal(key, value);
    }
  };
  localStorage.getItem = function (key) {
    if (prefix && isCustomKey(key)) {
      const localData = JSON.parse(oldGetItemLocal(prefix) || '{}');
      return localData[key];
    } else {
      return oldGetItemLocal(key);
    }
  };
  localStorage.removeItem = function (key) {
    return localStorage.setItem(key, undefined);
  };
  sessionStorage.setItem = function (key, value) {
    if (prefix && isCustomKey(key)) {
      const sessionData = JSON.parse(oldGetItemSession(prefix) || '{}');
      sessionData[key] = value;
      oldSetItemSession(prefix, JSON.stringify(sessionData));
    } else {
      oldSetItemSession(key, value);
    }
  };
  sessionStorage.getItem = function (key) {
    if (prefix && isCustomKey(key)) {
      const sessionData = JSON.parse(oldGetItemSession(prefix) || '{}');
      return sessionData[key];
    } else {
      return oldGetItemSession(key);
    }
  };
  sessionStorage.removeItem = function (key) {
    return sessionStorage.setItem(key, undefined);
  };
};

const isClearInitStorage = () => {
  if (window.location.href.indexOf('/#/middle/dhag/page') > -1) {
    return true;
  }
  return false;
};
