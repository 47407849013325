import qs from 'qs';
import api from './axios';

/** 健康档案 */
/**
 * 新建健康档案
 * @param {*} params
 */
export const createBasicInfo = (params) => api.post(`/health/archive/createBasicInfo`, params);

/**
 * 查询健康档案详情
 * @param {*} patientId
 */
export const queryArchiveDetail = (patientId) => api.get(`/health/archive/queryArchiveDetail?id=${patientId}`);

/**
 * 获取常见疾病
 * @param {*} params
 */
export const recommendList = (params) => api.get('/medical/search/disease/recommendList', params);

/**
 * 疾病搜索
 * @param {*} params
 */
export const getDiseaseTags = (params) => api.post(`/medical/search/disease/pageSearch`, params);

/**
 * 查询所有过敏原
 * @param {*} params
 */
export const queryAllergys = (params) => api.post(`/basic/sys/dict/queryAllergys`, params);

/**
 * 查询单类型过敏数据
 */

export const queryDict = (params) => api.post(`/basic/sys/dict/query`, params);
/**
 * 查看残疾情况
 */
export const queryDisability = (params) => api.post(`/basic/sys/dict/pageList`, params);

// 保存病史情况
export const createArchiveMedicalHistory = (params) => api.post('/health/archive/createArchiveMedicalHistory', params);

// 查询手术字典
export const querySurgerys = (params) => api.post(`/basic/sys/dict/pageSurgerys`, params);

// 保存身体情况
export const createBodyInfo = (params) => api.post(`/health/archive/createBodyInfo`, params);

// 数据字典
export const dict = (params) => api.get(`/basic/sys/dict/search/` + params);

// 生活方式
export const createRiskFactor = (params) => api.post(`/health/archive/createRiskFactor`, params);

// 健康档案列表
export const queryFiedList = () => api.get(`/health/archive/certified/list`);

// 设置默认就诊人
export const setDefaultPatient = (params) => api.post(`/health/archive/setDefault?id=${params.id}`);

// 查询本人就诊人是否实名
export const checkMineCertified = () => api.get(`/health/archive/checkMineCertified`);

// 查询我的基本信息
export const myInfo = () => api.get(`/medical/archive/myInfo`);

// 会员卡列表
export const getPersonalServiceCenter = () => api.get(`/mall/teamCenter/service/getPersonalServiceCenter`);

//购买记录列表;
export const getPersonalServiceOrder = (params) => api.post(`/mall/teamCenter/service/getPersonalServiceOrder`, params);

//五个指标项;
export const queryCheckList = (id) => api.get(`health/integration/track/queryCheckList?patientId=${id}`);

//首页打卡信息
export const queryClockDataOfH5 = (id) => api.post(`health/track/symptom/queryClockDataOfH5?patientId=${id}`);

// 微信openId
export const fetchWxOpenId = (params) => api.get(`auth/wx/user?${qs.stringify(params)}`);

// 获取未登录情况下 微信openId
export const getWxOpenId = ({ code, partnerId }) => api.get(`auth/wx/getOpenId?code=${code}&partnerId=${partnerId}`);

// 公众号 通过code登录
export const loginByCode = ({ code }) =>
  api.get(`auth/wx/loginByCode?code=${code}`, {
    headers: {
      'jk-app-id': '124',
    },
  });
//
export const bindOpenId = (openId) => api.post(`user/wx/mpuser/bind?openId=${openId}`);

//心情打卡
export const moodAdd = (params) => api.post(`medical/track/mood/add`, params);

//症状打卡
export const symptomAdd = (params) => api.post(`health/track/symptom/add`, params);

//查询症状
export const queryForConfig = (id) => api.get(`health/track/symptom/config/queryForConfig?openStatus=1&type=2&patientId=${id}`);

//添加症状
export const integrationAdd = (params) => api.post(`health/integration/track/add`, params);

// //查询症状
// export const getPersonalServiceOrder = (params) => api.post(`mall/teamCenter/service/getPersonalServiceOrder`, params);

//更新用户信息
export const updateUserInfo = (params) => api.post(`user/customer/update`, params);
//查询就诊人搜索页面疾病的症状
export const symptomLists = (params) => api.get(`health/symptom/querySymptom?type=${params.type}&patientId=${params.patientId}`);

//查询就诊人搜索页面疾病的症状
export const symptomSearch = (symptomName) => api.get(`basic/sys/standardSymptom/search?symptomName=${symptomName}`);

//查询就诊人搜索页面疾病的症状
export const symptomListAdd = (params) => api.post(`health/track/symptom/config/add`, params);

//查询会员卡的就诊人
export const renewSimpleCheck = (diseaseCenterId, teamId, teamDiseaseCenterId) => api.post(`mall/order/doctorTeamOrder/renewSimpleCheck/${diseaseCenterId}/${teamId}/${teamDiseaseCenterId}`);

// 面诊开方订单创建
export const facePrescriptionOrderCreate = (params) => api.post('mall/order/customerOrder/facePrescriptionOrderCreate', params);

// 查询图文问诊群
export const queryTeam = ({ partnerId, patientId }) =>
  api.get(`medical/consultation/queryPartnerConsultationTeam?partnerId=${partnerId}&patientId=${patientId}`);

//
export const getTokenModelByKey = (tokenModelKey) => api.get(`auth/miniapp/getTokenModelByKey?tokenModelKey=${tokenModelKey}`);

export const queryImIdByUserAndPartner = (params) => api.post(`transaction/order/consultation/queryImIdByUserAndPartner`, params);
/**
 * 查询血糖全部数据
 * @param {*} params
 * @returns
 */
export const fetchBloodList = (params) => api.post(`health/integration/track/queryCheckListByCode`, params);

/**
 * 批量删除血糖数据
 * @param {*} params
 * @returns
 */
export const deleteBloodList = (params) => api.post(`health/track/check/record/delete`, params);

/**
 * 查询血糖图表数据
 * @param {*} params
 * @returns
 */
export const queryBloodChartDetail = (params) => api.post(`health/integration/track/queryChartDetail`, params);

/**
 * 查询添加血糖参数列表
 * @param {*} itemCode
 */
export const getExaminationItem = (itemCode) => api.get(`basic/sys/standardExaminationItem/param/query?itemCodeList=${itemCode}`);

/**
 * 查询今日血糖数据
 * @param {*} params
 */
export const queryTodayDetail = ({ patientId, checkItemCode, checkTime }) =>
  api.get(`health/integration/track/queryTodayDetail?patientId=${patientId}&checkItemCode=${checkItemCode}&checkTime=${checkTime}`);

/**
 * 查询当前时间数据数量
 * @param {*} params
 */
export const queryCountByTime = ({ checkItemCode, patientId, checkTime }) =>
  api.get(`health/integration/track/queryCountByTime?checkItemCode=${checkItemCode}&patientId=${patientId}&checkTime=${checkTime}`);

// 筛查记录列表
export const queryScreenList = (data) => api.post('/medical/examination/screen/queryPageScreenList', data)

// 筛查记录详情
export const queryScreenInfo = (screenId) => api.get(`/medical/examination/screen/queryScreenInfo/${screenId}`)

// 面诊拉新  获取医生信息和订单信息接口
export const orderInfoByRecordId = (recordId) => api.get(`mall/order/customerOrder/queryOrderInfoByRecordId?recordId=${recordId}`);
// 面诊拉新  创建订单信息
export const createReDiagnosisOrder = (recordId) => api.post(`mall/order/customerOrder/createReDiagnosisOrder?recordId=${recordId}`);
//面诊判断服务是否上下架
export const serviceStatus = (params) => api.post(`mall/order/customerOrder/queryServiceStatus`,params);
//面诊通过订单查询用户
export const getUserId = (orderId) => api.get(`mall/order/customerOrder/queryCustomerUserIdByOrderId?orderId=${orderId}`);
// 获取检查检查树形根目录
export const trackRootNode = () => api.get(`intelligence/master/data/track/root/node`);

// 获取检查检查树形子目录
export const trackChildNode = (params) => api.post(`intelligence/master/data/track/child/node`, params);

// 分页检索检验检查
export const trackSearch = (params) => api.post(`intelligence/track/search/page`, params);

// 添加实验室指标项
export const trackAdd = (params) => api.post(`medical/integration/track/track/add`, params);

// 查询就诊人所有开启的检查项
export const queryOpenList = (patientId) => api.get(`health/track/check/queryOpenList?patientId=${patientId}`);

// 查询就诊人添加的所有检查项，分页
export const queryTrackPageList = (params) => api.post(`health/track/check/queryPageList`, params);

// 更新所有检查项,排序和开关
export const updateByOpenItems = (params) => api.post(`health/track/check/updateByOpenItems`, params);

//批量添加指标数据页面查询当天添加的数据
export const queryForBatchAdd = (patientId) => api.post(`medical/integration/track/queryForBatchAdd?patientId=${patientId}`);

// 查询基础数据
export const queryBaseDetail = (params) => api.post(`medical/integration/track/record/queryBaseDetail`, params);

// 查询图表数据
export const queryChartDetail = (params) => api.post(`health/integration/new/track/record/queryChartDetail`, params);

// 查询检查记录全部数据分页
export const queryCheckPage = (params) => api.post(`health/track/check/record/queryCheckPage`, params);

// 根据检查项编码查询检查项参数信息,不带分页
export const queryParam = ({ entityModelCode, itemCode }) =>
  api.get(`medical/integration/track/param/query?entityModelCode=${entityModelCode}&itemCode=${itemCode}`);

// 添加就诊人检查记录信息
export const trackRecordAdd = (params) => api.post(`medical/integration/track/record/add`, params);

/**
 * 查询添加实验室指标参数列表
 * @param {*} entityModelCode itemCode
 */
export const getIntegration = (entityModelCode, itemCode) =>
  api.get(`medical/integration/track/param/query?entityModelCode=${entityModelCode}&itemCode=${itemCode}`);

//添加实验指标
export const medicalIntegration = (params) => api.post(`medical/integration/track/record/add`, params);

// 个人中心 处方数量查询
export const patientUsablePrescriptionCount = (patientId) => api.get(`health/prescription/online/patientUsablePrescriptionCount?patientId=${patientId}`)

// 根据优惠券不ID查询可使用该券的团队疾病中心列
export const doctorTeamCoupon = (couponId) => api.get(`mall/order/doctorTeamCoupon/${couponId}`)

