<!-- eslint-disable vue/no-v-html -->
<template>
  <div id="login-pop">
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '3.62rem' }"
      :close-on-click-overlay="false"
      class="login-pop_wrapper"
    >
      <div class="login-view__form">
        <div class="title">{{ loginTitle }}</div>
        <div class="desc">{{ loginTips }}</div>
        <van-form>
          <van-field
            v-model="formData.phoneNum"
            type="tel"
            name="phoneNum"
            placeholder="请输入手机号码"
            label-width="0.31rem"
            maxlength="11"
            label-class="tel-label"
            label-align="center"
            input-align="left"
            class="bottom-line"
          >
            <template #label>
              <div class="tel_tag">+86</div>
              <div class="clearable" :style="{ display: formData.phoneNum ? 'block' : 'none' }" @click="clearPhoneNum">
                <jk-image :src="close" :width="16" :height="16"></jk-image>
              </div>
              <!-- +86 -->
            </template>
          </van-field>
          <van-field
            v-model="formData.smsCode"
            type="number"
            maxlength="6"
            name="smsCode"
            placeholder="请输入短信验证码"
            input-align="left"
            class="bottom-line validate-smscode"
          >
            <template #extra>
              <span v-if="!isSend" class="sms-btn" :class="{ 'sms-btn-theme': formData.phoneNum }" @click="getSmsCode">
                获取验证码
              </span>
              <span v-else class="sms-btn">
                <!-- 获取验证码 -->
                重新发送(
                <van-count-down :time="time" format="ss" @finish="countDownFinish" />s)
              </span>
              <div
                class="clearableCode"
                :style="{ display: formData.smsCode ? 'block' : 'none' }"
                @click="clearSmscode"
              >
                <jk-image :src="close" :width="16" :height="16"></jk-image>
              </div>
            </template>
          </van-field>
          <div class="login-rules">
            <div class="login-agree-box" @click="agreeFlag = !agreeFlag">
              <div v-if="!agreeFlag" class="login-agree"></div>
              <jk-image v-else :src="agree" class="login-agree-img"></jk-image>
            </div>
            同意
            <span class="ddjk-service-protocal" @click="showSevice(1)">《服务协议》</span>
            <span class="ddjk-privacy-policy" @click="showSevice(2)">《用户隐私政策》</span>
          </div>
          <div class="submit-box">
            <van-button :disabled="loading" round type="default" @click="cancel">取消</van-button>
            <van-button
              :loading="loading"
              :color="canLogin && agreeFlag ? 'rgba(68,204,119,1)' : '#b4ebc9'"
              round
              type="primary"
              @click="login"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    </van-popup>
    <van-popup v-model="show1" round position="bottom" :style="{ height: '95%' }">
      <div class="service">
        <div class="service-top">
          <div class="service-title">{{ serviceTitle }}</div>
          <div class="icon" @click="show1 = false">
            <van-icon name="cross" />
          </div>
        </div>
        <div ref="serviceContent" class="service-protocol">
          <div class="service-protocol-content" v-html="serviceContent"></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import store from '@/projects/mall/store/index';
import { Popup, Form, Field, Button, CountDown, Toast } from 'vant';
import close from '@/projects/mall/assets/img/login/close.png';
import agree from '@/projects/mall/assets/img/login/agree.png';
import { parseTime, versions, clearLoginInfo, parseParams, getChannelCode } from '@/utils/index';
import { getSmsCaptcha, huijunRegisterBySmsForMall } from '@/projects/mall/api/http';
import { getUserAgreement } from '../../projects/mall/api/http_mall';
export default {
  name: 'LoginPop',
  components: {
    [Popup.name]: Popup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [CountDown.name]: CountDown
  },
  data() {
    return {
      close,
      agree,
      serviceType: 1,
      show1: false,
      agreeFlag: false,
      canLogin: false,
      isSend: false,
      loading: false,
      time: 0,
      formData: {
        phoneNum: null,
        smsCode: null
      },
      show: false,
      callback: null,
      register_source: '',
      loginTitle: '用药信息完善',
      loginTips: '为保障会员专属购药补贴权益，请你完善会员信息。',
      serviceContent: '',
      serviceTitle: ''
    };
  },
  watch: {
    'formData.smsCode': function (val) {
      if (this.validTel(this.formData.phoneNum) && this.validCode(val)) {
        this.canLogin = true;
      } else {
        this.canLogin = false;
      }
    },
    'formData.phoneNum': function (val) {
      if (this.validTel(val) && this.validCode(this.formData.smsCode)) {
        this.canLogin = true;
      } else {
        this.canLogin = false;
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.show = true;
    });
  },
  mounted() {
    window.addEventListener('popstate', this.cancel, false);
    this.showCountDown();
    this.loginTitle = store.state.common.commonProperty.loginTitle || '用药信息完善';
    this.loginTips = store.state.common.commonProperty.loginTips || '为保障会员专属购药补贴权益，请你完善会员信息。';
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.cancel, false);
  },
  methods: {
    versions,
    cancel() {
      this.show = false;
      this.remove();
    },
    confirm() {
      this.show = false;
      this.callback && this.callback(true);
      this.remove();
    },
    // 组件移除事件
    remove() {
      // 等过渡效果结束后，再移除组件
      setTimeout(() => {
        this.$destroy(this);
        this.$el.remove(); // 移除组件 DOM
      }, 500);
    },
    clearPhoneNum() {
      this.formData.phoneNum = null;
    },
    clearSmscode() {
      this.formData.smsCode = null;
    },
    validTel(val) {
      if (!val) {
        return;
      }
      var telReg = /^1[3456789]\d{9}$/;
      return val && telReg.test(val);
    },
    validCode(val) {
      if (!val) {
        return;
      }
      var telReg = /^\d{6}$/;
      return val && telReg.test(val);
    },
    verify() {
      const { phoneNum = '', smsCode = '' } = this.formData;
      if (!phoneNum) {
        Toast('请输入正确的手机号');
        return false;
      }
      if (phoneNum && !this.validTel(this.formData.phoneNum)) {
        Toast('请输入正确的手机号');
        return false;
      }
      if (!smsCode) {
        Toast('请输入验证码');
        return false;
      }
      if (!this.agreeFlag) {
        Toast('请阅读并勾选相关协议');
        if (this.agreementToast) {
          return;
        }
        this.agreementToast = true;
        setTimeout(() => {
          this.agreementToast = false;
        }, 1500);
        return false;
      }
      if (smsCode && !this.validCode(smsCode)) {
        Toast('验证码输入错误');
        return false;
      }
      return true;
    },
    async getSmsCode() {
      try {
        if (!this.formData.phoneNum) {
          Toast('请输入正确的手机号码');
          return false;
        }
        if (!this.validTel(this.formData.phoneNum)) {
          Toast('请输入正确的手机号');
          return false;
        }
        this.setItem('msgTime');
        this.showCountDown();

        const res = await getSmsCaptcha({
          mobile: this.formData.phoneNum,
          appKey: localStorage.oldGetItemLocal('appId') || ''
        });
        if (res.status == -1) {
          const errText = res ? res.msg || res.message : '获取验证码失败，请稍后重试';
          this.isSend = false;
          Toast(errText);
        } else {
          this.isSend = true;
        }
      } catch (error) {
        const errText = error ? error.message || error.errorMsg || error.msg : '获取验证码失败，请稍后重试';
        Toast(errText);
      }
    },
    countDownFinish() {
      this.isSend = false;
    },
    async login() {
      try {
        if (!this.verify()) {
          return;
        }
        const params = {
          mobile: this.formData.phoneNum,
          smsCaptcha: this.formData.smsCode,
          thirdUserId: localStorage.getItem('thirdUserId'),
          appKey: localStorage.oldGetItemLocal('appId') || ''
          //   channelCode: '',
          // token: localStorage.getItem("huijun")
        };
        this.loading = true;
        const { code = '', data = {} } = await huijunRegisterBySmsForMall(params);
        console.log(data, 'huijunRegisterBySmsForMall');
        this.loading = false;
        if (!/20\d/.test(code)) {
          Toast(data.msg);
          clearLoginInfo();
        } else {
          // 埋点
          this.bindUser(data);
          console.log('loginWithSmsCode', data);
          const { odyUserLoginInfo, loginUserResp, token, refreshToken, thirdChannelInfo = {} } = data;
          const ut = odyUserLoginInfo.ut;
          localStorage.setItem(
            'unionLoginData',
            JSON.stringify(
              {
                ...data,
                thirdChannelInfo: {
                  ...thirdChannelInfo,
                  channelCode: getChannelCode() || ''
                }
              } || {}
            )
          );
          localStorage.setItem('ut', ut);
          localStorage.setItem('jk-token', token);
          localStorage.setItem('refresh-token', refreshToken);
          localStorage.setItem('userInfo', JSON.stringify({ ...odyUserLoginInfo, ...loginUserResp }));

          this.$nextTick(() => {
            this.confirm();
          });
        }
      } catch (error) {
        console.log(error, '==============');
        this.loading = false;
        const errMsg = (error && (error.errorMsg || error.message || error.msg)) || '服务异常，请稍后再试';
        Toast(errMsg);
        clearLoginInfo();
      }
    },
    showSevice(type) {
      this.serviceType = type;
      const partName = this.serviceType == 1 ? '服务协议' : '隐私政策';
      this.serviceContent = '';
      this.serviceTitle = '';
      getUserAgreement({ contractTypeName: partName })
        .then(res => {
          this.serviceContent = res.data.content;
          this.serviceTitle = res.data.name;
        })
        .finally(() => {
          console.log('getUserAgreement----finally');
          this.show1 = true;
          this.$nextTick(() => {
            this.$refs.serviceContent.scrollTop = 0;
          });
        });
    },
    setItem(key) {
      const time = Date.parse(new Date()) + 1000 * 60;
      sessionStorage.setItem(key, time);
    },
    // 剩余秒数获取
    secondLimit(key) {
      const time = sessionStorage.getItem(key);
      if (time && time !== 'NaN') {
        var scecond = time - Date.parse(new Date());
        if (scecond < 0) {
          // 过期
          return 0;
        } else {
          // 向上取整
          return Math.ceil(scecond / 1000);
        }
      } else {
        return 0;
      }
    },
    removeItem(key) {
      sessionStorage.removeItem(key);
    },
    // 判断是否读秒显示倒计时
    showCountDown() {
      var s = this.secondLimit('msgTime');
      if (s > 0) {
        this.time = s * 1000;
        this.isSend = true;
      } else {
        this.isSend = false;
      }
    },
    // 埋点
    bindUser(data) {
      // 登录成功，设置埋点用户属性
      const { loginUserResp = {} } = data;
      const profileParams = {
        login_user_id: loginUserResp.userId,
        user_unity: loginUserResp.userId + '_10',
        phone_number: loginUserResp.phone,
        register_time: parseTime(loginUserResp.registerTime)
      };
      // this.$sensors.loginWithKey('$identity_login_id',profileParams.user_unity)
      this.$sensors.login(profileParams.user_unity);
      this.$sensors.setProfile(profileParams);
      // 注册
      // register_phone	注册手机号	STRING
      // register_source	注册来源	STRING	1-商品详情页，2-购物车页
      this.$sensors.track(
        'Register',
        parseParams({
          register_phone: loginUserResp.phone,
          register_source: this.register_source
        })
      );
    }
  }
};
</script>
<style lang="scss" scoped>
$field-placeholder-text-color: #84899b;
@mixin field-placeholder {
  height: 0.24rem;
  font-size: 0.16rem;
  font-family: 'PingFang TC';
  font-weight: 400;
  color: rgba(0, 0, 0, 0.2) !important;
  line-height: 0.24rem;
}
#login-pop .login-view__form {
  padding: 0.24rem 0.12rem 0;
  .title {
    height: 0.24rem;
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.18rem;
    font-weight: 500;
    font-family: 'PingFang SC';
    text-align: center;
    line-height: 0.24rem;
    margin-bottom: 0.12rem;
  }
  .desc {
    // height: 0.48rem;
    opacity: 1;
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.16rem;
    font-weight: 400;
    font-family: 'PingFang SC';
    text-align: justify;
    line-height: 0.24rem;
    margin-bottom: 0.12rem;
  }
  /deep/ .van-cell {
    // border-bottom: 1rem solid #e5e5e5;
    padding: 0.11rem 0 0.11rem 0;
    background-color: #fff;
    .van-field__control {
      height: 0.24rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--normal-text-color);
      line-height: 0.24rem;
    }
    &::after {
      background: var(--normal-border-color);
      border: unset;
    }
  }
  /deep/ .van-icon-clear {
    font-size: 0.16rem;
    width: 0.16rem;
    height: 0.16rem;
    line-height: 0.16rem;
    padding-right: 0.24rem;
  }
  // .validate-smscode {
  //   /deep/ .van-icon-clear {
  //     padding-right: 0.36rem;
  //   }
  // }
  .bottom-line {
    display: flex;
    align-items: center;
    border-bottom: 0.01rem solid #ebedf0;
    position: relative;
    .clearable {
      position: absolute;
      top: 0.08rem;
      right: 0;
      z-index: 1000;
      padding: 0.08rem;
    }
    .clearableCode {
      position: absolute;
      top: 0.12rem;
      right: 1.12rem;
      z-index: 1000;
      padding: 0.04rem;
    }
  }
  .sms-btn {
    width: 1.12rem;
    height: 0.24rem;
    font-size: 0.16rem;
    font-family: 'PingFang TC';
    font-weight: 400;
    color: rgba(0, 0, 0, 0.2);
    line-height: 0.24rem;
    text-align: right;
    /deep/ .van-count-down {
      display: inline-block;
      height: 0.24rem;
      font-size: 0.16rem;
      font-family: 'PingFang TC';
      font-weight: 400;
      color: rgba(0, 0, 0, 0.2);
      line-height: 0.24rem;
    }
  }
  .sms-btn-theme {
    color: #44cc77;
  }
  .tel_tag {
    height: 0.24rem;
    font-size: 0.16rem;
    font-family: 'PingFang TC';
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    line-height: 0.24rem;
  }

  ::-webkit-input-placeholder {
    @include field-placeholder;
  }

  /* Firefox 4-18 */
  :-moz-placeholder {
    @include field-placeholder;
  }

  /* Firefox 19-50 */
  ::-moz-placeholder {
    @include field-placeholder;
  }

  /* - Internet Explorer 10–11
    - Internet Explorer Mobile 10-11 */
  :-ms-input-placeholder {
    @include field-placeholder;
  }

  /* Edge (also supports ::-webkit-input-placeholder) */
  ::-ms-input-placeholder {
    @include field-placeholder;
  }

  /* CSS Working Draft */
  ::placeholder {
    @include field-placeholder;
  }

  .submit-box {
    margin-top: 0.24rem;
    user-select: none;
    display: flex;
    justify-content: space-between;
    .van-button {
      width: 1.64rem;
      height: 0.48rem;
      border-radius: 0.24rem;
      opacity: 1;
      font-size: 0.18rem;
      font-family: 'PingFang SC';
      font-weight: 400;
      border: 0.01rem solid rgba(0, 0, 0, 0.05);
      background: rgba(0, 0, 0, 0.05);
      line-height: 0.24rem;
      &.van-button--primary {
        font-weight: 500;
      }
    }
    .van-button--disabled {
      opacity: 0.6;
    }
    .gry-layer {
      // opacity: 0.6;
      background: #b4ebc9;
    }
  }
}
.login-rules {
  text-align: left;
  margin-top: 0.24rem;
  height: 0.16rem;
  font-size: 0.12rem;
  font-family: 'PingFang TC';
  font-weight: 400;
  color: var(--normal-text-color);
  line-height: 0.16rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  .login-agree-box {
    padding: 0rem 0.08rem 0.08rem 0rem;
  }
  .login-agree {
    width: 0.16rem;
    height: 0.16rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 0rem 0.01rem rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  .login-agree-img {
    width: 0.16rem;
    height: 0.16rem;
  }
  .ddjk-service-protocal,
  .ddjk-privacy-policy {
    color: #44cc77;
  }
}
.service {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .service-top {
    height: 0.48rem;
    text-align: center;
    position: relative;
    .service-title {
      height: 0.48rem;
      line-height: 0.48rem;
      color: rgba(0, 0, 0, 0.8);
      font-size: 0.18rem;
      font-weight: 500;
      font-family: 'PingFang SC';
    }
    .icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 0.48rem;
      height: 0.48rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .service-protocol {
    padding: 0.12rem;
    flex: 1;
    overflow: auto;
    width: 100%;
  }
}
</style>
