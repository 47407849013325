export default [
  {
    path: '/physicianVisits',
    name: 'physicianVisits',
    component: () => import('@mall/views/shopingMall/physicianVisits.vue'),
    meta: {
      title: '购药问诊'
    }
  }
];
