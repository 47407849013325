export default [
  {
    path: '/groupBuy/index',
    name: 'groupBuyIndex',
    component: () => import('@mall/views/groupBuy/Index.vue'),
    meta: { title: '幂健康拼购' }
  },
  {
    path: '/groupBuy/detail',
    name: 'groupBuyDetail',
    component: () => import('@mall/views/groupBuy/Detail.vue'),
    meta: { title: '幂健康拼购' }
  },
  {
    path: '/groupBuy/trailer',
    name: 'GroupBuyTrailer',
    component: () => import('@mall/views/groupBuy/trailer.vue'),
    meta: { title: '预告列表' }
  }
];
