<template>
  <div id="app" :style="themeStyle">
    <keep-alive :include="kpAlives">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <jk-loading v-if="isLoading"></jk-loading>
    </keep-alive>
  </div>
</template>

<script>
import { isInApp } from '@/utils/index';
import { bs_getToken } from '@/utils/bridge';
import { getCommonProperty } from './api/http_mall';
import JkLoading from '@/components/jk-loading.vue';
import { hideShareMenuItems } from '@/utils/initWxShare';

export default {
  name: 'App',
  components: { JkLoading },
  computed: {
    kpAlives() {
      return this.$store.state.cache.kpAlives;
    },
    isLoading() {
      return this.$store.state.cache.isLoading;
    },
    themeStyle() {
      return this.$store.state.common.themeStyle;
    }
  },
  created() {
    this.setShare();
  },
  mounted() {
    if (isInApp()) {
      bs_getToken();
      let headers = sessionStorage.getItem('headers');
      if (headers) {
        headers = JSON.parse(headers);
        const uid = headers.uid || '';
        this.$store.commit('SET_UID', uid);
      }
    }
    // this.getCommonProperty();
  },
  methods: {
    hideShareMenuItems,
    async setShare() {
      await hideShareMenuItems(true);
    },
    async getCommonProperty() {
      try {
        // customSetting ("是否设置过自定义风格 0:未设置 1:已设置")
        // 后台未设置自定义主题时，使用store里的默认主题色配置
        const { data } = await getCommonProperty({
          type: 0,
          appKey: localStorage.oldGetItemLocal('appId')
        });
        this.$store.commit('common/SET_COMMON_PROPERTY', {
          data,
          isDefaultTheme: !data.customSetting
        });
      } catch (error) {
        this.$store.commit('common/SET_COMMON_PROPERTY', { isDefaultTheme: true });
      }
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--normal-text-color);
  font-size: 14px;
  height: 100%;
  margin: 0 auto;
  min-width: 320px;
  max-width: 540px;
}
/* 淡入淡出 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
