import sensors from './sensorsdata.es6.min.js';
import { server_url } from './config';
// import {bs_getToken} from '../bridge'
import { isInApp, getQueryString, getUniChannelCode, getChannelId } from '@/utils';
//, parseTime

class senesordata {
  newSensors() {
    sensors.init({
      server_url: server_url,
      app_js_bridge: true,
      show_log: true,
      heatmap: {
        // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'not_collect',
        // 是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'not_collect'
      }
    });

    if (!isInApp()) {
      let params = {};
      const url = window.location.href;
      console.log('url', url);
      let first_entry_source = '';
      let second_entry_source = '';
      if (url.indexOf('first_entry_source') > -1 || url.indexOf('second_entry_source') > -1) {
        first_entry_source = getQueryString(url, 'first_entry_source');
        second_entry_source = getQueryString(url, 'second_entry_source');
      }
      console.log('first_entry_source', first_entry_source);
      console.log('second_entry_source', second_entry_source);
      params = {
        platform_type: 'H5', // 平台类型
        application_name: '幂健康用户端', // 应用名称
        app_id: '124',
        user_role: '1',
        first_entry_source: first_entry_source,
        second_entry_source: second_entry_source,
        channel_code: getUniChannelCode(),
        middle_station_system_code: 'YW07',
        activity_identification: '99', // 1-公众号推文 2-外部活动 99-未知
        main_channel_id: getChannelId()
      };
      const page_source = getQueryString(url, 'page_source');
      if (page_source == 19) {
        params.activity_identification = '1';
      }
      if (page_source == 20) {
        params.activity_identification = '2';
      }
      sensors.registerPage(params);
    }

    sensors.quick('isReady', function () {
      sensors.quick('autoTrackSinglePage');
    });
    return sensors;
  }

  static sensor;

  static getInstance() {
    if (!this.sensor) {
      this.sensor = this.prototype.newSensors();
    }
    return this.sensor;
  }
}

export default function (Vue) {
  Vue.prototype.$sensors = senesordata.getInstance();
}
