export default [
  {
    path: '/internalPurchase/internalPurchaseEquity',
    name: 'InternalPurchaseEquity',
    component: () => import('@mall/views/internalPurchase/internalPurchaseEquity.vue'),
    meta: { title: '内购权益' }
  },
  {
    path: '/internalPurchase/internalPurchaseInfo',
    name: 'InternalPurchaseInfo',
    component: () => import('@mall/views/internalPurchase/internalPurchaseInfo.vue'),
    meta: { title: '内购权益' }
  }
];
