import Vue from 'vue';
import {
  unionLogin,
  loginByTokenKey,
  getApplicationInfo,
  getCommonProperty,
  getChannelInfoByAppKey,
  addApiInterfaceInvokeRecord
} from '@mall/api/http_mall';
import {
  parseTime,
  // setCookies,
  getUniChannelCode,
  getQueryString,
  initAuthInfo,
  getChannelId
} from '@/utils/index';
import { initStorageMethods } from '@/utils/storage.js';
import { getLoginInfo } from '@mall/api/http';
import store from '@mall/store';

export function setUserLog(invokeUrl, functionType, invokeType, inputOutputParam) {
  const params = { invokeUrl, functionType, invokeType, inputOutputParam };
  addApiInterfaceInvokeRecord(params)
    .then(res => {
      console.log(res, 'res-addApiInterfaceInvokeRecord');
    })
    .catch(err => {
      console.log(err, 'err-addApiInterfaceInvokeRecord');
    });
}

export const unionLoginFn = async (type, params, next, to) => {
  let result;
  store.commit('cache/SET_LOADING', true);
  try {
    // 联合登录 e.g.
    const { data } = type == 'tokenLogin' ? await loginByTokenKey(params) : await unionLogin(params);
    result = data;
    // 存储登录结果，更新本地缓存ut
    const { odyUserLoginInfo = {}, loginUserResp, token, refreshToken, paramMap = {} } = data;

    if (type == 'tokenLogin') {
      !!paramMap.returnUrl && (store.getters['cache/GET_RETURNURL'] = paramMap.returnUrl);
      const appid = paramMap.appid || '';
      localStorage.oldSetItemLocal('paramMap', JSON.stringify(paramMap) || {});
      localStorage.oldSetItemLocal('appId', appid); // appid
      localStorage.oldSetItemLocal('appMark', appid); // app缓存隔离标记
      localStorage.setItem('paramMap', JSON.stringify(paramMap) || {});
      localStorage.setItem('appId', appid);
      localStorage.setItem('appMark', appid);
      console.log(localStorage.getItem('appId'));
      console.log(localStorage.oldGetItemLocal('appId'));
      if (localStorage.oldGetItemLocal('appId')) {
        initStorageMethods();
      }
    }
    const { ut = '' } = odyUserLoginInfo;
    const tokens = token || data['jk-token'];
    localStorage.setItem('unionLoginData', JSON.stringify(data || {}));
    localStorage.setItem('ut', ut);
    localStorage.setItem('jk-token', tokens);
    localStorage.setItem('refresh-token', refreshToken);
    localStorage.setItem('userInfo', JSON.stringify({ ...odyUserLoginInfo, ...loginUserResp }));
    localStorage.setItem('paramMap', JSON.stringify(paramMap) || {});
    console.log('联合登录完成');

    commonSensorRegisterPage();

    // 登录成功，设置埋点用户属性
    const profileParams = {
      login_user_id: loginUserResp.userId,
      user_unity: loginUserResp.userId + '_1',
      phone_number: loginUserResp.phone,
      register_time: parseTime(loginUserResp.registerTime),
      user_center_id: loginUserResp.userId
    };
    Vue.prototype.$sensors.login(profileParams.user_unity);
    Vue.prototype.$sensors.setProfile(profileParams);
    next();
  } catch (e) {
    console.log(e, JSON.stringify(e), 'error----unionLoginFn');
    // const logParams = 'error：' + JSON.stringify(e) + '----' + JSON.stringify(params) + '----' + JSON.stringify(result);
    const logParams = {
      errRes: e,
      inputParams: params,
      outputRes: result
    };
    setUserLog(window.location.href, 'unionLoginFn', 'unionLoginFn', JSON.stringify(logParams));
    next({ path: '/NotFound' });
  } finally {
    store.commit('cache/SET_LOADING', false);
  }
};

export function commonSensorRegisterPage() {
  let paramsTrack = {};
  const url = window.location.href;
  let first_entry_source = '';
  let second_entry_source = '';
  if (url.indexOf('first_entry_source') > -1 || url.indexOf('second_entry_source') > -1) {
    first_entry_source = getQueryString(url, 'first_entry_source');
    second_entry_source = getQueryString(url, 'second_entry_source');
  }
  console.log('first_entry_source', first_entry_source);
  console.log('second_entry_source', second_entry_source);
  // let loginUserResp = JSON.parse(localStorage.getItem('userInfo') || '{}')
  console.log('getUniChannelCode', getUniChannelCode());
  paramsTrack = {
    platform_type: 'H5', // 平台类型
    application_name: '幂健康用户端', // 应用名称
    app_id: '124',
    user_role: '1',
    first_entry_source: first_entry_source,
    second_entry_source: second_entry_source,
    // phone_number: loginUserResp.phone,
    // user_unity: loginUserResp.userId + "_1",
    // register_time: parseTime(loginUserResp.registerTime),
    // login_user_id: loginUserResp.userId,
    // user_center_id: loginUserResp.userId,
    channel_code: getUniChannelCode(),
    middle_station_system_code: 'YW07',
    activity_identification: '99', // 1-公众号推文 2-外部活动 99-未知
    main_channel_id: getChannelId()
  };
  const page_source = getQueryString(url, 'page_source');
  if (page_source == 19) {
    paramsTrack.activity_identification = '1';
  }
  if (page_source == 20) {
    paramsTrack.activity_identification = '2';
  }
  Vue.prototype.$sensors.registerPage(paramsTrack);
}
export async function getUserCommonProperty(to) {
  console.error(to, 'getUserCommonProperty');
  initAuthInfo(to);
  if (to.fullPath.indexOf('/internalPurchase/internalPurchaseEquity') > -1) {
    const { code, data } = await getLoginInfo(to.query.sysChannelCode || '');
    if (code == 200 && data && data.appId) {
      localStorage.oldSetItemLocal('appId', data.appId); // appid
      localStorage.oldSetItemLocal('appMark', data.appId); // app缓存隔离标记
    }
  }
  const pathArr = ['/index', '/drugStore'];
  try {
    // customSetting ("是否设置过自定义风格 0:未设置 1:已设置")
    // 后台未设置自定义主题时，使用store里的默认主题色配置
    const { data } = await getCommonProperty({
      type: 0,
      appKey: localStorage.oldGetItemLocal('appId') || localStorage.getItem('appId') || ''
    });
    store.commit('common/SET_COMMON_PROPERTY', {
      data,
      isDefaultTheme: !data.customSetting
    });
    console.log(to.path, 'pathArr.includes(to.path)');
    if (pathArr.includes(to.path)) {
      let pageTitle = '商城首页';
      if (data.indexTitle) {
        pageTitle = data.indexTitle;
      } else {
        pageTitle = data.indexType == '1' ? '选购药品' : '商城首页';
      }
      setTimeout(() => {
        document.title = pageTitle;
      }, 0);
    }
  } catch (error) {
    if (pathArr.includes(to.path)) {
      setTimeout(() => {
        document.title = '商城首页';
      }, 0);
    }
    store.commit('common/SET_COMMON_PROPERTY', { isDefaultTheme: true });
  }
}

export const getSystemConfig = async (params, next) => {
  store.commit('cache/SET_LOADING', true);
  let result;
  try {
    // const utExpiresTime = 30 * 24 * 60 * 60;
    const { loginType, userId, thirdAppType, ...param } = params;
    const { data = [] } = await getApplicationInfo(param);
    result = data;
    localStorage.setItem(
      'unionLoginData',
      JSON.stringify({
        'jk-token': '',
        'refresh-token': '',
        thirdChannelInfo: { channelCode: data ? data.join(',') : '' },
        userInfo: {},
        ut: ''
        // loginType,
        // thirdUserId: userId || '',
        // thirdAppType,
      })
    );
    const thirdId = localStorage.getItem('thirdUserId') || userId || '';
    const thirdType = localStorage.getItem('thirdAppType') || thirdAppType || '';
    localStorage.setItem('loginType', loginType);
    localStorage.setItem('thirdUserId', thirdId);
    localStorage.setItem('thirdAppType', thirdType);
    localStorage.setItem('channelCode', data ? data.join(',') : '');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('ut');
    localStorage.removeItem('jk-token');
    localStorage.removeItem('refresh-token');
    // setCookies("channelCode", data ? data.join(",") : "", utExpiresTime, "jk.com");
    commonSensorRegisterPage();
    next();
  } catch (error) {
    const logParams = {
      errRes: error,
      inputParams: params,
      outputRes: result
    };
    setUserLog(window.location.href, 'getSystemConfig', 'getSystemConfig', JSON.stringify(logParams));
    next({ path: '/NotFound' });
  } finally {
    store.commit('cache/SET_LOADING', false);
  }
};

// 获取当前appId对应的channelcode
export const getChannelCode = async (to, next) => {
  let result;
  try {
    const res = await getChannelInfoByAppKey(
      localStorage.oldGetItemLocal('appId') || localStorage.getItem('appId') || ''
    );
    result = res;
    if (res.data && Array.isArray(res.data.channelInfoList) && res.data.channelInfoList.length > 0) {
      const channelCode = res.data.channelInfoList.map(item => item.channelServiceCode);
      localStorage.setItem(
        'unionLoginData',
        JSON.stringify({
          thirdChannelInfo: {
            channelCode: channelCode.join(',') || ''
          }
        })
      );
      localStorage.setItem('loginType', to.query.loginType);
      localStorage.setItem('channelCode', channelCode.join(',') || '');

      next();
    } else {
      // const logParams =
      //   localStorage.oldGetItemLocal('appId') || localStorage.getItem('appId') || '' + '----' + JSON.stringify(result);
      const logParams = {
        errRes: {},
        inputParams: localStorage.oldGetItemLocal('appId') || localStorage.getItem('appId') || '',
        outputRes: result
      };
      setUserLog(window.location.href, 'getChannelCode', 'getChannelCode--else', JSON.stringify(logParams));
      next({ path: '/NotFound' });
    }
  } catch (err) {
    const logParams = {
      errRes: err,
      inputParams: localStorage.oldGetItemLocal('appId') || localStorage.getItem('appId') || '',
      outputRes: result
    };
    setUserLog(window.location.href, 'getChannelCode', 'getChannelCode--catch', JSON.stringify(logParams));
    next({ path: '/NotFound' });
  }
};
