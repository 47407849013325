export default [
  {
    path: '/orderTrack',
    name: 'orderTrack',
    component: () => import('@/projects/mall/views/order/orderTrack.vue'),
    meta: { title: '订单追踪' }
  },
  {
    path: '/orderInvoice',
    name: 'orderInvoice',
    component: () => import('@/projects/mall/views/order/invoice.vue'),
    meta: { title: '开具发票' }
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('@/projects/mall/views/order/list.vue'),
    meta: { title: '订单列表' }
  },
  {
    path: '/orderSearch',
    name: 'orderSearch',
    component: () => import('@/projects/mall/views/order/search.vue')
  },
  {
    path: '/orderSearchResult',
    name: 'orderSearchResult',
    component: () => import('@/projects/mall/views/order/searchResult.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/projects/mall/views/order/detail.vue'),
    meta: { title: '订单详情' }
  },
  {
    path: '/orderInspectNote',
    name: 'orderInspectNote',
    component: () => import('@mall/views/order/inspectNote.vue'),
    meta: { title: '检前注意事项' }
  },
  {
    path: '/orderSuccess',
    name: 'orderSuccess',
    component: () => import('@mall/views/order/orderSuccess.vue'),
    meta: { title: '' }
  },
  {
    path: '/order/paySuccess',
    name: 'PaySuccess',
    component: () => import('@mall/views/order/paySuccess.vue'),
    meta: { title: '幂支付平台' }
  },
  {
    path: '/order/editOrderAddress',
    name: 'EditOrderAddress',
    component: () => import('@mall/views/order/editOrderAddress.vue'),
    meta: { title: '修改地址' }
  },
  {
    path: '/orderSearch',
    name: 'orderSearch',
    component: () => import('@mall/views/order/search.vue')
  },
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    component: () => import('@mall/views/order/confirmOrder.vue'),
    meta: { title: '确认订单' }
  }
];
