import Vue from 'vue';
import store from '../store';
import Global from '@/projects/mall/utils/common.js';

// 格式化时间
Vue.filter('formatDate', function (value, pattern) {
  if (!value) {
    return '';
  }
  const type = pattern || 'yyyy-MM-dd hh:mm:ss';
  return Global.format_date(value, type);
});

// 金额格式化
Vue.filter('formatMoney', function (value) {
  const commonProperty = store.state.common.commonProperty || {};
  const { isShowPrice = 1 } = commonProperty;
  if (!isShowPrice) return '0.00';
  value = parseFloat(value) || 0;
  return value.toFixed(2);
});

Vue.filter('formatPriceWithOutZero', function (value) {
  if (value) {
    const val = ((parseFloat(value) * 100) / 100).toFixed(2);
    return val == parseInt(val) ? parseInt(val) : parseFloat(val);
  }
  return '';
});

// 元换算
Vue.filter('yuan', function (val) {
  const commonProperty = store.state.common.commonProperty || {};
  const { isShowPrice = 1 } = commonProperty;
  if (!isShowPrice) return '0';
  if (val) {
    var value = parseFloat(val).toFixed(2);
    var num = value.toString();
    return num.split('.')[0];
  } else {
    return '0';
  }
});
Vue.filter('jiao', function (val) {
  const commonProperty = store.state.common.commonProperty || {};
  const { isShowPrice = 1 } = commonProperty;
  if (!isShowPrice) return '00';
  if (val) {
    var value = parseFloat(val).toFixed(2);
    var num = value.toString();
    return num.split('.')[1];
  } else {
    return '00';
  }
});

Vue.filter('jiaoZeroSuppression', function (val) {
  if (val) {
    var value = parseFloat(val); // .toFixed(2)
    var num = value.toString();
    return num.indexOf('.') > -1 ? `.${num.split('.')[1]}` : '';
  } else {
    return '';
  }
});

Vue.filter('filterTime', function (val, len = 5) {
  if (val && typeof val === 'string') {
    return val.substring(0, len);
  } else {
    return '00:00';
  }
});
