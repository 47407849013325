// 社区投诉、话题、问答

export const SAVE_COMPLAINT_INFO = 'SAVE_COMPLAINT_INFO';
export const SAVE_COMPLAINT_TYPE = 'SAVE_COMPLAINT_TYPE';
export const UPDATE_COMPLAINT_DATA = 'UPDATE_COMPLAINT_DATA';
export const INIT_COMPLAINT_DATA = 'INIT_COMPLAINT_DATA';
export const DELETE_COMPLAINT_IMAGE = 'DELETE_COMPLAINT_IMAGE';
export const UPLOAD_COMPLAINT_IMAGES = 'UPLOAD_COMPLAINT_IMAGES';
export const UPDATE_COMPLAINT_SOURCE_TYPE = 'UPDATE_COMPLAINT_SOURCE_TYPE';
export const SHOW_UPLOAD_AND_DESCRIPTION = 'SHOW_UPLOAD_AND_DESCRIPTION';

// 社区消息

export const FETCH_MESSAGE_TAB_LIST_SUCCESS = 'FETCH_MESSAGE_TAB_LIST_SUCCESS';
export const FETCH_MESSAGE_LIKE_LIST_SUCCESS = 'FETCH_MESSAGE_LIKE_LIST_SUCCESS';
export const FETCH_MESSAGE_INFO_STREAM_LIST_SUCCESS = 'FETCH_MESSAGE_INFO_STREAM_LIST_SUCCESS';
export const FETCH_MESSAGE_ATTENTION_LIST_SUCCESS = 'FETCH_MESSAGE_ATTENTION_LIST_SUCCESS';
export const FETCH_MESSAGE_ANSWER_LIST_SUCCESS = 'FETCH_MESSAGE_ANSWER_LIST_SUCCESS';
export const UPDATE_TAB_ACTIVE = 'UPDATE_TAB_ACTIVE';
export const SAVE_TABLIST_DATA = 'SAVE_TABLIST_DATA';
export const SAVE_TABLIST_PAGEINFO = 'SAVE_TABLIST_PAGEINFO';
export const UPDATE_ATTENTION_STATUS = 'UPDATE_ATTENTION_STATUS';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_FINISH_STATUS = 'UPDATE_FINISH_STATUS';
export const UPDATE_REFRESH_STATUS = 'UPDATE_REFRESH_STATUS';
export const SET_DEFAULT_TAB_LIST = 'SET_DEFAULT_TAB_LIST';
export const UPDATE_SHOPPING_CART_NUMBER = 'UPDATE_SHOPPING_CART_NUMBER';

export const RESET_RECOMMEND_LIST = 'RESET_RECOMMEND_LIST';
export const SAVE_RECOMMEND_LIST = 'SAVE_RECOMMEND_LIST';

// 健康档案
export const SAVE_BASE_INFO = 'SAVE_BASE_INFO';
export const SAVE_BODY_INFO = 'SAVE_BODY_INFO';
export const SAVE_LIFE_INFO = 'SAVE_LIFE_INFO';
export const SAVE_MEDICAL_HISTORY = 'SAVE_MEDICAL_HISTORY';
export const SAVE_DEFAULT_SELECT_LIST = 'SAVE_DEFAULT_SELECT_LIST';
export const UPDATE_PARAMS_FLAG_INFO = 'UPDATE_PARAMS_FLAG_INFO';
export const UPDATE_DELETEIDS_DATA = 'UPDATE_DELETEIDS_DATA';
export const SAVE_PATIENT_ID = 'SAVE_PATIENT_ID';
export const UPDATE_ADD_SUCCESS_FLAG = 'UPDATE_ADD_SUCCESS_FLAG';
export const UPDATE_PARAMS_DATA = 'UPDATE_PARAMS_DATA';
export const CHECK_PATIENT_ID = 'CHECK_PATIENT_ID';
export const CHECK_PATIENT = 'CHECK_PATIENT';
export const PATIENT_DISEASES_LIST = 'PATIENT_DISEASES_LIST';
export const PATIENT_DRUG_DISEASE_LIST = 'PATIENT_DRUG_DISEASE_LIST';
export const PATIENT_SELF_DISEASE_TAG_LIST = 'PATIENT_SELF_DISEASE_TAG_LIST';
export const PATIENT_DRUG_LIST = 'PATIENT_DRUG_LIST';
export const CHECK_MERCHANT_ID = 'CHECK_MERCHANT_ID';
export const CHECK_GOOD_STORE_ID = 'CHECK_GOOD_STORE_ID';
export const ALLERGY_INFO_LIST = 'ALLERGY_INFO_LIST';

// 订单
export const UPDATE_DOCTORINFO = 'UPDATE_DOCTORINFO';
