export default [
  {
    path: '/hys/orderList',
    name: 'HysOrderList',
    component: () => import('@mall/views/hys/OrderList.vue'),
    meta: {
      title: '历史订单'
    }
  },
  {
    path: '/hys/orderDetail',
    name: 'HysOrderDetail',
    component: () => import('@mall/views/hys/OrderDetail.vue'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/hys/invoiceDetail',
    name: 'InvoiceDetail',
    component: () => import('@mall/views/hys/InvoiceDetail.vue'),
    meta: {
      title: '发票详情'
    }
  },
  {
    path: '/hys/logistic',
    name: 'HysLogistic',
    component: () => import('@mall/views/hys/Logistic.vue'),
    meta: {
      title: '物流详情'
    }
  }
];
