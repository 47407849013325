import Vue from "vue";
import loginPopVue from "./index.vue";

const LoginPop = Vue.extend(loginPopVue);

const loginPop = (config = {}) => {
    return new Promise((resolve, reject) => {
        const callback = ret => {
            if (ret === true) {
                resolve();
            } else if (ret === false) {
                reject();
            }
        };
        const loginpop = document.querySelector("#login-pop"); // 查询页面现有的login-pop
        if (loginpop) {
            // 如果已经存在 messageBox，则停止新的 loginpop 创建
            return false;
        }
        const instance = new LoginPop({
            data: {
                callback: config?.callback || callback,
                register_source: config.register_source || ''
            }
        });
        instance.$mount(); // 挂载但是并未插入dom
        document.body.appendChild(instance.$el); // 将dom插入body
    });
};

export default {
    install: Vue => {
        Vue.prototype.$loginPop = loginPop; // 将组件暴露出去，并挂载在Vue的prototype上
    }
};
