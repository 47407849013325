/* eslint-disable max-lines */
import Vue from 'vue';
// import { isEmpty } from "lodash";
import {
  fetchPatientId,
  getAndroidStatusBarHeight,
  bs_login,
  getToken,
  bs_backup,
  bs_syncToken,
  bs_getToken,
  bs_orderDetailGotoCustomerService
} from './bridge';
import emoji from '@/assets/common/emoji';
import { transCode } from '@/api/registrationProcess';
import store from '../projects/mall/store';
import { Toast } from 'vant';
import { goIm } from '@/utils/im';

import { getTokenModelByKey } from '@/api/diseaseCenterH5.js';
import { temporarySaveToken } from '@/projects/mall/api/http_mall';

import mallRouter from '@mall/router/index';

const vm = new Vue();

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @param {boolean} twoDigitsFlag 是否完整展示两位  如2010-09-01 如若不需要 则展示为2010-9-1
 * @returns {string}
 */
export function parseTime(time, cFormat, twoDigitsFlag = true) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'undefined' || time === null || time === 'null') {
    return '';
  } else if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  if (cFormat === '年-月-日') {
    return formatObj.y + '年' + formatObj.m + '月' + formatObj.d + '日';
  }
  if (cFormat === '月-日') {
    return formatObj.m + '月' + formatObj.d + '日';
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];

    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    // 只显示一位只针对y m d
    if (twoDigitsFlag && result.length > 0 && value < 10) {
      value = '0' + value;
    } else {
      if (['y', 'm', 'd'].indexOf(key) === -1 && result.length > 0 && value < 10) {
        value = '0' + value;
      }
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return '';
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return '';
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    })
  ).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') +
      '"}'
  );
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + '';
  const randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

// 替换邮箱字符
export function regEmail(email) {
  if (String(email).indexOf('@') > 0) {
    const str = email.split('@');
    let _s = '';
    if (str[0].length > 3) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += '*';
      }
    }
    var new_email = str[0].substr(0, 3) + _s + '@' + str[1];
  }
  return new_email;
}

// 替换手机字符
export function regMobile(mobile) {
  if (mobile.length > 7) {
    var new_mobile = mobile.substr(0, 3) + '****' + mobile.substr(7);
  }
  return new_mobile;
}

// 下载文件
export function downloadFile(obj, name, suffix) {
  const url = window.URL.createObjectURL(new Blob([obj]));
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  const fileName = parseTime(new Date()) + '-' + name + '.' + suffix;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// 判断数组是否包含另外一个数组
export function isContain(arr1, arr2) {
  for (var i = arr2.length - 1; i >= 0; i--) {
    if (!arr1.includes(arr2[i])) {
      return false;
    }
  }
  return true;
}

// 防抖
export function _debounce(fn, delay) {
  const _delay = delay || 200;
  let timer;
  return function () {
    const th = this;
    const args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, _delay);
  };
}
// // 节流
// export function _throttle(fn, interval) {
//   let last;
//   let timer;
//   const _interval = interval || 200;
//   return function() {
//     const th = this;
//     const args = arguments;
//     const now = +new Date();
//     clearTimeout(timer);
//     if (last && now - last < _interval) {
//       fn.apply(th, args);

//       timer = setTimeout(function() {
//         last = now;
//       }, _interval);
//     } else {
//       last = now;
//       // fn.apply(th, args);
//     }
//   };
// }

export function _throttle(func, delay) {
  var timer = null;
  var startTime = Date.now();
  return function () {
    var curTime = Date.now();
    var remaining = delay - (curTime - startTime);
    var context = this;
    var args = arguments;
    console.log('context,', context);

    clearTimeout(timer);
    if (remaining <= 0) {
      console.log('time1---,', context);

      func.apply(context, args);
      startTime = Date.now();
    } else {
      console.log('time2---,', context);
      // timer = setTimeout(func, remaining);
    }
  };
}

// 解析树形二级json生成index
export function ParseJSONSecondLevel(data, childrenAlias) {
  return data.map((item, index) => {
    if (item[childrenAlias]) {
      item[childrenAlias] = item[childrenAlias].map((subItem, subIndex) => ({
        ...subItem,
        index: subIndex
      }));
    }
    return {
      ...item,
      index,
      children: item[childrenAlias]
    };
  });
}

/**
 * 当天 周岁计算
 * @param {生日} strBirthday
 */
export function getAge(strBirthday) {
  let fullAge;
  const strBirthdayArr = strBirthday.split('-');
  const birthYear = strBirthdayArr[0];
  const birthMonth = strBirthdayArr[1];
  const birthDay = strBirthdayArr[2];

  const d = new Date();
  const nowYear = d.getFullYear();
  const nowMonth = d.getMonth() + 1;
  const nowDay = d.getDate();

  if (nowYear == birthYear) {
    fullAge = 0; // 同年 则为0岁
  } else {
    const ageDiff = nowYear - birthYear; // 年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        const dayDiff = nowDay - birthDay; // 日之差
        if (dayDiff < 0) {
          fullAge = ageDiff - 1;
        } else {
          fullAge = ageDiff;
        }
      } else {
        const monthDiff = nowMonth - birthMonth; // 月之差
        if (monthDiff < 0) {
          fullAge = ageDiff - 1;
        } else {
          fullAge = ageDiff;
        }
      }
    } else {
      fullAge = -1; // 返回-1 表示出生日期输入错误 晚于今天
    }
  }
  return fullAge; // 返回实岁年龄
}

/**
 * 毫秒转换为00:00:00
 * @param {*} time
 */
export function getDuration(time) {
  const days = time / 1000 / 60 / 60 / 24;
  const daysRound = Math.floor(days);

  const hours = Math.floor(time / 1000 / 60 / 60 - 24 * daysRound);
  const hoursRound = hours < 10 ? `0${hours}` : hours;

  const minutes = Math.floor(time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound);
  const minutesRound = Math.floor(minutes) < 10 ? `0${Math.floor(minutes)}` : Math.floor(minutes);

  const seconds = time / 1000 - 24 * 60 * 60 * daysRound - 60 * 60 * hoursRound - 60 * minutesRound;
  const secondsRound = Math.floor(seconds) < 10 ? `0${Math.floor(seconds)}` : Math.floor(seconds);

  const result = `${hours ? `${hoursRound}:` : ''}${minutes ? `${minutesRound}:` : '00:'}${secondsRound}`;
  return result;
}

/**
 * 延迟
 * @param {*} ms
 */
export function delay(ms, callback) {
  return new Promise(() => {
    setTimeout(() => {
      callback();
    }, ms);
  });
}

// emoji format
function toEmojiLink(chs) {
  return (emoji.emoticons.find(item => item.chs === chs) && emoji.emoticons.find(item => item.chs === chs).png) || '';
}
export function emojiFormat(val, size) {
  size = size || '25px';
  if (!val) return '';
  const h = 'http://ddjk-resource-public.oss-cn-hangzhou.aliyuncs.com/emotion';
  return val.replace(/\[([\u4e00-\u9fa5\w]+)\]/g, function (a) {
    return `<img style="width:${size};height: ${size};vertical-align:text-bottom;"; src="${h}/${toEmojiLink(a)}.imageset/${toEmojiLink(a)}.png" />`;
  });
}

/** 暂时对应1-20 后续有需要再添加 */
const ROMAN_NUM_MAP = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'XIII',
  'XIV',
  'XV',
  'XVI',
  'XVII',
  'XVIII',
  'XIX',
  'XX'
];
/**
 * 阿拉伯数字转换为罗马数字
 * @param {*} num
 */
export const arabic2RomanNum = num => (num - 1 >= 0 ? ROMAN_NUM_MAP[num - 1] : num);

// 获取数字对应的字母 1->A 2->B依次类推 65是大写字母A的ASCII
export const getSerialNum = index => String.fromCharCode(index + 65);

/**
 * 判断是否在幂健康app中
 * 101: 用户端ios
 * 102: 用户端android
 * 104: 用户端H5
 * 201: 服务端ios
 * 202: 服务端android
 * ua标识 eg: xxx/xxx DDJK/0.3.2
 * 124: 开放商城
 */
export function isInApp() {
  // const headers = sessionStorage.getItem('headers') || {};
  // const flat = 'jk-app-id';
  // const appFlag = Object.keys(headers).length && headers[flat];
  // console.log(appFlag, 'appFlag')
  const { userAgent: ua } = window.navigator;
  const jkFlag = ua.indexOf('DDJK') >= 0;
  const hysFlag = ua.indexOf('HYS') >= 0;
  return jkFlag || hysFlag;
}

// mjkApp
export function isMjkApp() {
  // const headers = sessionStorage.getItem('headers') || {};
  // const flat = 'jk-app-id';
  // const appFlag = Object.keys(headers).length && headers[flat];
  // console.log(appFlag, 'appFlag')
  const { userAgent: ua } = window.navigator;
  const jkFlag = ua.indexOf('DDJK') >= 0;
  return jkFlag;
}
// hysApp
export function isHysApp() {
  // const headers = sessionStorage.getItem('headers') || {};
  // const flat = 'jk-app-id';
  // const appFlag = Object.keys(headers).length && headers[flat];
  // console.log(appFlag, 'appFlag')
  const { userAgent: ua } = window.navigator;
  const hysFlag = ua.indexOf('HYS') >= 0;
  return hysFlag;
}

// 是否显示蒙层
export function isShowRxs() {
  /**
   * @ApiModelProperty("是否显示处方蒙层 0--不显示 1--显示")
   * private Integer isShowRxsmegma = 1;
   */
  const customTag = store.state.common.commonProperty || {};
  const { isShowRxsmegma } = customTag;
  return isShowRxsmegma == 1;
}

/**
 * 转换数据展示1200000000 =》 12亿，100000 =》 10万
 * @param {*} val
 */
export function translateNum(val) {
  let value = val;
  if (val >= 100000000) {
    // const splitArr = String(val / 100000000).split('.');
    // const intStr = splitArr[0];
    // const decimalStr = splitArr[1].length > 2 ? splitArr[1].slice(0, 2) : splitArr[1].length == 1 ? `${}`;
    // const str = `${intStr}.${sliceStr(val, 2)}`;
    // value = `${str}亿`;
    value = `${(val / 100000000).toFixed(2)}亿`;
  } else if (val >= 10000) {
    value = `${(val / 10000).toFixed(2)}万`;
  }
  return value;
}

/**
 * 这是项怡写的。（小马只是代码的搬运工）
 * 比胡锐的写的translateNum更好使。
 * @param {*} val 要处理的数字
 * @param {*} decimal 保留小数点几位  默认=1
 */
export function handlerNum(val, decimal = 1) {
  var fomatFloat = function (value, n) {
    var f = Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
    var s = f.toString();
    var rs = s.indexOf('.');
    if (rs < 0) {
      s += '.';
    }
    for (var i = s.length - s.indexOf('.'); i <= n; i++) {
      s += '0';
    }
    return s;
  };
  if (!val || val < 10000) {
    return val || 0;
  }
  if (val > 990000) {
    return '99万+';
  }
  return `${fomatFloat(parseInt((val / 10000) * 10, 0) / 10, decimal)}万`;
}

/**
 * 判断是否登录
 */
export function isLogin() {
  let accountToken = '';
  let ut = '';
  if (isInApp()) {
    const headers = JSON.parse(localStorage.getItem('headers') || '{}');
    ut = headers['ut'];
    accountToken = getToken() || '';
  } else {
    accountToken = localStorage.getItem('jk-token') || '';
    ut = localStorage.getItem('ut') || '';
  }
  return !!accountToken && !!ut;
}

/**
 * 移动终端浏览器版本信息
 */
export function versions() {
  const u = navigator.userAgent;
  // console.log("navigator.userAgent", u);
  /** android终端 */
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  /** ios终端 */
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  return {
    trident: u.indexOf('Trident') > -1, // IE内核
    presto: u.indexOf('Presto') > -1, // opera内核
    webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
    iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, // 是否iPad
    webApp: u.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
    isWeChat: !!u.toLowerCase().match(/micromessenger/i), // 微信浏览器
    isMiniProgram: !!u.toLowerCase().match(/miniProgram/i), // 微信小程序
    isInApp: u.indexOf('DDJK') >= 0 || u.indexOf('HYS') >= 0,
    isAliApp: u.indexOf('AliApp') > -1, // 支付宝小程序
    isDingTalk: !!u.toLowerCase().match(/dingtalk/i), // 钉钉
    isAndroid,
    isiOS,
    isMjkApp: isMjkApp(),
    isHysApp: isHysApp(),
    isNormalApp: isAndroid || isiOS
  };
}

/**
 * 分发不同环境执行方法
 * @param appFn app方法
 * @param miniFn 小程序方法
 * @param wechatFn 微信浏览器方法
 * @param otherBrowserFn 其他浏览器方法
 */
export function handleSchedule(appFn, miniFn, wechatFn, otherBrowserFn) {
  const env = versions();
  // console.log(env.isInApp,env.isMiniProgram,env.isWeChat)
  if (env.isInApp) {
    // app环境下，重定向到app收银台
    appFn && appFn();
  } else if (env.isMiniProgram) {
    // 小程序环境
    miniFn && miniFn();
  } else if (env.isWeChat) {
    // 微信环境
    wechatFn && wechatFn();
  } else {
    // 其他浏览器
    otherBrowserFn && otherBrowserFn();
  }
}

// 区分终端执行，参数使用对象形式
export function handleScheduleByEnd({ appFn, alipayFn, miniFn, wechatFn, otherBrowserFn }) {
  try {
    const env = versions();
    if (env.isInApp) {
      // app环境下
      appFn && appFn();
      console.log('appFn');
    } else if (env.isDingTalk) {
      // 其他
      otherBrowserFn && otherBrowserFn();
      console.log('其他浏览器环境');
    } else if (env.isAliApp) {
      // 支付宝小程序
      alipayFn && alipayFn();
      console.log('aliFn');
    } else if (env.isMiniProgram) {
      // 微信小程序环境
      miniFn && miniFn();
      console.log('miniFn');
    } else if (env.isWeChat) {
      // 微信浏览器环境
      wechatFn && wechatFn();
      console.log(4);
      console.log('微信浏览器环境');
    } else {
      // 其他
      otherBrowserFn && otherBrowserFn();
      console.log('其他浏览器环境');
    }
  } catch (e) {
    console.warn('handleSchedule', e);
  }
}

/**
 * 退出登录
 */
export function logout() {
  // localStorage.removeItem('userInfo');
  // localStorage.removeItem('jk-token');
  console.log('isInApp', isInApp());
  console.log('versions', versions());
  goToLogin();
}

/**
 * 判断自定义标签是否已经配置
 * 惠军通过接口返回配置自定义标签来判断是否展示蓝卡信息等
 * @returns
 */
export function isCusTag() {
  console.log(store.state.common, 'store.state.common');
  const customTag = store.state.common.commonProperty || {};
  const { reduceName = '', reduceBackgroundColour = '', reduceTextColour = '', afterReduceName = '' } = customTag;
  return reduceName && reduceBackgroundColour && reduceTextColour && afterReduceName;
}

/**
 * 跳转登录
 * APP中调原生登录方法
 * 浏览器则跳转M端登录
 */
export function goToLogin() {
  // 如果有自定义标签配置就先走这个弹窗登录
  const thirdAppType = localStorage.getItem('thirdAppType');
  console.warn(thirdAppType, 'thirdAppTypethirdAppTypethirdAppType');
  if (!isLogin() && thirdAppType == 1) {
    vm.$loginPop({
      callback: () => {
        //
      },
      register_source: '1'
    });
    return false;
  }
  if (versions().isMiniProgram) {
    window.wx.miniProgram.navigateTo({ url: `/pages/login/login?backUrl=${encodeURIComponent(location.href)}` });
    return;
  }
  if (isInApp()) {
    bs_login();
  } else {
    const host = location.href;
    console.log(host, 'host=======');
    // 修改多个拦截跳转问题
    if (host.indexOf('/login?backUrl') > -1) {
      return;
    }
    console.log(location, location.href, encodeURIComponent(location.href), 'goToLogin');
    // window.location.href = `${process.env.VUE_APP_HOST}/client/mall/#/login?backUrl=${encodeURIComponent(location.href)}`;
    mallRouter.push({
      name: 'login',
      query: {
        backUrl: encodeURIComponent(location.href)
      }
    });
  }
}

// export function goToLogin() {
//   if (versions().isMiniProgram) {
//     window.wx.miniProgram.navigateTo({ url:`/pages/login/login?backUrl=${encodeURIComponent(location.href)}`});
//     return;
//   }
//   if (isInApp()) {
//     bs_login();
//   } else {
//     const host = location.href;
//     console.log(host, 'host=======');
//     // 修改多个拦截跳转问题
//     if (host.indexOf('/login?backUrl') > -1) {
//       return;
//     }
//     console.log(location, location.href, encodeURIComponent(location.href), 'goToLogin');
//     // window.location.href = `${process.env.VUE_APP_HOST}/client/mall/#/login?backUrl=${encodeURIComponent(location.href)}`;
//     mallRouter.push({
//       name: 'login',
//       query: {
//         backUrl: encodeURIComponent(location.href),
//       },
//     });
//   }
// }

// 时间1位 前面补0
export function formatTimeStr(str) {
  str = str + '';
  if (str.length < 2) {
    str = '0' + str;
  }
  return str;
}

// 获取patientId
export function custGetPatientId() {
  const type = sessionStorage.getItem('flowType') || '';
  if (type === 'quickBuy') {
    console.log('quickBuy');
    console.log('quickBuy', sessionStorage.getItem('custPatientId'));
    return sessionStorage.getItem('custPatientId') || sessionStorage.getItem('patientId') || fetchPatientId();
  }
  const id = fetchPatientId() || sessionStorage.getItem('patientId');
  console.log(id);
  return id;
}

// sessionStorage
// export function getItem(key) {
//   if (!isEmpty(window.sessionStorage)) {
//     const data = window.sessionStorage.getItem(key);
//     return JSON.parse(data);
//   }
//   return null;
// }
//
// export function setItem(key, value) {
//   if (!isEmpty(window.sessionStorage)) {
//     window.sessionStorage.setItem(key, value);
//   }
// }

/**
 *  神策自定事件发送。
 * @param {*} saObj 神策包全局对象
 * @param {*} EventName 需要发送的自定义事件
 * @param {*} options 参数
 */
export function saSendData(saObj, EventName, options) {
  if (saObj && EventName && options) {
    saObj.track(EventName, options);
    return true;
  }
  return false;
}

export function getStatusBarHeight() {
  if (!isInApp()) {
    return 0;
  }
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    const height = window.screen.height;
    if (height === 812 || height === 896 || height === 844 || height === 926) {
      return 44;
    } else {
      return 20;
    }
  } else {
    console.log(getAndroidStatusBarHeight(), '反回的安卓信号栏高度');
    return getAndroidStatusBarHeight() || 20;
  }
}

/**
 * 解决重复点击按钮，重复发送请求
 * @param {要发送的请求} func
 * @param {手动解锁} manual
 */
export function ignoreMultiClick(func, manual = false) {
  let lock = false;
  return function (...args) {
    if (lock) return;
    lock = true;
    const done = () => (lock = false);
    if (manual) return func.call(this, ...args, done);
    const promise = func.call(this, ...args);
    Promise.resolve(promise).finally(done);
    return promise;
  };
}

export function numberFormate(value = 0, fixed = 1, max = 999999) {
  if (max < 10000 || value < 10000) {
    return value;
  } else if (value > max) {
    return parseInt(max / 10000) + '万+';
  } else if (value >= 10000) {
    return (value / 10000).toFixed(fixed) + '万';
  }
}

/**
 * 将天数转化为年和天的格式
 * @param {*} days
 * @returns
 */
export const formatDays = days => {
  if (typeof days !== 'number') return { day: days };
  const currentYear = new Date().getFullYear();
  const yearDays = isLeapYear(currentYear) ? 366 : 365;
  const mod = parseInt(days / yearDays);
  if (mod < 1) return { day: days };
  else return { year: mod, day: days % yearDays };
};

/**
 * 判断平年和闰年
 * @param {*} year
 * @returns
 */
function isLeapYear(year) {
  if (
    (year / 4 == Math.floor(year / 4) && year / 100 != Math.floor(year / 100)) ||
    (year / 400 == Math.floor(year / 400) && year / 3200 != Math.floor(year / 3200)) ||
    year / 172800 == Math.floor(year / 172800)
  ) {
    return true;
  }
  return false;
}
export const getTransCode = async cityCode => {
  const res = await transCode(cityCode);
  if (res && res.data && res.data.code === '200') {
    const { areaCode = '' } = res.data.data || {};
    return areaCode;
  } else {
    return '';
  }
};
export function toHHmmss(time) {
  let timeStr = '';
  const stringFormat = i => {
    return i < 10 ? `0${i}` : `${i}`;
  };
  let minuteTime = 0;
  let secondTime = 0;
  let hourTime = 0;
  if (time < 60) {
    timeStr = `00:${stringFormat(time)}`;
  } else if (time >= 60 && time < 3600) {
    minuteTime = parseInt(time / 60, 10);
    secondTime = parseInt(time % 60, 10);
    timeStr = `${stringFormat(minuteTime)}:${stringFormat(secondTime)}`;
  } else if (time >= 3600) {
    // eslint-disable-next-line no-underscore-dangle
    const _t = parseInt(time % 3600, 10);
    hourTime = parseInt(time / 3600, 10);
    minuteTime = parseInt(_t / 60, 10);
    secondTime = parseInt(_t % 60, 10);
    timeStr = `${stringFormat(hourTime)}:${stringFormat(minuteTime)}:${stringFormat(secondTime)}`;
  }
  return timeStr;
}
export function timeFormate(timmer) {
  if (!timmer) return '';
  //  时间规则 一分钟以内 刚刚；一小时以内 N分钟前；24小时以内 N小时前； 24小时以外(本年以内) mm-dd HH-MM；非本年 yyyy-mm-dd HH-MM
  const times = timmer ? new Date().getTime() - timmer : 0;
  let parseStr = '';
  if (times < 60 * 1000) {
    parseStr = '刚刚';
  } else if (times < 60 * 60 * 1000) {
    const mins = times / (60 * 1000);
    parseStr = `${Math.floor(mins)}分钟前`;
  } else if (times < 24 * 60 * 60 * 1000) {
    const hours = times / (60 * 60 * 1000);
    parseStr = `${Math.floor(hours)}小时前`;
  } else if (new Date(timmer).getFullYear() === new Date().getFullYear()) {
    parseStr = parseTime(timmer, '{m}-{d} {h}:{i}');
  } else {
    parseStr = parseTime(timmer, '{y}-{m}-{d}');
  }
  return parseStr;
}

export function splitAndDot(str, num = 8) {
  if (str && (typeof str === 'string' || typeof str === 'number')) {
    if (String(str).length > num) {
      return `${String(str).slice(0, num)}...`;
    }
  }
  return str;
}
/**
 * 获取价格整数部分
 * @param {*} val
 * @returns
 */
export function formatIntPrice(val) {
  const valStr = val ? Number(val).toFixed(2) : '0.00';
  return valStr.split('.')[0];
}

/**
 * 获取价格小数部分
 * @param {*} val
 * @returns
 */
export function formatDecimalPrice(val) {
  const valStr = val ? String(val) : '0';
  const decimal = valStr.indexOf('.') > -1 ? valStr.split('.')[1] : '';
  return decimal ? `.${decimal}` : '';
}

// 处理价格整数部分
export const formatBigIntPrice = val => {
  let value = val ? Number(val) : '';
  if (val >= 100000000) {
    [value] = String(val / 100000000).split('.');
  } else if (val >= 10000) {
    [value] = String(val / 10000).split('.');
  } else {
    value = String(val).indexOf('.') > -1 ? String(val).slice(0, String(val).indexOf('.')) : val;
  }
  return val ? value : 0;
};

// 处理价格小数部分(不补0)
export const formatBigDecimalPrice = val => {
  const originVal = val ? Number(val) : '';
  const valArr = String(originVal).split('.');
  const decimalStr = valArr[1] || '00';
  let value = '';
  let tag = '';
  if (originVal >= 100000000) {
    tag = '亿';
    [, value] = String(originVal / 100000000).split('.');
  } else if (originVal >= 10000) {
    tag = '万';
    [, value] = String(originVal / 10000).split('.');
  } else {
    value = decimalStr;
  }
  const str = value.slice(0, 2);
  return `.${str}${tag}`;
};

/**
 * 处理价格的整数部分或小数部分
 * @param {*} val 价格
 * @param {*} type  类型：不传整数 其他小数
 * @returns
 */
export const filterPriceIntOrDecimalPart = (val, type = 'int') => {
  const valStr = val ? (String(val).indexOf('.') == -1 ? `${val}.00` : Number(val).toFixed(2)) : '0.00';
  const intStr = valStr.split('.')[0];
  const decimalStr = valStr.split('.')[1];
  return type == 'int' ? intStr : `.${decimalStr}`;
};

export function getCookie(name) {
  name = completeCookieName(name);
  var prefix = name + '=';
  var start = document.cookie.indexOf(prefix);

  if (start == -1) {
    return null;
  }

  var end = document.cookie.indexOf(';', start + prefix.length);
  if (end == -1) {
    end = document.cookie.length;
  }

  var value = document.cookie.substring(start + prefix.length, end);
  return unescape(value);
}

// 处理价格小数部分(不补0)
export const formatDecimalPriceTwo = val => {
  const originVal = val ? Number(val) : '';
  const valArr = String(originVal).split('.');
  let decimalStr = valArr[1] || '00';
  if (decimalStr.length == 1) {
    decimalStr = decimalStr + '0';
  }
  let value = '';
  let tag = '';
  if (originVal >= 100000000) {
    tag = '亿';
    value = String(originVal / 100000000).split('.')[1] || '00';
    // value = decimal;
  } else if (originVal >= 10000) {
    tag = '万';
    value = String(originVal / 10000).split('.')[1] || '00';
    // value = decimal;
  } else {
    value = decimalStr;
  }
  const str = value.slice(0, 2);
  return `.${str}${tag}`;
};

/**
 * 症状打卡 数值换算
 * @param {*} params
 * @returns
 */
export const handleLevel = params => {
  if (Array.isArray(params) && params.length > 0) {
    const { length } = params;
    length < 7 && params.unshift(...new Array(7 - length).fill(0));
    return [...params];
  }
  return [0, 0, 0, 0, 0, 0, 0];
};
/**
 * H5返回事件
 */
export const backupH5 = () => {
  // 判断是否在app中
  if (isInApp()) {
    console.log('在app中返回');
    bs_backup();
  } else {
    console.log('在h5中返回');
    window.history.go(-1);
  }
};

export const getDiseaseShareParams = ({ diseaseCode, diseaseCenterName }) => {
  // 默认文案
  const str = '幂健康平台为您提供专家问诊、随访管理、康复训练、用药指导等全流程服务';
  const diseaseText = {
    // 冠心病
    J01964: '幂健康平台为您提供专家问诊、血压管理、手术评估、用药指导等全流程服务',
    // 糖尿病
    J02643: '幂健康平台为您提供专家问诊、血糖管理、饮食指导、用药建议等全流程服务',
    // 帕金森
    J07525: '幂健康平台为您提供专家问诊、用药指导、运动康复指导等全流程服务'
    // 脑卒中
    // J01964: '幂健康平台为您提供专家问诊、术后康复训练、用药指导等全流程服务',
  };
  const params = {
    imgUrl: 'https://ddjk-resource-public.cdn.jk.com/default_disease_share.png',
    title: `【${diseaseCenterName}】一站式管理服务平台`,
    content: diseaseText[diseaseCode] || str
  };
  return params;
};

export const getTeamShareParams = ({ teamLogo, teamName, centerName }) => {
  const params = {
    imgUrl: teamLogo,
    title: `${teamName}为您提供${centerName}全流程管理服务`,
    content: '为您提供专家团接诊、用药指导、随访管理等慢病全流程服务，助力您恢复身体健康！'
  };
  return params;
};

/**
 * 跳转H5登录 （项怡写的登录页）
 * @returns
 */
export const gotoLogin = () => {
  if (versions().isMiniProgram) {
    window.wx.miniProgram.navigateTo({ url: `/pages/login/login?backUrl=${encodeURIComponent(location.href)}` });
    return;
  } else {
    return (window.location.href =
      window.location.hostname === 'localhost'
        ? `http://localhost:8080/#/login`
        : `${process.env.VUE_APP_HOST}/client/diseaseCenterH5/#/login`);
  }
};

export const uuid = (len, radix) => {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [];
  var i;
  radix = radix || chars.length;
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data. At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
};

export const cookieInstance = {
  // 获得指定 Cookie
  get: function (name) {
    const ca = document.cookie.split(';');

    name = completeCookieName(name);
    name += '=';

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) == ' ') c = c.substring(1);

      if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }

    return '';
  },
  // 设置 Cookie
  set: function (name, value, hours, domain) {
    name = completeCookieName(name);
    const domainHost = domain || location.hostname;
    const now = new Date();
    now.setTime(now.getTime() + hours * 60 * 60 * 1000);
    let expires = 'expires=' + now.toUTCString() + ';';

    if (hours == undefined) {
      // 创建会话级cookie
      expires = '';
    }
    document.cookie = name + '=' + value + ';' + expires + 'path=/;' + 'domain=' + domainHost + ';';
    // document.cookie = name + "=" + value + ";" + expires + "path=/;";
  },
  // 移除指定 Cookie
  remove: function (name, domain, path) {
    name = completeCookieName(name);
    const domainHost = domain || location.hostname;
    const paths = path || '/';
    document.cookie = name + '=; expires=Fri, 31 Dec 1999 23:59:59 GMT; domain=' + domainHost + '; path=' + paths;
  }
};

const completeCookieName = name => {
  // 微信授权收银台页面w-auth.jk.com的页面需要从url取标记，localStorage不共享拿不到
  const appMark =
    getQueryVariable('appMark') || (localStorage.oldGetItemLocal && localStorage.oldGetItemLocal('appMark'));
  console.log('--completeCookieName', name + '-' + appMark);
  return name + '-' + appMark;
};

/**
 * Max-Age：单位秒，如果不设置则为，session类型的cookie，关闭浏览器就会丢失
 * @param {*} name
 * @param {*} value
 * @param {*} times
 * @param {*} domain
 */
export const setCookies = (name, value, times, domain, flag = true) => {
  name = flag ? completeCookieName(name) : name;
  // times 秒
  const domainHost = domain || location.hostname;
  document.cookie = name + '=' + value + ';Max-Age=' + times + ';path=/;' + 'domain=' + domainHost + ';';
};

export const tokenHandle = {
  // 设置请求头
  setConfig: function (config) {
    if (isInApp()) {
      bs_getToken();
    }
    let headers = localStorage.getItem('headers') || '{}';
    headers = {
      ...(JSON.parse(headers) || {}),
      ...config.headers
    };
    if (!isInApp()) {
      // 如果在小程序里 优先取地址后拼接token
      // if(versions().isMiniProgram){
      //   let token = getQueryVariable('token')
      //   console.log('location.href',window.location.href)
      //   console.log('tokentokentokentokentoken',token)
      //   if(token){
      //     const { jkToken,refreshToken } = JSON.parse(decodeURIComponent(token))
      //     localStorage.setItem('jk-token',jkToken)
      //     localStorage.setItem('refresh-token',refreshToken)
      //   }
      // }

      if (localStorage.getItem('jk-token')) {
        headers['jk-token'] = localStorage.getItem('jk-token');
      }
      if (localStorage.getItem('refresh-token')) {
        headers['refresh-token'] = localStorage.getItem('refresh-token');
      }
      // const isValidFlag = sessionStorage.getItem("jk-valid-flag");
      if (sessionStorage.getItem('jk-distributor-id')) {
        headers['jk-distributor-id'] = sessionStorage.getItem('jk-distributor-id');
      }
    }
    return headers;
  },
  // 将response的两个token 存入local中 等待下次请求使用
  setResponse: function (response) {
    if (response && response.headers && response.headers['jk-token'] && response.headers['refresh-token']) {
      const jkToken = response.headers['jk-token'];
      localStorage.setItem('jk-token', jkToken);
      const refreshToken = response.headers['refresh-token'];
      localStorage.setItem('refresh-token', refreshToken);
      console.log('setResponse', response);
      // const tokenExpiresTime = 7 * 24 * 60 * 60;
      // const refreshExpiresTime = 30 * 24 * 60 * 60;
      // setCookies('jk-token', jkToken, tokenExpiresTime, 'jk.com');
      // setCookies('refresh-token', refreshToken, refreshExpiresTime, 'jk.com');
      isInApp() ? bs_syncToken({ 'jk-token': jkToken, 'refresh-token': refreshToken }) : '';
    }
  },
  // 将两个token存入local中
  setToken: function (jkToken, refreshToken, ut = '') {
    jkToken && localStorage.setItem('jk-token', jkToken);
    refreshToken && localStorage.setItem('refresh-token', refreshToken);
    ut && localStorage.setItem('ut', ut);
  },
  // 获取两个token
  getToken: function () {
    return { 'jk-token': localStorage.getItem('jk-token'), 'refresh-token': localStorage.getItem('refresh-token') };
  },
  // 判断两个token是否都存在
  hasToken: function () {
    return localStorage.getItem('jk-token') && localStorage.getItem('refresh-token');
  },
  // 将两个token移除
  removeToken: function () {
    localStorage.setItem('jk-token', '');
    localStorage.setItem('refresh-token', '');
  },
  // 设置渠道
  setChannel: function (headers) {
    headers['channelSearchCode'] = getChannelCode() || [];
    return headers;
  },
  // 本地token配置
  localRequest: function (headers) {
    const regExp = /^(http|https).*.jk.com/.test(location.origin);
    if (!regExp && !headers['jk-token'] && !headers['refresh-token']) {
      headers['jk-token'] = '';
      headers['refresh-token'] = '';
      localStorage.setItem('jk-token', headers['jk-token']);
      localStorage.setItem('refresh-token', headers['refresh-token']);
      return headers;
    } else {
      return headers;
    }
  },
  setContentType: function (headers, type) {
    headers['Content-Type'] = type || 'application/json';
    return headers;
  }
};
// 获取路由参数
// export const getQueryVariable = (name) => {
//   let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
//   let arr = window.location.href.split('?');
//   let r = arr[1].match(reg);
//   if (r != null) return unescape(r[2]);
//   return '';
// };
/**
 * 张帆写的，小马只是搬运工，小马啥也不知道。
 * @param {*} name
 * @returns
 */
export const getQueryVariable = name => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const arr = window.location.href.split('?');
  if (arr.length > 2) {
    const r = arr[1].match(reg);
    const r2 = arr[2].match(reg);
    if (r) return unescape(r[2]);
    if (r2) return unescape(r2[2]);
  } else {
    const r = arr[1] ? arr[1].match(reg) : '';
    if (r) return unescape(r[2]);
  }
  return '';
};
/**
 * 获取url参数
 */
export function getUrlParam(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
/**
 * 获取url参数(hash地址)
 */
export function getUrlParamByHash(name) {
  if (location.hash.indexOf('?') == -1) return '';
  const arr = (location.hash || '').split('?')[1].split('&');
  const params = {};
  console.log(arr, 'arr');
  for (let i = 0; i < arr.length; i++) {
    const data = arr[i].split('=');
    if (data.length === 2) {
      params[data[0]] = data[1];
    }
  }
  return params[name];
}
/**
 * 春雨医生
 * 计算疾病患病时间，处理疾病名称超长
 */
export const formateStr = (params, ellipsis) => {
  const { diseaseName, diagnosisTime } = params;
  let diseaseNames = '';
  if (diseaseName.length > 10 && ellipsis) {
    diseaseNames = diseaseName.slice(0, 10) + '...';
  }
  const nowYear = new Date().getFullYear();
  const selectYear = diagnosisTime ? new Date(diagnosisTime).getFullYear() : nowYear;
  const timerStr = nowYear > selectYear ? `${diseaseNames} ${nowYear - selectYear}年` : diseaseNames;
  return timerStr;
};

// 清除登录信息
export const clearLoginInfo = () => {
  localStorage.removeItem('userInfo');
  tokenHandle.removeToken();
  cookieInstance.remove('jk-token', 'jk.com');
  cookieInstance.remove('loginUserId', 'jk.com');
  cookieInstance.remove('userId', 'jk.com');
  cookieInstance.remove('ut', 'jk.com');
  cookieInstance.remove('userInfo', 'jk.com');
};

// export const setPaymentLoginInfo = () => {
//   const loginJsonStr = getCookie('userInfo');
//   const jkToken = getCookie('jk-token');
//   const refreshToken = getCookie('refresh-token');
//   const odyUt = getCookie('ut');
//   const serviceGoodsChannelCode = getCookie('serviceGoodsChannelCode');
//   loginJsonStr && localStorage.setItem('userInfo', loginJsonStr);
//   jkToken && localStorage.setItem('jk-token', jkToken);
//   refreshToken && localStorage.setItem('refresh-token', refreshToken);
//   odyUt && localStorage.setItem('ut', odyUt);
//   serviceGoodsChannelCode && localStorage.setItem('serviceGoodsChannelCode', serviceGoodsChannelCode);
//   console.log(loginJsonStr, '==', jkToken, '==', refreshToken, '==', odyUt, 'loginJsonStr-------');
// };
// 环境渠道标识
const envChannel = isInApp() ? '' : versions().isMiniProgram ? 'MiniProgram' : 'H5';
export const myLocalStorage = {
  // 获取
  getItem(key, channel = '') {
    try {
      channel = channel || envChannel;
      const value = window.localStorage.getItem(key + channel);
      if (!value || value === '') {
        return null;
      }
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  },

  // 添加
  setItem(key, value, channel = '') {
    channel = channel || envChannel;
    window.localStorage.setItem(key + channel, JSON.stringify(value));
  },

  // 删除
  removeItem(key, channel = '') {
    channel = channel || envChannel;
    window.localStorage.removeItem(key + channel);
  },

  // 清除
  clear() {
    window.localStorage.clear();
  },

  // 长度
  length() {
    return window.localStorage.length;
  }
};

export const mySessionStorage = {
  // 获取
  getItem(key, channel = '') {
    try {
      channel = channel || envChannel;
      const value = window.sessionStorage.getItem(key + channel);
      if (value === null || value === undefined || value === '') {
        return null;
      }
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  },

  // 添加
  setItem(key, value, channel = '') {
    channel = channel || envChannel;
    window.sessionStorage.setItem(key + channel, JSON.stringify(value));
  },

  // 删除
  removeItem(key, channel = '') {
    channel = channel || envChannel;
    window.sessionStorage.removeItem(key + channel);
  },

  // 清除
  clear() {
    window.sessionStorage.clear();
  },

  // 长度
  length() {
    return window.sessionStorage.length;
  }
};

export const timeStorage = {
  // 获取
  getItem(key, channel = '') {
    try {
      if (!window.localStorage) {
        return null;
      }
      channel = channel || envChannel;
      const data = JSON.parse(window.localStorage.getItem(key + channel));
      // 判断设定的缓存已经过期
      const nowTime = new Date().getTime() - data.endTime;
      // 过期了，则删除
      if (nowTime >= 0) {
        window.localStorage.removeItem(key + channel);
        return null;
      }
      // 未过期，返回key对应的值
      return data.value;
    } catch {
      channel = channel || envChannel;
      window.localStorage.removeItem(key + channel);
      return null;
    }
  },

  // 添加
  setItem(key, value, time, channel = '') {
    try {
      // 如果不支持localStorage，则返回null
      if (!window.localStorage) {
        return null;
      }
      // 默认传值出错或不传值时，缓存时长为60分钟
      if (isNaN(time) || !time) {
        time = 60;
      }
      // 过期时间的毫秒数
      const endTime = new Date().getTime() + time * 60 * 1000;
      channel = channel || envChannel;
      window.localStorage.setItem(key + channel, JSON.stringify({ value: value, endTime: endTime }));
    } catch (e) {
      return null;
    }
  },

  // 删除
  removeItem(key, channel = '') {
    channel = channel || envChannel;
    window.localStorage.removeItem(key + channel);
  },

  // 清除
  clear() {
    window.localStorage.clear();
  },

  // 长度
  length() {
    return window.localStorage.length;
  }
};
const aliYunReg =
  /(img.jk.com)|(img1.jk.com)|(ddjk-resource-public.oss-cn-hangzhou.aliyuncs.com)|(images-pub.ehaoyao.com\/old\/)/;
const huaweiYunReg = /(jk998-middle.obs.cn-north-4.myhuaweicloud.com)/;
const ksYunReg = /(public-2020.ks3-cn-shanghai.ksyun.com)/;

export const webpEncoding = str => {
  if (aliYunReg.test(str)) {
    str = str + '?x-oss-process=image/format,webp';
  }
  if (huaweiYunReg.test(str)) {
    str = str + '?x-image-process=image/format,webp';
  }
  if (ksYunReg.test(str)) {
    str = str + '@base@tag=imgScale&F=webp';
  }
  return str;
};
// 价格保留2位小数
export const formatPrice = value => {
  return value ? ((parseFloat(value) * 100) / 100).toFixed(2) : 0;
};

// 判断是否为false,0时为true
export const isNull = value => {
  return !(value && value !== 0);
};

// 是否是微信小程序环境
export const isMiniProgram = () => {
  if (versions().isWeChat && window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
    // 微信环境下
    window.wx.miniProgram.getEnv(res => {
      if (res.miniprogram) {
        // 小程序环境下逻辑
        console.log('===小程序环境下===');
        return true;
      } else {
        // 非小程序环境下逻辑
        console.log('===非小程序环境下===');
        return false;
      }
    });
  } else {
    return false;
  }
};

// 判断是否可以转为Number类型
export const toNumber = value => {
  if (Number(value).toString() !== 'NaN') {
    return value / 1;
  } else {
    return value;
  }
};
/**
 * 拼接字符串
 * @param {list} list 目标数组
 * @param {field} field 数组中对象字段 对象数组必传
 *
 */
export const listFormat = (list, field) => {
  if (list && list.length && field) {
    return list.map(item => item[field] + '');
  } else {
    return [];
  }
};

/**
 * 保留几位小数，从小项那边来的，有问题是他写的辣鸡
 * @param {*} val
 * @param {*} decimal
 * @returns
 */
export function handlerNumDecimal(val, decimal = 1) {
  var fomatFloat = function (value, n) {
    var f = Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
    var s = f.toString();
    var rs = s.indexOf('.');
    if (rs < 0) {
      s += '.';
    }
    for (var i = s.length - s.indexOf('.'); i <= n; i++) {
      s += '0';
    }
    return s;
  };
  if (!val) {
    return null;
  }
  return `${fomatFloat(parseFloat((val * 100) / 100, 0), decimal)}`;
}

/**
 * 小程序 webview 获取token
 */
export const handlerLogin = async () => {
  const encryptData = getQueryVariable('encryptData');
  if (encryptData && encryptData !== 'undefined') {
    const res = await getTokenModelByKey(encryptData);
    if (res?.data?.code === '200' && res?.data?.data) {
      localStorage.setItem('jk-token', res?.data?.data?.token);
      localStorage.setItem('refresh-token', res?.data?.data?.refreshToken);
      const data = res.data;
      const authInfo = {
        ...data.data,
        ...data.data.loginUserResp,
        ...data.data.odyUserLoginInfo,
        'jk-token': data.data.token,
        'jk-app-id': 105
      };
      delete authInfo.token;
      localStorage.setItem('userInfo', JSON.stringify(authInfo));
      localStorage.setItem('ut', authInfo['ut'] || '');
      /**
       * 登陆信息保存一份到cookie，是为了解决一个问题
       * https://jira.yyjzt.com/browse/DDJK-24108
       * 产生此问题的原因：
       * 用户在面诊开方登陆后，登陆信息是保存到cookie。
       * 用户再去登陆小程序，小程序的登陆信息保存到localStorage
       * 用户在小程序处方购药，跳转到选择药房列表页，该页面是从cookie获取登陆信息，导致登陆信息不对等，跳转到登陆页
       * 故而在此登陆时，顺道保存一份到cookie
       */
      // const tokenExpiresTime = 7 * 24 * 60 * 60;
      // const utExpiresTime = 30 * 24 * 60 * 60;
      // setCookies('userInfo', JSON.stringify(authInfo), tokenExpiresTime, 'jk.com');
      // setCookies('jk-token', data.data.token, tokenExpiresTime, 'jk.com');
      // setCookies('ut', authInfo.ut, utExpiresTime, 'jk.com');
    }
  }
};

/**
 * @param {*} url
 * @returns
 */
export const splitFilename = url => {
  // URL从com/开始截取 截取到的截取从第4位开始返回
  const regex = /com\/.*\.(JPG|jpg|PNG|png|BMP|bmp|JPEG|jpeg|JFIF|jfif)/g;
  const res = url.match(regex);
  return (res && res.length > 0 && decodeURIComponent(res[0].slice(4))) || '';
};

/**
 广告位跳转统一这里处理
 source: 来源，还没定义的可以加一下，方便以后扩展
 source = 0 一小时达金刚区
 source = 1 全部分类页头部广告
 source = 2 商城首页金刚区
 source = 3 商城首页买好药
 source = 4 商城首页轮播
 source = 5 搜索热词
 */
export function advertClickProcessing(model) {
  console.log(model, 'model');
  if (!model.isNoTrack) {
    const trackData = {
      page_name: model.page_name || '商城首页',
      activity_type: model.activity_type, // 1-首页轮播，2-首页金刚区，7-精品专区
      site_id: model.siteId + 1 || '',
      activity_name: model.name || '',
      activity_id: model.id || '',
      go_url: model.linkUrl || '',
      cfda_name: model.cfda_name || '',
      commodity_channel_id: model.mpId || ''
    };
    this.$sensors_ddjk.track('ClickMallActivity', trackData);
  }
  if (model.refType == -1) {
    return;
  }
  // 跳转区分
  if (model.refType == '0' || model.refType == 27) {
    // 跳转cms
    toOtherApplication(model.linkUrl);
    // window.location.href = model.linkUrl
  } else if (model.refType == '2') {
    // 跳转商品详情
    mallRouter.push(`/goods/${model.refId}.html`);
  } else if (model.refType == '3') {
    // 跳转搜索页
    const keyword = model.productCode || '';
    console.log(keyword, 'model.productCode');
    mallRouter.push(`/storelist?keyWord=${keyword}`);
  } else if (model.refType == '10') {
    // 跳转类目页
    console.log(model.refId, 'model.refId');
    mallRouter.push(`/oneClassList?classLevel=${model.level}&categoryId=${model.refId}&title=${model.productCode}`);
  } else if (model.refType == '40') {
    // 跳转类目页
    if (model.linkUrl == 'all_categories') {
      // /全部分类页
      mallRouter.push('/classify/index');
    } else if (model.linkUrl == '1hour_home') {
      // 跳转全部分类页
      mallRouter.push('/classify/index');
    }
  }
}

export function toOtherApplication(linkUrl) {
  if (linkUrl) {
    if (isLogin()) {
      let unionLoginData = localStorage.getItem('unionLoginData');
      if (typeof unionLoginData == 'string') {
        unionLoginData = JSON.parse(unionLoginData);
      }
      const params = {
        appUserSecret: unionLoginData.appUserSecret || '',
        loginUserResp: unionLoginData.loginUserResp || '',
        odyUserLoginInfo: unionLoginData.odyUserLoginInfo || '',
        refreshToken: unionLoginData.refreshToken || '',
        token: unionLoginData.token || ''
      };
      let toPath = linkUrl;
      toPath = toPath.indexOf('?') > -1 ? toPath + '&appId=124&redirectType=H5' : toPath + '?appId=124&redirectType=H5';
      temporarySaveToken(params)
        .then(res => {
          if (res.code == '200') {
            const encryptData = res.data;
            toPath =
              toPath.indexOf('?') > -1
                ? toPath + '&ut=' + localStorage.getItem('ut') + '&encryptData=' + encryptData
                : toPath + '?ut=' + localStorage.getItem('ut') + '&encryptData=' + encryptData;
          } else {
            toPath =
              toPath.indexOf('?') > -1
                ? toPath + '&ut=' + localStorage.getItem('ut')
                : toPath + '?ut=' + localStorage.getItem('ut');
          }
          window.location.href = toPath;
        })
        .catch(() => {
          window.location.href =
            linkUrl.indexOf('?') > -1 ? linkUrl + '&redirectType=H5' : linkUrl + '?redirectType=H5';
        });
    } else {
      window.location.href = linkUrl.indexOf('?') > -1 ? linkUrl + '&redirectType=H5' : linkUrl + '?redirectType=H5';
    }
  }
}

/**
 * 根据渠道判断应用 localStorage
 */
export function getAppSource(channelCodeList) {
  // const { thirdChannelInfo: { channelCode } = {} } = JSON.parse(localStorage.getItem('unionLoginData') || '{}')
  const channelCode = getUniChannelCode();
  return channelCodeList.includes(channelCode);
}

/**
 * 根据渠道判断应用 cookie
 */
export function getAppSourceByCookie(channelCodeList) {
  const channelCode = getCookie('channelCode');
  return channelCodeList.includes(channelCode);
}

/**
 * 是否为普康宝应用
 * @returns {*}
 */
export function isPkbApp() {
  const channelCodeList = ['0000810003', '0000820003', '0000830003', '0000840003'];
  return getAppSource(channelCodeList);
}

/**
 * 是否为太平应用
 * @returns {*}
 */
export function isTpApp() {
  const channelCodeList = ['0001010001', '0001010003', '0001010004'];
  return getAppSource(channelCodeList);
}

/**
 * 是否为远盟应用
 * @returns {*}
 */
export function isYmApp() {
  const channelCodeList = ['1001420003', '1001410003', '1001400003', '1001420004', '1001410004', '1001400004'];
  return getAppSource(channelCodeList);
}

/**
 * 是否为宏信
 * @returns {*}
 */
export function hongxinChannel() {
  // const channelCodeList = ['100171', '100172']; 宏信为验证码，无法拿到渠道，所以用appId做隔离
  // https://open-mall.jk.com/client/mall/#/index
  // ?appid=3a0b10b08a524e119e007e474c0920d4&time=1680074455631&sign=8357755d82791cc180e977037eb041d6&loginType=2
  // https://open-mall.jk.com/client/mall/#/index
  // ?appid=ec5e73ce94514fa095e597c25f168f4a&time=1680074463401&sign=fbd3d4e66f1b216d10928a1b646eac9e&loginType=2
  const appIdList = ['3a0b10b08a524e119e007e474c0920d4', 'ec5e73ce94514fa095e597c25f168f4a'];
  const appId = localStorage.oldGetItemLocal('appId') || '';
  return appIdList.includes(appId);
}

/**
 * 是否为伊好应用
 * @returns {*}
 */
export function isYhApp() {
  const channelCodeList = ['0001390001', '0001390003'];
  return getAppSource(channelCodeList);
}

export const formatPriceWithNoneZero = value => {
  if (value) {
    const val = ((parseFloat(value) * 100) / 100).toFixed(2);
    return val == parseInt(val) ? parseInt(val) : parseFloat(val);
  }
  return 0;
};

// 获取字符串中的？后的参数
export const getQueryString = function (url, paramName) {
  const reg = new RegExp('(^|&)' + paramName + '=([^&]*)(&|$)', 'i');
  const arr = url.split('?');
  if (arr.length > 1) {
    const r = arr[1].match(reg);
    if (r != null) return unescape(r[2]);
  }
  return null;
};

// 获取登录返回的channelCode（多个渠道code）
// 获取所有渠道
export const getChannelCode = () => {
  const { thirdChannelInfo: { channelCode } = {}, channelInfoList = [] } = JSON.parse(
    localStorage.getItem('unionLoginData') && localStorage.getItem('unionLoginData') != 'undefined'
      ? localStorage.getItem('unionLoginData')
      : '{}'
  );
  let codeStr = '';
  const codeArr = [];
  if (channelInfoList.length) {
    channelInfoList.forEach(item => {
      if (item.serviceType == 'B2C') {
        codeArr.unshift(item.channelServiceCode);
      } else {
        codeArr.push(item.channelServiceCode);
      }
    });
  }
  codeStr = codeArr.join(',');
  const channelCodes = localStorage.getItem('channelCodes') || '';
  return channelCode || codeStr || channelCodes;
};

// 获取服务商品渠道code
export const getServiceCommodityChannelCode = () => {
  const { thirdChannelInfo: { serviceGoodsChannelCode } = {} } = JSON.parse(
    localStorage.getItem('unionLoginData') && localStorage.getItem('unionLoginData') != 'undefined'
      ? localStorage.getItem('unionLoginData')
      : '{}'
  );
  return serviceGoodsChannelCode || localStorage.getItem('serviceGoodsChannelCode') || '0000010005';
};

// 获取一个小渠道
export const getUniChannelCode = () => {
  const code = getChannelCode();
  const uniChannelCode = String(code).indexOf(',') > -1 ? String(code).split(',')[0] : code;
  return uniChannelCode;
};

/**
 * 获取大渠道id/大渠道code
 * @returns
 * 幂健康大渠道id为000001，有三个特殊渠道，'110001', '110003', '110101'
 * 其他渠道均为 channelId + channelServiceCode => 000097 + 0003 = 0000970003
 */
export const getChannelId = () => {
  // 以切割的方式拿CHANNELI
  const MJK_CHANNEL_ID = '000001';
  const code = getUniChannelCode();
  const excludeChannelCodeArr = ['110001', '110003', '110101'];

  // 幂健康不同于其他渠道，如果是幂健康，直接返回渠道ID
  if (excludeChannelCodeArr.includes(code.toString())) return MJK_CHANNEL_ID;

  const cusChannelId = code.toString().slice(0, 6);

  // 从接口拿CHANNELID
  const { channelInfoList = [] } = JSON.parse(
    localStorage.getItem('unionLoginData') && localStorage.getItem('unionLoginData') != 'undefined'
      ? localStorage.getItem('unionLoginData')
      : '{}'
  );

  const channelId = channelInfoList && channelInfoList.length && channelInfoList[0].channelId;
  // 如果接口没有返回CHANNELID，就取自己切割出来的

  return channelId || cusChannelId || '';
};

export const showConsultation = param => {
  console.log(param, 'showConsultation-param');
  /**
   * 2022.0213
   *
   * 需求背景：因好药师的业务合并到幂健康，需要调整客服相关
   * 需求一：将合力亿捷的原好药师客服域名切换到健康集团的需求
   *
   * 原web：https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000030555&chatId=8f3d552a-900a-4a7c-ae68-42539de0b597
   * 新web：https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000030555&chatId=8f3d552a-900a-4a7c-ae68-42539de0b597
   *
   * 需求二：将原好药师官网的电话更新为集团客服电话
   *
   * 原电话：400-648-5566
   * 新电话：400-8118-970
   */
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const accId = 'N000000030555';
  const cId = '8f3d552a-900a-4a7c-ae68-42539de0b597';
  const vId = userInfo.userId || '';
  const name = userInfo.username || '' ? encodeURIComponent(userInfo.username) : '';
  const mobile = userInfo.phone || '';
  const heli = 'https://im1c5366d.7x24cc.com/phone_webChat.html';
  const redirectUrl = `${heli}?accountId=${accId}&chatId=${cId}&visitorId=${vId}&nickName=${name}&phone=${mobile}`;
  console.log(redirectUrl, 'redirectUrl');
  if (isInApp()) {
    bs_orderDetailGotoCustomerService(redirectUrl);
    return false;
  }
  window.location.href = redirectUrl;
};

export const parseParams = obj => {
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    var param = {};

    for (var key in obj) {
      if (obj[key] === undefined || obj[key] === null) {
        param[key] = '';
      } else {
        param[key] = obj[key];
      }
    }
    return param;
  } else {
    return obj;
  }
};

// 需求单页面浏览
export const sensorsBrowseDemandList = (index = 0, pList = []) => {
  if (index) {
    console.log(pList, 'pList');
    const idsArr = [];
    if (pList && pList.length) {
      pList.forEach(i => {
        i.productGroups.forEach(c => {
          c.productList.map(m => {
            console.log(m.mpId, 'm');
            idsArr.push(`${m.mpId}`);
          });
        });
      });
    }
    console.log(idsArr, 'idsArr');
    vm.$sensors_ddjk.track('BrowseDemandList', {
      commodity_channel_id_list: idsArr
    });
  }
};

export function fn_countDown_auto(countDown_time, tmpl) {
  /* tmpl:倒计时模板 day_hour_minute_second */
  tmpl = tmpl || 'day_hour_minute_second';
  let day = 0;
  let hour = 0;
  let minute = 0;
  let second = 0;
  let countDown_txt = '';

  const second_d = countDown_time % (24 * 60 * 60); // 去掉天数剩余多少秒
  const second_h = second_d % (60 * 60); // 剩余多少秒; //去掉小时剩余多少秒

  day = Math.floor(countDown_time / (24 * 60 * 60));
  hour = Math.floor(second_d / (60 * 60));
  minute = Math.floor(second_h / 60);
  second = second_h % 60;
  if (tmpl.indexOf('day') > -1) {
    countDown_txt = day > 0 ? day + '天' : '0天';
  } else {
    hour = day * 24 + hour;
  }

  if (tmpl.indexOf('hour') > -1) {
    countDown_txt = countDown_txt + (hour > 0 ? hour + '小时' : '0小时');
  } else {
    minute = hour * 60 + minute;
  }

  if (tmpl.indexOf('minute') > -1) {
    countDown_txt = countDown_txt + (minute > 0 ? minute + '分钟' : '0分钟');
  } else {
    second = countDown_time;
  }

  if (tmpl.indexOf('second') > -1) {
    countDown_txt = countDown_txt + (second > 0 ? second + '秒' : '0秒');
  }

  return countDown_txt;
}

export function changImgUrl(src) {
  if (!src) return '';
  if (src.indexOf('https://jk998-middle.obs.cn-north-4.myhuaweicloud.com') > -1) {
    src = src.replace('https://jk998-middle.obs.cn-north-4.myhuaweicloud.com', 'https://jk998-middle.cdn.jk.com');
  } else if (src.indexOf('https://zyy-ehospital-public.obs.cn-north-4.myhuaweicloud.com') > -1) {
    src = src.replace(
      'https://zyy-ehospital-public.obs.cn-north-4.myhuaweicloud.com',
      'https://zyy-ehospital-public.cdn.jk.com'
    );
  } else if (src.indexOf('https://ddjk-resource-public.oss-cn-hangzhou.aliyuncs.com') > -1) {
    src = src.replace('https://ddjk-resource-public.oss-cn-hangzhou.aliyuncs.com', 'https://img.jk.com');
  } else if (src.indexOf('https://middle-img-public.oss-cn-hangzhou.aliyuncs.com') > -1) {
    src = src.replace('https://middle-img-public.oss-cn-hangzhou.aliyuncs.com', 'https://middle-img-public.cdn.jk.com');
  } else if (src.indexOf('https://ddjk-community-public.oss-cn-hangzhou.aliyuncs.com') > -1) {
    src = src.replace('https://ddjk-community-public.oss-cn-hangzhou.aliyuncs.com', 'https://img1.jk.com');
  }
  return src;
}

// 是否单一和秒杀等限购类促销
export const isSinglePromo = option => {
  // 秒杀   frontPromotionType  PromotionType  1012 秒杀 1 特价 7 折扣 8 直降
  return (option && option.length && [1, 7, 8].includes(option[0].promotionType)) || false;
};

// 过滤渠道, 默认过滤出o2o、b2c、服务商品这三种渠道（0001-o2o  0003-b2c  0005-服务商品）
export const getChannel = (serviceCodeArr = ['0001', '0003', '0005']) => {
  const channelInfoList =
    JSON.parse(localStorage.getItem('unionLoginData') || '{}')?.channelInfoList?.filter(item =>
      serviceCodeArr.includes(item.serviceCode)
    ) || [];
  // 生成对应渠道的映射关系
  const channelObj = {};
  channelInfoList.forEach(item => {
    switch (item.serviceCode) {
      case '0003':
        channelObj['b2c'] = item;
        break;
      case '0001':
        channelObj['o2o'] = item;
        break;
      case '0005':
        channelObj['serviceGoods'] = item;
        break;
    }
  });
  return channelObj;
};

// 是否幂健康渠道
export function isMJkChannel() {
  const channelCodeList = getChannelCode() ? getChannelCode().split(',') : []; // ["110001", "110003"];
  // let channelCode = getUniChannelCode();
  const list = channelCodeList.filter(a => a == '110001' || a == '110003');
  return list.length > 0;
}

export const initAuthInfo = ({ query = {}, name }) => {
  const host = 'w-auth.jk.com';
  console.warn(query, location.host, 'initWauthInfo');
  if (name == 'paywait' && location.host == host && versions().isWeChat) {
    const { appId = '', ut = '', channelCodes = '' } = query;

    console.log(appId, ut, channelCodes, 'route.query');

    // initStorageMethods(appMark);
    localStorage.oldSetItemLocal('appId', appId);
    localStorage.oldSetItemLocal('appMark', appId);
    localStorage.setItem('ut', ut);
    localStorage.setItem('channelCodes', channelCodes);
  }
};

/**
 * 平台客服
 * 获取im or phone
 * @param {*} type 店铺客服电话判断，有值取电话，没值走im判断逻辑
 * @returns
 * 要展示im的地方，有im取im，没有im取电话，没有电话，则隐藏，幂健康app逻辑特殊，不做隐藏处理
 */
export const getPlatformImOrPhone = (type = '') => {
  const { customerServiceUrl = '', customerServicePhone = '' } = store.state.common.commonProperty || {};
  const customerInfo = type
    ? customerServicePhone
      ? { label: 2, value: customerServicePhone }
      : null
    : customerServiceUrl
    ? { label: 1, value: customerServiceUrl }
    : customerServicePhone
    ? { label: 2, value: customerServicePhone }
    : null;
  return customerInfo;
};

/**
 * 店铺客服
 * 获取im or phone
 * @param {*} type 店铺客服电话判断，有值取电话，没值走im判断逻辑
 * @returns
 * 要展示im的地方，有im取im，没有im取电话，没有电话，直接报错，不做兜底处理
 */
export const getStoreImOrPhone = (type = '') => {
  const { contactImUrl = '', contactInformation = '' } = store.state.common.storeInfos || {};
  const customerInfo = type
    ? contactInformation
      ? { label: 2, value: contactInformation }
      : null
    : contactImUrl
    ? { label: 1, value: contactImUrl }
    : contactInformation
    ? { label: 2, value: contactInformation }
    : null;
  return customerInfo;
};

/**
 * im url存在，则跳im页面
 * im不存在，有电话，打电话
 * 都没有不操作
 * isStore 是否店铺客服
 */
export const callImOrPhone = (type = '', isStore = '', params = {}, source = '') => {
  if (isStore) {
    const { label = '', value = '' } = getStoreImOrPhone(type) || {};
    console.log(label, value, type, isStore, params, source, 'callImOrPhone');
    if (!value) {
      Toast('在线客服数据异常');
      return false;
    }
    window.location.href =
      label == 1
        ? goIm({
            ...params,
            imUrl: value
          })
        : `tel:${value}`;
  } else {
    const { label = '', value = '' } = getPlatformImOrPhone(type) || {};
    window.location.href = label == 1 ? goIm({ ...params, imUrl: value, source }) : `tel:${value}`;
  }
};

/**
 * 获取路径中第一个？后的参数
 */
export function getUrlParams(url) {
  console.log('urlll', url);
  const paramsObj = {};
  if (url) {
    let params = url.split('?')[1];
    let dealUrl;
    if (params) {
      dealUrl = decodeURIComponent(params);
      console.log('dealUrl', dealUrl);
      params = dealUrl.split('&');
      for (let i = 0; i < params.length; i++) {
        const key = params[i].split('=')[0];
        paramsObj[key] = params[i].replace(key + '=', '');
      }
    }
  }
  return paramsObj;
}

/*
  设备的像素比和设备分辨率表如下：
  iPhone 3GS  3.5寸 => 320x480 => 1
  iPhone 4/4s  3.5寸 => 320x480 => 2
  iPhone 5/5s/5c  4.0寸 =>  320x568 => 2
  iPhone 6   4.7寸 => 375x667 => 2
  iPhone 6Plus  5.5寸 => 414x736 => 3
  iPhone 6s  4.7寸 => 375x667 => 2
  iPhone 6sPlus  5.5寸 => 414x736 => 3
  iPhone 7  4.7寸 => 375x667 => 2
  iPhone 7Plus  5.5寸 => 414x736 => 3
  iPhone 8  4.7寸 => 375x667 => 2
  iPhone 8Plus5  5.5寸 => 414x736 => 3
  iPhone X  5.8寸 => 375x812 => 3
  iPhone XS  5.8寸 => 375x812 => 3
  iPhone XS Max6.5寸 => 414x896 => 3
  iPhone XR  6.1寸 => 414x896 => 2
  iphone se 1 == iphone 5s
  iphone se 2 == iphone 8
  获取苹果手机、微信内(微信浏览器、微信小程序)、有底部黑色线条的iphone手机，iphone x以上版本
*/
export const getBtmBarIphone = () => {
  const iPhone4_4s =
    window.devicePixelRatio &&
    window.devicePixelRatio === 2 &&
    window.screen.width === 320 &&
    window.screen.height === 480;
  const iPhone5_5s_5c =
    window.devicePixelRatio &&
    window.devicePixelRatio === 2 &&
    window.screen.width === 320 &&
    window.screen.height === 568;
  const iPhone6_6s_7_8 =
    window.devicePixelRatio &&
    window.devicePixelRatio === 2 &&
    window.screen.width === 375 &&
    window.screen.height === 667;
  const iPhone6P_6sP_7P_8P =
    window.devicePixelRatio &&
    window.devicePixelRatio === 3 &&
    window.screen.width === 414 &&
    window.screen.height === 736;
  const isWeChat = versions().isWeChat;
  const isMiniProgram = versions().isMiniProgram;
  const isIphone = versions().iPhone;
  console.log(window.devicePixelRatio, 'devicePixelRatio');
  console.log(window.screen.width, 'screen.width');
  console.log(window.screen.height, 'screen.height');
  console.log(isWeChat, 'isWeChat');
  console.log(isMiniProgram, 'isMiniProgram');
  console.log(isIphone, 'isIphone');
  return (
    isIphone && (isWeChat || isMiniProgram) && (!iPhone4_4s || !iPhone5_5s_5c || !iPhone6_6s_7_8 || !iPhone6P_6sP_7P_8P)
  );
};

// 存储新奥燃气险相关字段
export const storeInsuranceInfo = to => {
  const {
    appid,
    insuranceBusinessNo = '', // 保险业务号
    insuranceProductNo = '', // 保险产品编码
    insuranceCustomerId = '', // 保险客户Id(三方)
    insuranceServiceItemCode = '', // 保险服务项目编码`
    insurancePhone = '' // 保险用户履约手机号
  } = to.query;
  if (to.name == 'shopingMall' && appid) {
    if (
      !(insuranceBusinessNo && insuranceProductNo && insuranceCustomerId && insuranceServiceItemCode && insurancePhone)
    ) {
      localStorage.getItem('insuranceInfo') ? localStorage.removeItem('insuranceInfo') : '';
      return false;
    }
    localStorage.setItem('insuranceInfo', {
      insuranceBusinessNo,
      insuranceProductNo,
      insuranceCustomerId,
      insuranceServiceItemCode,
      insurancePhone
    });
  }
};

// 三方小程序/app在链接上带hideTitle参数过来隐藏h5的头
// 处理地址列表及地址新增/编辑页返回按钮逻辑
export const getThirdHideHeaderQuery = () => {
  const queryHideTitle = localStorage.getItem('hideTitle') || '';
  const hideTitle = (queryHideTitle != 'undefined' && queryHideTitle != 'null' && queryHideTitle) || '';
  return hideTitle;
};

// 格式化数字，>=100展示99+
export const formatNumber = num => {
  if (num > 99) {
    return '99+';
  } else {
    return num.toString();
  }
};
