/**
 * 垃圾代码
 */
import { isInApp } from './index';
import { getCustomIM } from '@/api/index.js';
import { getTransCode } from '@/utils';
const dsBridge = window.dsBridge;
export const bs_backup = () => {
  if (isInApp()) {
    var str = dsBridge.call('backup');
    console.log('synchronously-------backup', str);
  } else {
    console.log('在h5中返回');
    // window.history.go(-1);
    window.history.back();
  }
};
// ios右划手动触发返回
export const bs__registerBackUp = () => {
  dsBridge.register('registerBackUp', dsBridge.call('backup'));
};

// 纯跳转原生
export const bs_callBackNative = () => {
  dsBridge.call('callBackNative');
};

// 退出登录
export const bs_logout = () => {
  console.log('do logout');
  dsBridge.call('logout');
};

export const bs_login = () => {
  console.log('call goLoginPage');
  dsBridge.call('goLoginPage');
};

// 关闭webView
export const closeWebPage = () => {
  console.log('call closeWebPage');
  dsBridge.call('closeWebPage');
};

// 去认证
export const to_Authenticate = () => {
  var str = dsBridge.call('to_Authenticate');
  console.log('to_Authenticate', str);
};

// 更换头像
export const uploadIcon = fn => {
  dsBridge.call('uploadIcon', fn);
};
// 上传图片多张
export const uploadImages = fn => {
  console.log('uploadImages');
  dsBridge.call('uploadImages', fn);
};

// 上传多张照片到私有库
export const uploadPrivateImages = fn => {
  if (!isInApp()) {
    // wx.miniProgram.chooseImage
    import('@/utils/chooseWxImage').then(({ chooseWxImage }) => {
      chooseWxImage(fn);
    });
  }
  dsBridge.call('uploadPrivateImages', fn);
};

// 上传到公有库
export const uploadPublicImages = fn => {
  console.log('uploadPublicImages');
  dsBridge.call('uploadPublicImages', fn);
};

// 提交支付
export const submitPay = (payInfo, fn) => {
  console.log(payInfo, 'call  ds  方法', fn);
  dsBridge.call('submitPay', payInfo, fn);
};

// 拿患者ID
export const bs_getPatientId = () => {
  console.log('test------getPatientId');

  var str = dsBridge.call('getPatientId');
  if (str) {
    sessionStorage.setItem('patientId', str);
  }
  console.log('synchronously-------getPatientId', str);
  return str;
};

export const bs_getPaperUserAnswerId = () => dsBridge.call('getPaperUserAnswerId');

export const bs_getSourceType = () => dsBridge.call('getSourceType');

// 问诊 - 继续咨询
export const bs_goToSession = teamId => {
  console.log('goToSession', teamId);
  dsBridge.call('goToSession', teamId);
};

// 医生 - 聊天
export const doctorChat = imAccid => {
  console.log('doctorChat', imAccid);
  dsBridge.call('doctorChat', imAccid);
};

// 用户跳转-医生群聊问诊
export const bs_doctorGroupChat = (imAccid, physiognosis) => {
  console.log('imAccid', imAccid);
  // 是否是在app
  if (isInApp()) {
    dsBridge.call('doctorGroupChat', imAccid);
  } else {
    getCustomIM().then(res => {
      if (res && res.data && res.data.success) {
        const { data } = res.data;
        const { imAccid: uid, imToken: sdktoken } = data;
        if (physiognosis) {
          const roomUrl = `${process.env.VUE_APP_HOST}/client/diseaseCenterH5/#/chartRoom`;
          window.location.href =
            window.location.hostname === 'localhost'
              ? `http://localhost:8080/#/chartRoom?id=${imAccid}&&uid=${uid}&sdktoken=${sdktoken}&physiognosis=${physiognosis}`
              : `${roomUrl}?id=${imAccid}&&uid=${uid}&sdktoken=${sdktoken}&physiognosis=${physiognosis}`;
        } else {
          window.location.href =
            window.location.hostname === 'localhost'
              ? `http://localhost:8080/#/chartRoom?id=${imAccid}&&uid=${uid}&sdktoken=${sdktoken}`
              : `${process.env.VUE_APP_HOST}/client/diseaseCenterH5/#/chartRoom?id=${imAccid}&&uid=${uid}&sdktoken=${sdktoken}`;
        }
      }
    });
  }
};

// 问诊 - 再次咨询
export const bs_consultAgain = doctorId => {
  console.log('consultAgain');
  dsBridge.call('consultAgain', doctorId);
};

// 拿医生订单ID
export const bs_getOrderId = () => {
  console.log('test------bs_getOrderId');
  var str = dsBridge.call('getOrderId');
  sessionStorage.setItem('orderId', str);
  console.log('synchronously-------getOrderId', str);
};

// 拿医生ID
export const bs_getDoctorId = () => {
  console.log('test------bs_getDoctorId');
  var str = dsBridge.call('getDoctorId');
  console.log('synchronously-------getDoctorId', str);
  return str;
};

// 获取评论接口入参信息
export const getRateInfo = () => {
  var rateInfo = dsBridge.call('getRateInfo');
  console.log('调用getRateInfo方法', rateInfo);
  return rateInfo;
};

// 关闭弹窗
export const closePopupWindow = () => {
  dsBridge.call('closePopupWindow');
  console.log('调用closePopupWindow方法');
};

// 跳转收银台
export const payMethods = () => {
  var payInfo = dsBridge.call('payMethods');
  console.log('payMethods调用了', payInfo);
  return payInfo;
};

// 定位城市
export const bs_getCurrentCity = () => {
  console.log('test------bs_getCurrentCity');
  var str = dsBridge.call('getCurrentCity');
  console.log('synchronously-------getCurrentCity', str);
  return str;
};

// 获取头部
export const bs_getToken = () => {
  var str = dsBridge.call('getRequestHeader');
  console.log('token in bridge', str);
  localStorage.setItem('headers', JSON.stringify(str || {}));
};

export const bs_syncToken = params => {
  dsBridge.call('syncToken', params);
};

// 获取头部
export const getToken = () => {
  console.log('test------getToken');
  var str = dsBridge.call('getToken') || '';
  isInApp() && localStorage.setItem('jk-token', str);
  console.log('synchronously-------getToken', str);
  return str;
};

// ios键盘收回
export const keyboardRecycling = () => {
  console.log('调用键盘回收');
  dsBridge.call('keyboardRecycling');
};

// 频道传值
export const bs_updateCommunityChannel = params => {
  console.log('test------updateCommunityChannel', params);
  var str = dsBridge.call('updateCommunityChannel', params);
  console.log('synchronously-------updateCommunityChannel', str);
};

// 频道拿值
export const bs_getCommunityChannelId = () => {
  console.log('test------getCommunityChannelId');
  var str = dsBridge.call('getCommunityChannelId');
  console.log('synchronously-------getCommunityChannelId', typeof str, str);
  return str;
};

// 拿用户ID
export const bs_getUserId = () => {
  console.log('test------getUserId');
  var str = dsBridge.call('getUserId');
  console.log('synchronously-------getUserId', str);
  return str;
};

// 拿健康号ID
export const bs_getHealthAccountId = () => {
  console.log('test------healthAccountId');
  var str = dsBridge.call('getHealthAccountId');
  console.log('synchronously-------healthAccountId', str);
  return str;
};

// 调用原生播放视频
export const bs_playVideo = url => {
  console.log('test------playVideo');
  dsBridge.call('playVideo', url);
};

// 跳转到个人中心设置
export const bs_urlPersonalSet = () => {
  console.log('test------urlPersonalSet');
  dsBridge.call('urlPersonalSet');
};

// 问诊订单 - 医生服务 - 跳转到添加就诊人
export const bs_goToAddPatient = parmasObj => {
  console.log('call bs_goToAddPatient');
  dsBridge.call('goToAddPatient', parmasObj);
};

// 找医生获取问诊订单带过来的医生服务参数
export const bs_getSelectedService = () => {
  console.log('call bs_getSelectedService');
  return dsBridge.call('getSelectedService');
};

// 跳转到社区个人动态
export const bs_urlPersonalDetail = (params, fixed, isShowCommentAlert, momentsType) => {
  params.fixed = !!fixed;
  params.isShowCommentAlert = !!isShowCommentAlert;
  params.momentsType = momentsType ? 1 : 0;
  console.log('test------urlPersonalDetail', params, fixed, isShowCommentAlert);
  dsBridge.call('urlPersonalDetail', params);
};

// 跳转到社区健康号文章s
export const bs_urlHeathDetail = (params, fixed) => {
  if (!isInApp()) {
    return (window.location.href = `${process.env.VUE_APP_SEARCH_HOST}/client/content/#/articleDetails?id=${params.articleId}`);
  }
  params.fixed = fixed;
  console.log('test------bs_urlHeathDetail', params);
  dsBridge.call('urlHeathDetail', params);
};

// 问诊 - 再次咨询
export const bs_turnToDocPage = doctorId => {
  console.log('consultAgain');
  dsBridge.call('consultAgain', doctorId);
};

// 跳转到社区个人点赞
export const bs_updateCommunityContentStatus = params => {
  console.log('test------bs_updateCommunityContentStatus', params);
  dsBridge.call('updateCommunityContentStatus', params);
};

// 评分拿值
export const getCommentStar = () => {
  var str = dsBridge.call('getCommentStar');
  return str;
};

// 调用原生输入框
export const showKeybroad = params => {
  console.log('test------showKeybroad', params);
  dsBridge.call('showKeybroad', params);
};

// 调用原生视频播放
export const vedioPlay = params => {
  console.log('test------vedioPlay', params);
  dsBridge.call('vedioPlay', params);
};

// 页面跳转
export const getRoutePath = () => {
  return dsBridge.call('getRoutePath');
};

// 拿patientId
export const fetchPatientId = () => {
  return dsBridge.call('getPatientId');
};

/**
 * 跳转到个人用户主页（h5—>原生->h5）
 * params={"userId": userId, "userType": userType}
 * userId:用户id或者健康号id  userType: 1是用户,4是健康号
 * @param {*} params
 */
export const bs_jumpCommunityUserHomePage = params => {
  console.log('call jumpCommunityUserHomePage:%s', JSON.stringify(params));
  return dsBridge.call('jumpCommunityUserHomePage', params);
};

// 监听h5页面进入到原生页面
export const bs_viewWillAppear = call => {
  console.log('call viewWillAppear...');
  dsBridge.register('viewWillAppear', call);
};

// 监听原生页面进入到h5页面
export const bs_viewWillDisappear = call => {
  console.log('call viewWillDisappear...');
  dsBridge.register('viewWillDisappear', call);
};

// 监听安卓的返回
export const navBack = call => {
  console.log('call navBack...');
  call =
    call ||
    (() => {
      dsBridge.call('backup');
    });
  dsBridge.register('navBack', call);
};

// 监听安卓的返回事件 后关闭webview (预约挂号业务需求)
export const navBackByAndroid = () => {
  console.log('===navBackByAndroid===');
  // const callback = () => {
  //   dsBridge.call('closeWebPage');
  // }
  dsBridge.register('navBack', () => dsBridge.call('closeWebPage'));
};
// 键盘收起
export const bs_keyboardWillHidden = call => {
  dsBridge.register('keyboardWillHidden', call);
};

export const bs_jumpToFindDoctorPage = params => {
  console.log('search doctor', params);
  return dsBridge.call('jumpToFindDoctorPage', params);
};

export const bs_getDoctorPageSearchKey = () => {
  console.log('getDoctorPageSearchKey...');
  return dsBridge.call('getDoctorPageSearchKey');
};
/**
 * 编辑药品，调起原生弹窗
 * 1: 添加规格; 2: 添加包装; 3: 服用频次; 4：服用剂量
 * @param {*} type
 */
// - Parameters:
//   - popType: 弹出类型 1: 添加规格; 2: 添加包装; 3: 服用频次
// - Return:
//   - values: 数值
//   - units: 单位
//   @objc func popUpPrescriptionWindow(popType : String) -> [String: Any]{
//     let values = ""//数值
//     let units = ""//单位
//     return ["values" : values, "units" : units]
// }
// export const bs_editSkuInfo = (type) => {
//   return dsBridge.register('popUpPrescriptionWindow', type)
// }

export const takePicture = (type, fn) => {
  console.log('take');
  dsBridge.call('takePicture', type, fn);
};

export const choosePhoto = fn => {
  console.log('choosePhoto');
  dsBridge.call('choosePhoto', fn);
};

// 通知原生用药方案刷新
export const myHealthMedicationChanged = () => {
  console.log('myHealthMedicationChanged');
  dsBridge.call('myHealthMedicationChanged');
};

// 通知原生处方记录刷新
export const myHealthPrescriptionChanged = () => {
  console.log('myHealthPrescriptionChanged');
  dsBridge.call('myHealthPrescriptionChanged');
};

export const popUpPrescriptionWindow = (type, fn) => {
  console.log('popUpPrescriptionWindow', type);
  dsBridge.call('popUpPrescriptionWindow', type, fn);
};
export const bs_editSkuInfo = type => {
  return dsBridge.call('popUpPrescriptionWindow', type);
};

/**
 * 提示APP更新
 */
export const noticeApp = () => {
  return dsBridge.call('myHealthMedicationChanged');
};

/**
 * 获取原生跳转H5时带过来的疾病code
 */
export const fetchDisease = () => {
  return dsBridge.call('fetchDisease');
};

/**
 * 获取原生跳转H5时带过来的疾病名称
 */
export const fetchDiseaseName = () => {
  return dsBridge.call('fetchDiseaseName');
};

export const getScanningData = patientId => {
  console.log('getScanningData');
  return dsBridge.call('getScanningData', patientId);
};

// 获取医生名称
export const getPartnerName = () => {
  console.log('test------getPartnerName');

  var str = dsBridge.call('getPartnerName');
  if (str) {
    sessionStorage.setItem('partnerName', str);
    console.log('synchronously-------getPartnerName', str);
    return str;
  }
};

// 获取病人名称
export const getPatientName = () => {
  console.log('test------getPatientName');
  var str = dsBridge.call('getPatientName');
  if (str) {
    sessionStorage.setItem('patientName', str);
  }
  console.log('synchronously-------getPatientName', str);
  return str;
};

// 获取用户id
export const getCustomerId = () => {
  console.log('test------getCustomerId');
  var str = dsBridge.call('getCustomerId');
  sessionStorage.setItem('customerId', str);
  console.log('synchronously-------getCustomerId', str);
  return str;
};

// 获取用户姓名
export const getCustomerName = () => {
  console.log('test------getCustomerName');
  var str = dsBridge.call('getCustomerName');
  sessionStorage.setItem('getCustomerName', str);
  console.log('synchronously-------getCustomerName', str);
  return str;
};

// 获取医生id
export const getPartnerId = () => {
  console.log('test------getPartnerId');
  var str = dsBridge.call('getPartnerId');
  if (str) {
    sessionStorage.setItem('partnerId', str);
    console.log('synchronously-------getPartnerId', str);
    return str;
  }
};

// 获取发送getSendItemId
export const getSendItemId = () => {
  console.log('test------getSendItemId');
  var str = dsBridge.call('getSendItemId');
  sessionStorage.setItem('sendItemId', str);
  console.log('synchronously-------getSendItemId', str);
  return str;
};

export const bs_getPaperId = () => {
  return dsBridge.call('getPaperId');
};

/**
 * type: 1: 处方记录；2: 用药方案
 * methods: 1: 手动上传；2: 扫描上传
 */
export const getUploadType = () => {
  return dsBridge.call('getUploadType');
};

/**
 * 跳转处方记录
 * @param {*} type 就诊人ID
 */
export const jumpToPrescription = type => {
  console.log('调用jumpToPrescription');
  dsBridge.call('jumpToPrescription', type);
};

export const bs_getPatientAge = () => {
  console.log('test------getPatientAge');
  var str = dsBridge.call('getPatientAge');
  console.log('getPatientAge', str);
  if (str) {
    sessionStorage.setItem('patientAge', str);
    return str;
  }
};

export const bs_getPatientSex = () => {
  console.log('test------getPatientSex');
  var str = dsBridge.call('getPatientSex');
  console.log('getPatientSex', str);
  if (str) {
    sessionStorage.setItem('patientSex', str);
    return str;
  }
};

export const bs_getFupSourceType = () => {
  console.log('test------getFupSourceType');
  var str = dsBridge.call('getFupSourceType');
  console.log('getFupSourceType', str);
  if (str) {
    sessionStorage.setItem('fupSourceType', str);
    return str;
  }
};

export const bs_goToChooseContacts = params => {
  console.log('bs_goToChooseContacts', params);
  return dsBridge.call('goToChooseContacts', params);
};

export const bs_getTemplateId = () => {
  console.log('test------bs_getTemplateId');
  var str = dsBridge.call('getTemplateId');
  console.log('getTemplateId', str);
  if (str) {
    sessionStorage.setItem('templateId', str);
    return str;
  }
};
// 获取sessionId
export const getSessionId = () => {
  var str = dsBridge.call('getSessionId');
  console.log('getSessionId', str);
  sessionStorage.setItem('sessionId', str);
  return str;
};
// 获取sessionStatus
export const getSessionStatus = () => {
  var str = dsBridge.call('getSessionStatus');
  console.log('getSessionStatus', str);
  sessionStorage.setItem('sessionStatus', str);
  return str;
};

// 跳转到健康指标列表页
export const JumpToHealthList = id => {
  console.log('call JumpToHealthList', String(id));
  dsBridge.call('JumpToHealthList', String(id));
};

// 通知检验报告刷新
export const myHealthReportChanged = () => {
  console.log('call myHealthReportChanged');
  dsBridge.call('myHealthReportChanged');
};
// 记录跟踪健康打卡
export const bs_recordIndicators = (params, fn) => {
  return dsBridge.call('recordIndicators', params, fn);
};

// 获取检验检查报告详情Id
export const getReportDetailId = () => {
  return dsBridge.call('getReportDetailId');
};

/**
 * 用药提醒弹窗
 * @param {*} type // type：1、 药品余量，2、服用时间及剂量
 * @param {*} fn
 * 回调方法返回 { value1: '', value2: '' }
 */
export const popUpMedicalWindow = (type, fn) => {
  console.log('popUpMedicalWindow', type);
  dsBridge.call('popUpMedicalWindow', type, fn);
};
export const popUpMedicalAlert = fn => {
  return dsBridge.call('popUpMedicalAlert', fn);
};

// 获取卡片id(医生查看随访计划)
export const getCardId = () => {
  console.log('test------getCardId');
  var str = dsBridge.call('getCardId');
  if (str) {
    sessionStorage.setItem('cardId', str);
    console.log('synchronously-------getCardId', str);
    return str;
  }
};
// 新增症状打卡传参===========
export const addSymptomClockData = (params, fn) => {
  console.log('新增症状打卡传参', params);
  console.log('新增症状打卡fn', fn);
  dsBridge.call('addSymptomClockData', params, fn);
};
// 查看症状打卡传参
// 新增治疗评估传参
export const addTreatEvaluationData = (params, fn) => {
  console.log('新增诊疗评估传参', params);
  console.log('新增诊疗评估fn', fn);
  dsBridge.call('addTreatEvaluationData', params, fn);
};
// 查看治疗评估传参
export const goToTreatEvaluationDetail = (params, fn) => {
  console.log('查看随访详情传参', params);
  console.log('查看随访详情fn', fn);
  dsBridge.call('goToTreatEvaluationDetail', params, fn);
};
// 新建随访计划-新增症状打卡传参

// 新建随访计划-治疗评估打卡的传参

// 新建随访计划-健康跟踪的传参
// 从用户主页跳转到随访计划详情页
export const bs_gotoUserPlanDetail = params => {
  console.log('ready to enter follow-up plan detail page....', params);
  return dsBridge.call('gotoUserPlanDetail', params);
};

// addTrackData 从随访详情 健康跟踪 跳转到 新增页面
// 交互废弃改跳h5自己页面
// export const bs_addTrackData = (params, fn) => {
//     console.log("从随访详情 健康跟踪 跳转到 新增页面....", params);
//     dsBridge.call("addTrackData", params, fn);
// };
// 废弃 改跳h5页面
// export const bs_goToTrackItemDetail = (params, fn) => {
//     console.log("从随访详情 健康跟踪 跳转到 详情页面....", params);
//     dsBridge.call("goToTrackItemDetail", params, fn);
// };

export const bs_getSummary = () => {
  const str = dsBridge.call('getSummary');
  if (str) {
    sessionStorage.setItem('getSummary', str);
    console.log('synchronously-------getSummary', str);
    return str;
  }
};

/**
 * 社区-获取投诉页面相关参数
 * targetId targetType complainType
 */
export const bs_getComplaintParams = () => {
  return dsBridge.call('getComplainParam');
};

/**
 * 社区-擅长领域跳转问题中心
 */
export const bs_goToProblemCenter = params => {
  console.log('bs_goToProblemCenter擅长领域跳转问题中心', params);
  return dsBridge.call('goToProblemCenter', params);
};

/**
 * 跳转到内容详情
 * @param {*} params
 * topic:话题
 * question:问题
 * answer:回答
 * params={
 * type:'topic'||'question'||'answer'
 * id:id
 * }
 */
export const bs_gotoContentDetail = (params, fixed) => {
  params.fixed = fixed;
  console.log('gotoContentDetail', JSON.stringify(params));
  return dsBridge.call('gotoContentDetail', params);
};

/**
 * 写回答
 * @param {*} params
 * {
 *    id: '', // 问题ID
 *    title: '', // 问题名
 * }
 */
export const bs_goToPublishAnswer = params => {
  console.log('goToPublishAnswer', JSON.stringify(params));
  return dsBridge.call('goToPublishAnswer', params);
};

// 个人主页 分享 投诉 拉黑 弹窗
export const popMenuFromUserPage = (params, fn) => {
  console.log(params, 'popMenuFromUserPage');
  dsBridge.call('popMenuFromUserPage', params, fn);
};

// 获取推荐 跳转类型 3为用户 4为健康号
export const getRecommendType = () => {
  console.log('getRecommendType');
  return dsBridge.call('getRecommendType');
};

/**
 * 上传图片公共方法
 * commonUploadImages
 * @param {*} fn h5回调方法，原生返回数组
 * @param {*} params 参数
 * eg:
 *    public: http://ddjk-community-public.oss-cn-hangzhou.aliyuncs.com/android_client_1607672222556.jpg
 *    private: http://ddjk-resource-protected.oss-cn-hangzhou.aliyuncs.com/ios_ddjk_client_1_1607672459438267.jpg
 *  params: {
      module: 'public/private',  // 公私有库
      bucket: 'community', // 桶名: resource(默认)，community
      multiOrSingle: 'multiple/single',  // 多张或单张
      imageMaxNum: 1, // 图片数量 single的是时候 默认1张  mutiple的时候自定义一次可选择的最大图片数
    }
 */
export const commonUploadImages = (fn, params) => {
  console.log('commonUploadImages-上传公共方法参数', params);
  const temp = {
    bucket: 'resource',
    ...params
  };
  dsBridge.call('commonUploadImages', temp, fn);
};

export const globalUploadImages = (fn, params) => {
  console.log('globalUploadImages-上传公共方法参数', params);
  const temp = {
    bucket: 'resource',
    ...params
  };
  dsBridge.call('globalUploadImages', temp, fn);
};

export const transmitContent = item => {
  console.log('transmitContent-转发公共方法参数', item);
  dsBridge.call('transmitContent', item);
};

export const refreshAttentionState = () => {
  console.log('refreshAttentionState-调用');
  dsBridge.call('refreshAttentionState');
};
// 跳转到商城
export const goSearchResult = params => {
  console.log('goSearchResult-商城', params);
  return dsBridge.call('goSearchResult', params);
};

/**
 * 兼容ios关闭弹窗问题
 * @param {*} params
 *
    const params = {
      animated: 0, // 0没有动画
    };
 */
export const bs_closePopWindow = params => {
  console.log('closePopWindow', params);
  if (params) {
    return dsBridge.call('closePopWindow', params);
  }
  return dsBridge.call('closePopWindow');
};

// 获取定位权限
export const detectionLocationService = () => {
  const detectionLocationService = dsBridge.call('detectionLocationService');
  console.log('detectionLocationService', detectionLocationService);
  return detectionLocationService;
};

// 获取定位
export const getLocationAddress = async () => {
  let cityInfo = dsBridge.call('getLocationAddress');
  // 转城市code 3位转12位 areaCode 地区编码
  const { cityCode = '' } = cityInfo || {};
  const areaCode = cityCode == '' ? '' : await getTransCode(cityCode);
  cityInfo = {
    ...cityInfo,
    areaCode
  };
  console.log('getLocationAddress', cityInfo);
  if (cityInfo) {
    sessionStorage.setItem('locationAddressObj', JSON.stringify(cityInfo));
  }
  return cityInfo;
};
// 预约挂号获取定位
export const getLocationAddressRegistion = () => {
  console.log('开始', new Date().getSeconds());
  const str = dsBridge.call('getLocationAddress');
  console.log('getLocationAddress', str);
  if (str) {
    sessionStorage.setItem('locationAddressObj', JSON.stringify(str));
  }
  console.log('结束', new Date().getSeconds());
  return str;
};
export const turnOnPositioning = () => {
  console.log('turnOnPositioning');
  return dsBridge.call('turnOnPositioning');
};

export const bs_doctorServiceChoosed = params => {
  console.log('doctorServiceChoosed');
  return dsBridge.call('doctorServiceChoosed', params);
};

/**
 * 注意：手机号必须去掉空格 ios手机识别不了带空格的手机号。
 * @param {*} params
 * @returns
 */
export const callPhone = params => {
  console.log('callPhone', params);
  return dsBridge.call('callPhone', params);
};
// 获取网络状态
export const getNetworkStatus = () => {
  const str = dsBridge.call('getNetworkStatus');
  console.log('getNetworkStatus', str);
  return str;
};

// 安卓信号栏高度
export const getAndroidStatusBarHeight = () => {
  return dsBridge.call('getAndroidStatusBarHeight');
};
// 分享
export const share = params => {
  console.log('share', params);
  return dsBridge.call('share', params);
};
// 私信
export const personalLetter = id => {
  console.log('personalLetter');
  return dsBridge.call('personalLetter', id);
};
// 病历本详情
export const jumpToMedicalRecords = params => {
  console.log('jumpToMedicalRecords');
  return dsBridge.call('jumpToMedicalRecords', params);
};
// 回复的回复弹窗
export const goCommentDetailVC = params => {
  console.log('goCommentDetailVC');
  return dsBridge.call('goCommentDetailVC', params);
};

// 心情弹窗
export const callMoodPopup = (params, fn) => {
  console.log('callMoodPopup', params);
  dsBridge.call('callMoodPopup', params, fn);
};

// 症状弹窗
export const callSymptomPage = (params, fn) => {
  console.log('callSymptomPage', params);
  dsBridge.call('callSymptomPage', params, fn);
};

/**
 * 设置webView里的patientId
 * @param {*} id int类型，没有或不是int类型默认传0
 */
export const setPatientId = id => {
  console.log('setPatientId', id, typeof id);
  const patientId = Number(id);
  console.log('setPatientI-patientId', patientId, typeof patientId);
  dsBridge.call('setPatientId', patientId);
};

export const updateAllFollowStatus = params => {
  console.log('updateAllFollowStatus', params);
  dsBridge.call('updateAllFollowStatus', params);
};
/**
 *  getUserInf 获取登录用户信息
 *  期望返回：
 *  {
 *    userName:'',
 *    userId:'
 *  }
 */
export const getUserInf = () => {
  console.log('getUserInf');
  return dsBridge.call('getUserInf');
};

/**
 * 停用报告弹窗
 * @param {*} params
 * params: {
 *   brandName: '', // 品牌名
 *   genericName: '', // 通用名
 *   id: 0, // 用药方案ID
 *   skuId: '', // 药品ID
 *   patientId: '', // 就诊人ID
 *   diseaseCode: '', // 疾病CODE
 *   diseaseName: '', // 疾病名称
 *   dosageRegimenId
 *   medicineId
 *   skuId
 *   genericName
 *   specification
 *   剂量的
 *   startTime
 *   usageDose
 *   usageDoseUnit
 *   usageFrequency
 *   usageTimes
 * }
 */
export const stopReportPop = (params, fn) => {
  console.log('stopReportPop', params);
  return dsBridge.call('stopReportPop', params, fn);
};

/**
 * 跳转商城结算并将参数带过去
 * @param {*} params
 */
export const goToMallSettlePage = params => {
  console.log('goToMallSettlePage', params);
  return dsBridge.call('goToMallSettlePage', params);
};
export const previewImg = params => {
  console.log('previewImg', params);
  return dsBridge.call('previewImg', params);
};
// 预约挂号get set
export const getIgnoreStatus = () => {
  const str = dsBridge.call('getIgnoreStatus');
  console.log('getIgnoreStatus', str);
  return str;
};

export const setIgnoreStatus = params => {
  console.log('setIgnoreStatus', params);
  return dsBridge.call('setIgnoreStatus', params);
};

export const getDoctorTeamId = () => {
  console.log('test------getDoctorTeamId');
  var str = dsBridge.call('getDoctorTeamId');
  console.log('getDoctorTeamId', str);
  if (str) {
    sessionStorage.setItem('doctorTeamId', str);
    return str;
  }
};

export const getReceiverType = () => {
  console.log('test------getReceiverType');
  var str = dsBridge.call('getReceiverType');
  console.log('getReceiverType', str);
  if (str) {
    sessionStorage.setItem('receiverType', str);
    return str;
  }
};

// 跳转团队资料
export const jumpToTeamDetail = params => {
  console.log('jumpToTeamDetail', params);
  return dsBridge.call('jumpToTeamDetail', params);
};

// 跳转团队成员
export const jumpToTeamMember = params => {
  console.log('jumpToTeamMember', params);
  return dsBridge.call('jumpToTeamMember', params);
};
// ios 设置页面滑动 传字符串   "0"-不能滑动   "1" - 可以滑动
export const setWebViewCanScroll = params => {
  console.log('setWebViewCanScroll', params);
  return dsBridge.call('setWebViewCanScroll', params);
};
// 隐藏滚动条
export const showScrollBar = params => {
  console.log('showScrollBar', params);
  return dsBridge.call('showScrollBar', params);
};

/**
 * 监听视频播放数量的变化
 * {videoId:0}
 */

export const bs_increaseVideoPlayTimes = call => {
  console.log('call increaseVideoPlayTimes...');
  dsBridge.register('increaseVideoPlayTimes', call);
};

/**
 * 改变webView背景
 * @param { backgroundColor: '[UIColor colorWithRed:244/255.0 green:245/255.0 blue:247/255.0 alpha:1.0].CGColor'}
 * 改变webView背景
 * @param { backgroundColor }
 */
export const bs_changeWebViewBgColor = backgroundColor => {
  console.log('showScrollBar', backgroundColor);
  return dsBridge.call('changeWebViewBgColor', { backgroundColor: backgroundColor });
};

/**
 * 分享拼团页面
 * @param {*} params
 * @returns
 * imgUrl  图片链接
 * webUrl 分享链接
 * title  标题
 * content 内容   需要这些字段   格式按照你们h5桥接的格式来就行
 */
export const shareGroupBuy = options => {
  console.log('shareGroupBuy', options);
  const params = {
    imgUrl: '', // 图片链接
    webUrl: '', // 分享链接
    title: '', // 标题
    content: '', // 内容   需要这些字段   格式按照你们h5桥接的格式来就行
    ...options
  };
  return dsBridge.call('shareGroupBuy', params);
};

/**
 * 跳转商品详情
 * @param {*} params
 */
export const bs_goToProductDetail = params => {
  // {skuId: channelSkuId}
  dsBridge.call('goProductDeails', params);
};

/**
 * 图文问诊 病情描述页跳转到原生的订单页
 * @param {*} params
 */
export const bs_gotoSubmitOrder = params => {
  console.log('gotoSubmitOrder', params);
  dsBridge.call('gotoSubmitOrder', params);
};

/** *
 * 春雨医生 快速问诊 跳转订单页
 */
export const bs_goToProductDetailByCY = params => {
  // {skuId: channelSkuId}
  dsBridge.call('gotoConsultationOrder', params);
};

/**
 * 跳转健康跟踪添加页面
 * @param {*} params
 */
// 交互废弃 改调h5页面 update-1119 zhangfan
// export const bs_addHealthRecord = (params, fn) => {
//   console.log('bs_addHealthRecord', params);

//   dsBridge.call('addHealthRecord', params, fn);
// };
/**
 * 跳转健康跟踪详情页面
 * @param {*} params
 */
// 交互废弃 改跳h5页面
// export const bs_healthRecordDetail = (params, fn) => {
//   console.log('bs_healthRecordDetail', params);

//   dsBridge.call('healthRecordDetail', params, fn);
// };

/**
 * 跳转自疗评估页面
 * @param {*} params
 */
export const bs_goToEvaluationPage = (params, fn) => {
  console.log('bs_goToEvaluationPage', params);

  dsBridge.call('goToEvaluationPage', params, fn);
};

/**
 * 自疗评估详情页
 * @param {*} params
 */
export const bs_goToEvaluationDetail = (params, fn) => {
  console.log('bs_goToEvaluationDetail', params);
  dsBridge.call('goToEvaluationDetail', params, fn);
};
/**
 * 用户端症状详情
 *
 */
export const bs_symptomDetail = (params, fn) => {
  console.log('bs_symptomDetail', params);
  dsBridge.call('symptomDetail', params, fn);
};
/**
 * 症状打卡 goToSymptomRecord
 */
export const goToSymptomRecord = (params, fn) => {
  console.log('goToSymptomRecord', params);
  dsBridge.call('goToSymptomRecord', params, fn);
};

/**
 * 问诊订单，去支付
 * @param {*} params
 */
export const bs_goPaymentPage = params => {
  console.log('goPaymentPage', params);
  dsBridge.call('goPaymentPage', params);
};

// 扫一扫
export const getScanGoods = fn => {
  console.log('getScanGoods');
  dsBridge.call('getScanGoods', fn);
};

/**
 * 跳转APP商品详情
 * {skuId: ''}
 */
export const bs_goProductDeails = params => {
  console.log('bs_goProductDeails', params);
  return dsBridge.call('goProductDeails', params);
};
/**
 * 跳转APP商品详情
 * { afterSalesId: '' }
 */
export const bs_scanAfterSales = params => {
  console.log('bs_scanAfterSales', params);
  return dsBridge.call('scanAfterSales', params);
};

// 预约挂号返回首页
export const bs_orderBack = params => {
  console.log('test------orderBack', params);
  dsBridge.call('orderBack', params);
};

/**
 * 个人优惠券主页跳转商品o2o列表
 * @param {*} params
 */
export const bs_toCouponGoods = params => {
  console.log('toCouponGoods', params);
  dsBridge.call('toCouponGoods', params);
};

export const showDoctorSuggest = (params, fn) => {
  console.log('showDoctorSuggest', params);
  dsBridge.call('showDoctorSuggest', params, fn);
};

/**
 * 添加新症状
 * @param {*} params
 * @param {*} fn
 */
export const bs_addSymptoms = (params, fn) => {
  console.log('bs_addSymptoms', params);
  dsBridge.call('addSymptoms', params, fn);
};

// 用于多模是否隐藏头部
export const bs_isIMWebVCFlag = () => {
  const flag = dsBridge.call('isIMWebVCFlag');
  console.log('isIMWebVCFlag', flag);
  return flag;
};

/**
 * webview添加原生的头
 * @param {*} params
 */
export const updateHeadViewStyle = params => {
  dsBridge.call('updateHeadViewStyle', params);
};

// 获取url
export const bs_getRedirectUri = () => {
  const url = dsBridge.call('getRedirectUri');
  console.log('getRedirectUri', url);
  return url;
};
// 跳转商城首页
// 跳转商城首页方法1
export const goToMall = () => {
  const str = dsBridge.call('goToMall');
  console.log('goToMall', str);
  return str;
};
// 跳转商城首页方法2
export const goOrdinaryIndex = () => {
  const str = dsBridge.call('goOrdinaryIndex');
  console.log('goOrdinaryIndex', str);
  return str;
};
/**
 * 春雨医生 快速问诊
 * 消息盒子跳转到IM会话页面
 * @param {*} params 群聊id
 */
export const goToFastSession = params => {
  console.log('goToFastSession', params);
  dsBridge.call('goToFastSession', params);
};
/**
 * 春雨医生 快速问诊
 * 消息盒子跳转到订单详情页面
 * @param {*} params 订单id
 */
export const gotoInquiryOrderDetailVC = params => {
  console.log('gotoInquiryOrderDetailVC', params);
  dsBridge.call('gotoInquiryOrderDetailVC', params);
};
// 跳转首页医生第一个团队疾病中页签
export const bs_goToDoctorTeamTab = () => {
  console.log('bs_goToDoctorTeamTab');
  dsBridge.call('goToDoctorTeamTab');
};
// 选择就诊人后回调
export const bs_choosePatient = patientInfo => {
  console.log('bs_goToDoctorTeamTab');
  dsBridge.call('choosePatient', patientInfo);
};

// 选择就诊人后回调
export const bs_hideMask = () => {
  console.log('hideMask');
  dsBridge.call('hideMask');
};

window.bs_hideMask = bs_hideMask;
// 二次诊疗传递就诊人信息值
export const bs_CallBackPatient = params => {
  console.log('CallBackPatient', params);
  dsBridge.call('CallBackPatient', params);
};
// 二次诊疗传递疾病的值
export const bs_CallBackVisitResults = params => {
  console.log('CallBackVisitResults', params);
  dsBridge.call('CallBackVisitResults', params);
};
// 二次诊疗去咨询跳转到原生页面
export const bs_STGoTOCommitOrder = params => {
  console.log('STGoTOCommitOrder', params);
  dsBridge.call('STGoTOCommitOrder', params);
};
/**
 * 二次诊疗
 * 消息盒子跳转到诊疗意见页面
 * @param {*} params 订单id
 */
export const goSecondaryTreatmentOpinion = params => {
  console.log('goSecondaryTreatmentOpinion', params);
  dsBridge.call('goSecondaryTreatmentOpinion', params);
};
/**
 * 二次诊疗
 * 消息盒子跳转到完善资料页面
 * @param {*} params 订单id
 */
export const goCompleteInformation = params => {
  console.log('goCompleteInformation', params);
  dsBridge.call('goCompleteInformation', params);
};
/**
 * 二次诊疗
 * 消息盒子跳转到上传资料页面
 * @param {*} params 订单id
 */
export const goUploadInformation = params => {
  console.log('goUploadInformation', params);
  dsBridge.call('goUploadInformation', params);
};
/**
 * 二次诊疗
 * 消息盒子跳转到二次诊疗订单详情页面
 * @param {*} params 订单id
 */
export const goSecondaryTreatmentOrderDetail = params => {
  console.log('goSecondaryTreatmentOrderDetail', params);
  dsBridge.call('goSecondaryTreatmentOrderDetail', params);
};
// 严选医生传递跳转链接到原生（仅安卓）
export const bs_gotoWeb = params => {
  console.log('bs_gotoWeb', params);
  dsBridge.call('gotoWeb', params);
};
/**
 * 更新血糖信息
 * @param {*} params
 */
export const bs_updateBloodSugarData = params => {
  console.log('bs_updateBloodSugarData', params);
  dsBridge.call('updateBloodSugarData', params);
};
/**
 * 社区消息
 * 跳转到发动态
 */
export const empty2sendDynamic = () => {
  console.log('empty2sendDynamic');
  dsBridge.call('empty2sendDynamic');
};

export const getTeamDiseaseCenterId = () => {
  var str = dsBridge.call('getTeamDiseaseCenterId');
  console.log('getTeamDiseaseCenterId', str);
  if (str) {
    sessionStorage.setItem('teamDiseaseCenterId', str);
    return str;
  }
};
/**
 * 新增实验室指标成功交互
 * @param {*} msg
 */
export const bs_trackRecordAdd = msg => {
  console.log(msg);
  dsBridge.call('trackRecordAdd', msg);
};
/**
 * 修改原生弹框背景色
 * @param {*} params
 */
export const bs_updateWebVCBackgroundColor = params => {
  console.log('updateWebVCBackgroundColor', params);
  dsBridge.call('updateWebVCBackgroundColor', params);
};
/**
 * 社区消息
 * 跳转到写回答
 */
export const goToWriteAnswer = () => {
  console.log('goToWriteAnswer');
  dsBridge.call('goToWriteAnswer');
};

// 收起键盘事件（仅安卓）
export const bs_hideKeyboard = () => {
  console.log('hideKeyboard');
  return dsBridge.call('hideKeyboard');
};
/**
 * IOS中 H5返回上一页更新页面
 */
export const iosUpdateView = () => {
  console.log('updateView');
  dsBridge.call('updateView');
};
/**
 * 跳转到快速问诊首页
 */
export const goQuickConsultation = () => {
  console.log('goQuickConsultation');
  dsBridge.call('goQuickConsultation');
};

// 修改地址
export const goOrderUpdateAddress = (params, fn) => {
  console.log('goOrderSelectAddress', params);
  dsBridge.call('goOrderSelectAddress', params, fn);
};
/**
 * 将选中的擅长疾病传给原生
 */
export const setHealthAccountRegisterSelectedDiseases = params => {
  console.log('setHealthAccountRegisterSelectedDiseases', params);
  dsBridge.call('setHealthAccountRegisterSelectedDiseases', params);
};
/**
 * 获取擅长疾病
 */
export const bs_getHealthAccountRegisterSelectedDiseases = () => {
  const selectDiseasesData = dsBridge.call('getHealthAccountRegisterSelectedDiseases');
  console.log('getHealthAccountRegisterSelectedDiseases', selectDiseasesData);
  return selectDiseasesData;
};
// 二次诊疗订单跳转
export const bs_goSecondTreatmentOrderList = () => {
  console.log('goSecondTreatmentOrderList');
  dsBridge.call('goSecondTreatmentOrderList');
};
/**
 * 返回到app首页
 */
export const bs_backToIndex = () => {
  console.log('bs_backToIndex');
  dsBridge.call('backToIndex');
};
// 疾病中心 0元单的跳转
export const bs_goPaymentPageForTeam = params => {
  console.log('goPaymentPageForTeam', params);
  dsBridge.call('goPaymentPageForTeam', params);
};
// 二次诊疗保存多选疾病
export const bs_saveMultiSelectDisease = params => {
  console.log('saveMultiSelectDisease', params);
  dsBridge.call('saveMultiSelectDisease', params);
};
// 二次诊疗获取多选疾病
export const bs_getMultiSelectDisease = () => {
  const diseaseNameArr = dsBridge.call('getMultiSelectDisease');
  console.log('getMultiSelectDisease', diseaseNameArr);
  return diseaseNameArr;
};

// 问诊购药
export const goIMAgain = params => {
  console.log('goIMAgain', params);
  dsBridge.call('goIMAgain', params);
};

// 订单页调起APP合力客服
export const bs_orderDetailGotoCustomerService = params => {
  console.log('test------orderDetailGotoCustomerService', params);
  dsBridge.call('orderDetailGotoCustomerService', params);
};

// 获取用户信息
export const bs_getAccountInfo = () => {
  const str = dsBridge.call('getCurrentAccountInfo');
  console.log('getCurrentAccountInfo', str);
  return str;
};

// 投诉商家跳转
export const bs_gotoComplaintMerchantsPage = params => {
  console.log('gotoComplaintMerchantsPage', params);
  dsBridge.call('gotoComplaintMerchantsPage', params);
};

export const bs_allergyHistoryPageCallBack = () => {
  console.log('allergyHistoryPageCallBack');
  dsBridge.call('allergyHistoryPageCallBack');
};

// 就诊人新增编辑后传patientId参数app
export const addPatientCallBack = patientId => {
  console.log('test------addPatientCallBack', patientId);
  dsBridge.call('addPatientCallBack', patientId);
};

// 获取userId
export const getUid = () => {
  var str = dsBridge.call('getUid') || '';
  console.log('userId in bridge', str);
  return str;
};
