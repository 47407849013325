export default [
  {
    path: '/prescriptionInfoRegister',
    name: 'prescriptionInfoRegister',
    component: () => import('@mall/views/prescriptionInfo/register.vue'),
    meta: { title: '问诊购药' }
  },
  {
    path: '/prescriptionSelectiveUser',
    name: 'prescriptionSelectiveUser',
    component: () => import('@mall/views/prescriptionInfo/selectiveUser.vue'),
    meta: { title: '选择用药人' }
  },
  {
    path: '/prescriptionAddUser',
    name: 'prescriptionAddUser',
    component: () => import('@mall/views/prescriptionInfo/addUser.vue'),
    meta: { title: '添加用药人信息' }
  },
  {
    path: '/prescriptionReview',
    name: 'prescriptionReview',
    component: () => import('@mall/views/prescriptionInfo/reviewMsg.vue'),
    meta: { title: '用药人信息' }
  },
  {
    path: '/scanPrescription',
    name: 'scanPrescription',
    component: () => import('@mall/views/order/scanPrescriptionInfo.vue')
  }
];
