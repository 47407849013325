export default [
  {
    path: '/address/list',
    name: 'addressList',
    component: () => import('@mall/views/address/list.vue'),
    meta: { title: '选择地址' }
  },
  {
    path: '/address/:addressId',
    name: 'editAddress',
    component: () => import('@mall/views/address/_addressId.vue'),
    meta: { title: '编辑收货地址' }
  }
];
