import * as types from './types';

export default {
  namespaced: true,
  state: {
    addSuccessFlag:false,
    diseaseInitStr: '',
    patientId: '',
    checkPatient: {},
    checkPatientId:'',
    patientDiseasesList: [],
    patientDrugDiseaseList: [],
    patientSelfDiseaseTagList: [],
    patientDrugList:[],
    checkMerchantId:"",
    checkGoodStoreId:"",
    baseInfo: {},
    bodyInfo: {},
    lifeStyle: {},
    medicalHistory: {},
    defaultSelectList: [],
    currentDiseases: {
      flag: false,
      chooseList: [],
      deleteIds: [],
      addList: []
    },
    heredityDiseases: {
      flag: false,
      chooseList: [],
      deleteIds: [],
      addList: []
    },
    allergyResp: {
      flag: false,
      chooseList: [],
      drugAllergysDeleteIds: [],
      environmentAllergysDeleteIds: [],
      foodAllergysDeleteIds: [],
      addList: []
    },
    disabilityDiseases: {
      flag: false,
      chooseList: [],
      deleteIds: [],
      addList: []
    },
    traumas: {
      flag: false,
      chooseList: [],
      deleteIds: [],
      addList: []
    },
    surgerys: {
      flag: false,
      chooseList: [],
      deleteIds: [],
      addList: []
    },
    transfuses: {
      flag: false,
      chooseList: [],
      deleteIds: [],
      addList: []
    },
    bloodtypeList: [
      { label: 'A型', id: 1 },
      { label: 'B型', id: 2 },
      { label: 'AB型', id: 3 },
      { label: 'O型', id: 4 },
      { label: '不详', id: 5 },
    ],
    hrBloodList: [
      { label: '阴性', id: 1 },
      { label: '阳性', id: 2 },
      { label: '不详', id: 3 },
    ],
    marriageList: [
      { label: '未婚', id: 0 },
      { label: '已婚', id: 1 },
      { label: '其他', id: 2 },
    ],
    gestationList: [
      { label: '无', id: 1 }, { label: '有', id: 2 },
    ],
    allergyInfoList: []
  },
  actions: {
    refreshResetData({ commit }){
      const baseInfoData = localStorage.getItem('baseInfoData')
      baseInfoData && commit(types.SAVE_BASE_INFO,JSON.parse(baseInfoData))
      const bodyInfoData = localStorage.getItem('bodyInfoData')
      bodyInfoData && commit(types.SAVE_BODY_INFO,JSON.parse(bodyInfoData))
      const lifeInfoData = localStorage.getItem('lifeInfoData')
      lifeInfoData && commit(types.SAVE_LIFE_INFO,JSON.parse(lifeInfoData))
      const historyData = localStorage.getItem('historyData')
      historyData && commit(types.SAVE_MEDICAL_HISTORY,JSON.parse(historyData))
      const patientId = localStorage.getItem('patientId')
      patientId && commit(types.SAVE_PATIENT_ID,patientId)
      const defaultSelectList = localStorage.getItem('defaultSelectList')
      defaultSelectList && commit(types.SAVE_DEFAULT_SELECT_LIST,JSON.parse(defaultSelectList))

    },
  },
  mutations: {
    [types.UPDATE_ADD_SUCCESS_FLAG](state,flag){
      state.addSuccessFlag = flag
    },
    [types.SAVE_PATIENT_ID](state, patientId) {
      localStorage.setItem('patientId',patientId)
      state.patientId = patientId
    },
    [types.UPDATE_PARAMS_DATA](state, { sourceType = 1, chooseList = [] }) {
      switch (sourceType) {
        case 1:
          state.currentDiseases.flag = chooseList && chooseList.length ? true : false;
          state.currentDiseases.chooseList = chooseList;
          break;
        case 2:
          state.heredityDiseases.flag = chooseList && chooseList.length ? true : false;
          state.heredityDiseases.chooseList = chooseList;
          break;
        case 3:
          state.allergyResp.flag = chooseList && chooseList.length ? true : false;
          state.allergyResp.chooseList = chooseList;
          break;
        case 4:
          state.disabilityDiseases.flag = chooseList && chooseList.length ? true : false;
          state.disabilityDiseases.chooseList = chooseList;
          break;
        case 5:
          state.traumas.flag = chooseList && chooseList.length ? true : false;
          state.traumas.chooseList = chooseList;
          break;
        case 6:
          state.surgerys.flag = chooseList && chooseList.length ? true : false;
          state.surgerys.chooseList = chooseList;
          break;
        case 7:
          state.transfuses.flag = chooseList && chooseList.length ? true : false;
          state.transfuses.chooseList = chooseList;
          break;
        default: break;
      }
    },
    [types.UPDATE_PARAMS_FLAG_INFO](state, { sourceType = 1, flag = false }) {
      switch (sourceType) {
        case 1: state.currentDiseases.flag = flag;
          break;
        case 2: state.heredityDiseases.flag = flag;
          break;
        case 3: state.allergyResp.flag = flag;
          break;
        case 4: state.disabilityDiseases.flag = flag;
          break;
        case 5: state.traumas.flag = flag;
          break;
        case 6: state.surgerys.flag = flag;
          break;
        case 7: state.transfuses.flag = flag;
          break;
        default: break;
      }
    },
    [types.SAVE_BASE_INFO](state, data) {
      state.baseInfo = data || {};
      localStorage.setItem('baseInfoData',JSON.stringify(data))
    },
    [types.SAVE_BODY_INFO](state, data) {
      state.bodyInfo = data || {};
      localStorage.setItem('bodyInfoData',JSON.stringify(data))

    },
    [types.SAVE_LIFE_INFO](state, data) {
      state.lifeStyle = data || {};
      localStorage.setItem('lifeInfoData',JSON.stringify(data))

    },
    // 保存病史情况
    [types.SAVE_MEDICAL_HISTORY](state, data) {
      state.medicalHistory = data || {};
      let { currentDiseases, heredityDiseases, drugAllergys, environmentAllergys, foodAllergys, disabilityDiseases, traumas, surgerys, transfuses } =
        data || {};
      // 将请求数据存入local
      localStorage.setItem('historyData',JSON.stringify(data))
      // requestId
      currentDiseases = (currentDiseases || []).map(item => ({ ...item, requestId: item.id }))
      heredityDiseases = (heredityDiseases || []).map(item => ({ ...item, requestId: item.id }))
      drugAllergys = (drugAllergys || []).map(item => ({ ...item, requestId: item.id, label: item.allergenName, type: 1 }))
      environmentAllergys = (environmentAllergys || []).map(item => ({ ...item, requestId: item.id, label: item.allergenName, type: 2 }))
      foodAllergys = (foodAllergys || []).map(item => ({ ...item, requestId: item.id, label: item.allergenName, type: 3 }))
      disabilityDiseases = (disabilityDiseases || []).map(item => ({ ...item, requestId: item.id, label: item.diseaseName }))
      traumas = (traumas || []).map(item => ({ ...item, requestId: item.id, label: item.traumaName, diagnosisTime: item.traumaTime }))
      surgerys = (surgerys || []).map(item => ({ ...item, requestId: item.id, label: item.surgeryName, diagnosisTime: item.surgeryTime }))
      transfuses = (transfuses || []).map(item => ({ ...item, requestId: item.id, label: item.transfuseName, diagnosisTime: item.transfuseTime }))

      state.currentDiseases = { addList: [], deleteIds: [], chooseList: currentDiseases || [], flag: currentDiseases && currentDiseases.length ? true : false };
      state.heredityDiseases = { addList: [], deleteIds: [], chooseList: heredityDiseases || [], flag: heredityDiseases && heredityDiseases.length ? true : false };
      const allergyResp = [...(drugAllergys || []), ...(foodAllergys || []), ...(environmentAllergys || [])];
      state.allergyResp = { addList: [], drugAllergysDeleteIds: [], environmentAllergysDeleteIds: [], foodAllergysDeleteIds: [], chooseList: allergyResp || [], flag: allergyResp && allergyResp.length ? true : false };
      state.disabilityDiseases = { addList: [], deleteIds: [], chooseList: disabilityDiseases || [], flag: disabilityDiseases && disabilityDiseases.length ? true : false };
      state.traumas = { addList: [], deleteIds: [], chooseList: traumas || [], flag: traumas && traumas.length ? true : false };
      state.surgerys = { addList: [], deleteIds: [], chooseList: surgerys || [], flag: surgerys && surgerys.length ? true : false };
      state.transfuses = { addList: [], deleteIds: [], chooseList: transfuses || [], flag: transfuses && transfuses.length ? true : false };

      state.diseaseInitStr = JSON.stringify(state.currentDiseases) + JSON.stringify(state.heredityDiseases) + JSON.stringify(state.allergyResp) + JSON.stringify(state.disabilityDiseases) + JSON.stringify(state.traumas) +
        JSON.stringify(state.surgerys) + JSON.stringify(state.transfuses)
    },
    // 更新删除数组
    [types.UPDATE_DELETEIDS_DATA](state, data) {
      const { sourceType, id, type } = data;
      if (id) {
        switch (sourceType) {
          case 1:
            !state.currentDiseases.deleteIds.includes(id) && state.currentDiseases.deleteIds.push(id);
            break;
          case 2:
            !state.heredityDiseases.deleteIds.includes(id) && state.heredityDiseases.deleteIds.push(id);
            break;
          case 3:
            if (type === 1) {
              !state.allergyResp.drugAllergysDeleteIds.includes(id) && state.allergyResp.drugAllergysDeleteIds.push(id);
            } else if (type === 2) {
              !state.allergyResp.environmentAllergysDeleteIds.includes(id) && state.allergyResp.environmentAllergysDeleteIds.push(id);
            } else if (type === 3) {
              !state.allergyResp.foodAllergysDeleteIds.includes(id) && state.allergyResp.foodAllergysDeleteIds.push(id);
            }
            break;
          case 4:
            !state.disabilityDiseases.deleteIds.includes(id) && state.disabilityDiseases.deleteIds.push(id);
            break;
          case 5:
            !state.traumas.deleteIds.includes(id) && state.traumas.deleteIds.push(id);
            break;
          case 6:
            !state.surgerys.deleteIds.includes(id) && state.surgerys.deleteIds.push(id);
            break;
          case 7:
            !state.transfuses.deleteIds.includes(id) && state.transfuses.deleteIds.push(id);
            break;
          default: break;
        }
      }
    },

    [types.SAVE_DEFAULT_SELECT_LIST](state, data) {
      state.defaultSelectList = data || []
      localStorage.setItem('defaultSelectList',JSON.stringify(data))
    },
    [types.CHECK_PATIENT_ID](state,data){
      state.checkPatientId = data
    },
    [types.CHECK_PATIENT](state,data){
      state.checkPatient = data
    },
    [types.PATIENT_DISEASES_LIST](state,data){
      state.patientDiseasesList = data;
    },
    [types.PATIENT_DRUG_DISEASE_LIST](state,data){
      state.patientDrugDiseaseList = data;
    },
    [types.PATIENT_SELF_DISEASE_TAG_LIST](state,data){
      state.patientSelfDiseaseTagList = data;
    },
    [types.PATIENT_DRUG_LIST](state,data){
      state.patientDrugList = data;
    },
    [types.CHECK_MERCHANT_ID](state,data){
      state.checkMerchantId = data;
    },
    [types.CHECK_GOOD_STORE_ID](state,data){
      state.checkGoodStoreId = data;
    },
    [types.ALLERGY_INFO_LIST](state,data){
      state.allergyInfoList = data;
    }
  },
  getters: {},
};
