/* eslint-disable complexity */
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import store from '../store/index.js';
import { isInApp, logout, versions, tokenHandle, getChannelCode, getUniChannelCode } from '@/utils/index';
import { Toast } from 'vant';
import { bs_getToken } from '@/utils/bridge';
import { genApiSign } from 'ddjk-utils/dist/sign';

const jkAppId = 124;

const vm = new Vue();
const dsBridge = window.dsBridge;

/** *** --start 幂健康接口 *****/
const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
});

api.interceptors.request.use(
  config => {
    // platformId  0:android端; 1:ios端; 2:pc端; 3:H5; 4:小程序; 6:POS
    const platformId = 3;
    let coonType = 10;
    const cityId = '110101';
    let ut = '';
    if (isInApp()) {
      // 如果是app端
      bs_getToken();
      let headers = localStorage.getItem('headers') || '{}';
      headers = JSON.parse(headers);
      coonType = headers['coonType'] || '';
      if (headers && headers['ut']) {
        ut = headers['ut'];
      }
      // /^(http|https).*.jk.com/.test(location.origin)
      const regExp = /^(http|https).*.jk.com/.test(location.origin);
      if (!regExp && !headers['jk-token']) {
        var token = '';
        config.headers['jk-token'] = token;
      } else {
        config.headers = {
          ...headers,
          ...config.headers
        };
      }
    } else {
      let userInfos = localStorage.getItem('userInfo');
      userInfos = userInfos && userInfos != 'undefined' ? userInfos : '{}';
      const userInfoObj = JSON.parse(userInfos);
      const loginJsonStr = {
        ...config.headers,
        ...userInfoObj,
        'jk-app-id': jkAppId,
        'jk-token': localStorage.getItem('jk-token') || '',
        ut: localStorage.getItem('ut') || ''
      };
      if (
        localStorage.getItem('jk-token') &&
        localStorage.getItem('jk-token') != 'undefined' &&
        localStorage.getItem('jk-token') != 'null'
      ) {
        loginJsonStr['jk-token'] = localStorage.getItem('jk-token');
      }
      ut = localStorage.getItem('ut') || '';
      config.headers = { ...loginJsonStr };

      const signObj = genApiSign(process.env.VUE_APP_SALT);
      Object.keys(signObj).forEach(key => {
        config.headers[key] = signObj[key];
      });
    }
    config.headers['ut'] = ut;
    const channelCode = getChannelCode();
    config.headers['channelSearchCode'] = channelCode;
    config.headers['channelCode'] = getUniChannelCode();
    // 地推 携带推广者id
    if (sessionStorage.getItem('jk-distributor-id')) {
      config.headers['jk-distributor-id'] = sessionStorage.getItem('jk-distributor-id');
    }
    console.log('axios-api-config', config);
    // 犇思渠道标识
    if (localStorage.getItem('sourceChannelCode')) {
      config.headers['channelCode'] = localStorage.getItem('sourceChannelCode');
      // 大数据接口单独添加channelSearchCode
      if (config.url.indexOf('searchmain/item/search') > -1) {
        config.headers['channelSearchCode'] = '210020';
      }
    }
    // 服务商品渠道标识
    if (config.data && config.data.serviceCommodityChannelCode) {
      config.headers['channelCode'] = config.data.serviceCommodityChannelCode;
      config.headers['channelSearchCode'] = config.data.serviceCommodityChannelCode;
    }
    if (config.data && config.data.serviceCommoditySearchChannelCode) {
      config.headers['channelSearchCode'] = config.data.serviceCommoditySearchChannelCode;
    }
    if (config.method === 'post' || config.method === 'put') {
      config.data = config.data || {};
      config.data['coonType'] = config.data['coonType'] || coonType;
      config.data['platformId'] = config.data['platformId'] || platformId;
      config.data['cityId'] = config.data['cityId'] || cityId;
    } else {
      // config['params']['platformId'] = platformId;
      // config['params']['cityId'] = cityId;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    // 提交按钮显示加载
    store.state.globalAutoLoading ? store.commit('CHANGE_LOADING', false) : false;
    if (response.status == 200 && response.data) {
      var data = response.data;
      if (data.code == 200 || data.code == 0) {
        tokenHandle.setResponse(response);
        return data;
      } else {
        return Promise.reject(data);
      }
    }
  },
  error => {
    // 提交按钮显示加载
    store.commit('CHANGE_LOADING', false);
    console.log('axios error: ', error);
    const res = error.response;

    if (res && res.status === 400) {
      const errMsg = res?.message || res?.data?.message || res?.data?.errorMsg || '400 Bad';
      // Toast(errMsg);
      if (JSON.stringify(error).indexOf('Network Error') > -1) {
        console.log(error, '网络链接失败', '--Network Error--');
        // Toast('网络链接失败');
      } else {
        Toast(errMsg);
      }
    } else if (res && res.status === 401) {
      // Toast.fail('授权已过期')
      if (window.wx.miniProgram && localStorage.getItem('sourceChannelCode')) {
        window.wx.miniProgram.navigateTo({ url: '/pages/index/index' });
      } else {
        const thirdAppType = localStorage.getItem('thirdAppType');
        if (thirdAppType == 1) {
          vm.$loginPop({
            callback: () => {
              location.reload();
            }
          });
        } else {
          logout();
        }
      }
    } else if (res && res.status === 403) {
      Toast.fail('请求被拒绝');
    } else if (res && res.status === 500) {
      Toast.fail('服务器内部错误');
    } else if (JSON.stringify(error).indexOf('Network Error') != -1) {
      console.log(error, '网络链接失败', '--Network Error--');
      // Toast('网络链接失败');
    } else {
      Toast.fail('未知错误');
    }
    return Promise.reject({
      ...error,
      message: error.message && error.message.includes('timeout') ? '网络有点拥堵，请重试～' : error.message
    });
  }
);

/** *** --start 欧电云中台接口 *****/
const axios_ody = axios.create({
  baseURL: process.env.VUE_APP_HOST_ODY,
  timeout: 10000
  // withCredentials: true // 允许携带cookie
});

// http request 拦截器
axios_ody.interceptors.request.use(
  config => {
    // platformId  0:android端; 1:ios端; 2:pc端; 3:H5; 4:小程序; 6:POS
    let platformId = 3;
    let coonType = 10;
    let cityId = '110101';
    let ut = '';
    // 如果是app端
    if (isInApp()) {
      bs_getToken();
      let headers = localStorage.getItem('headers') || '{}';
      headers = {
        ...JSON.parse(headers),
        ...config.headers
      };

      if (headers && headers['ut']) {
        coonType = headers['coonType'];
        cityId = headers['cityId'];
        ut = headers['ut'];
        platformId = headers['platformId'];
      }
      platformId = versions().ios ? 1 : 0;
    } else {
      platformId = 3;
      ut = localStorage.getItem('ut') || '';
      config.headers['jk-app-id'] = 124;
    }
    config.headers['ut'] = ut;
    // config.headers["platformId"] = platformId;
    if (config.method === 'post' || config.method === 'put') {
      config.data = config.data || {};
      config.data.coonType = coonType;
      config.data.platformId = platformId;
      config.data.cityId = cityId;

      let contentType = '';
      if (config.method === 'put') {
        contentType = config.headers.put['Content-Type'] || '';
      } else {
        contentType = config.headers.post['Content-Type'] || '';
      }
      if (contentType.indexOf('urlencoded') > -1) {
        config.data = qs.stringify(config.data);
      }
    } else {
      // config.params.coonType = coonType;
      config.params.platformId = platformId;
      config.params.cityId = cityId;
    }
    const channelCode = getChannelCode();
    config.headers['channelSearchCode'] = channelCode;
    config.headers['channelCode'] = getUniChannelCode();
    // 地推 携带推广者id
    if (sessionStorage.getItem('jk-distributor-id')) {
      config.headers['jk-distributor-id'] = sessionStorage.getItem('jk-distributor-id');
    }
    // 犇思渠道标识(近视眼)
    if (localStorage.getItem('sourceChannelCode')) {
      config.headers['channelCode'] = localStorage.getItem('sourceChannelCode');
    }

    // 服务商品渠道标识
    if (config.data && config.data.serviceCommodityChannelCode) {
      config.headers['channelCode'] = config.data.serviceCommodityChannelCode;
      config.headers['channelSearchCode'] = config.data.serviceCommodityChannelCode;
    }
    if (config.data && config.data.serviceCommoditySearchChannelCode) {
      config.headers['channelSearchCode'] = config.data.serviceCommoditySearchChannelCode;
    }

    console.log('axios-ody-config', config);
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios_ody.interceptors.response.use(
  response => {
    if (response.status == 200 && response.data) {
      const headers = response.headers;
      tokenHandle.setResponse(response);
      if (
        headers['content-type'] === 'application/octet-stream;charset=utf-8' ||
        headers['content-type'] == 'application/javascript; charset=utf8'
      ) {
        return response.data;
      }
      var data = response.data;
      if (data.status == 0 || data.code == 0 || data.code == 200) {
        return data;
      } else if (data.code == 770012) {
        logout();
      } else {
        return Promise.reject(data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  err => {
    if (err && err.response) {
      if (err.response.status == 302) {
        // 登录失效跳转app登录页面
        dsBridge.call('goLoginPage');
        return { status: 302, message: '授权失败, 请重新登录！' };
      } else if (err.response.status == 401) {
        if (window.wx.miniProgram && localStorage.getItem('sourceChannelCode')) {
          window.wx.miniProgram.navigateTo({ url: '/pages/index/index' });
        } else {
          err.message = '未授权，请登录';
        }
      } else {
        switch (err.response.status) {
          case 400:
            err.message = '请求错误';
            break;

          // case 401:

          //   // logout();
          //   break;

          case 403:
            err.message = '拒绝访问';
            break;

          case 404:
            err.message = `请求地址出错: ${err.response.config.url}`;
            break;

          case 408:
            err.message = '请求超时';
            break;

          case 500:
            err.message = '网络请求失败';
            break;

          case 501:
            err.message = '服务未实现';
            break;

          case 502:
            err.message = '网关错误';
            break;

          case 503:
            err.message = '服务不可用';
            break;

          case 504:
            err.message = '网关超时';
            break;

          case 505:
            err.message = 'HTTP版本不受支持';
            break;

          default:
            err.message = '未知错误';
        }
        return Promise.reject(err.message);
      }
    } else {
      console.warn(err, JSON.stringify(err), typeof err, 'ereeeerrrrr');
      return Promise.reject({
        ...err,
        message: err.message && err.message.includes('timeout') ? '网络有点拥堵，请重试～' : err.message
      });
    }
  }
);

/**
 * [HttpService description]
 *  fetch:get请求方法、 post: post请求方法
 * @param url
 * @param params
 * @return (Promise)
 */

const api_ody = {
  fetch: function (url, params = {}, responseType = 'json') {
    return axios_ody.get(url, {
      params: params,
      withCredentials: false,
      responseType: responseType
    });
  },
  put: function (url, params = {}, contentType = 'application/json') {
    // application/json
    axios_ody.defaults.headers.put['Content-Type'] = contentType;
    return axios_ody.put(url, params);
  },
  post: function (url, params = {}, contentType = 'application/json', headersConfig) {
    // 提交订单时增加的header
    if (url == '/api/checkout/submitOrder') {
      axios_ody.defaults.headers.post['orderChannel'] = '124';
    }
    axios_ody.defaults.headers.post['Content-Type'] = contentType;
    return axios_ody.post(url, params, {
      headers: {
        ...headersConfig
      }
    });
  }
};

/** *** --start 远程医疗接口 *****/
const api_ycyl = axios.create({
  baseURL: process.env.VUE_APP_HOST_YCYL,
  timeout: 10000
  // withCredentials: true // 允许携带cookie
});
// http request 拦截器
api_ycyl.interceptors.request.use(
  config => {
    const channelCode = getChannelCode();
    config.headers['channelSearchCode'] = channelCode;
    config.headers['channelCode'] = getUniChannelCode();
    config.headers['jk-app-id'] = 124;
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// http response 拦截器
api_ycyl.interceptors.response.use(
  response => {
    if (response.status == 200 && response.data) {
      const headers = response.headers;
      if (
        headers['content-type'] === 'application/octet-stream;charset=utf-8' ||
        headers['content-type'] == 'application/javascript; charset=utf8'
      ) {
        return response.data;
      }
      var data = response.data;
      if (data.status == 0 || data.code == 0) {
        return data;
      } else {
        return Promise.reject(data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  err => {
    if (err && err.response) {
      if (err.response.status == 302) {
        // 登录失效跳转app登录页面
        dsBridge.call('goLoginPage');
        return { status: 302, message: '授权失败, 请重新登录！' };
      } else {
        switch (err.response.status) {
          case 400:
            err.message = '请求错误';
            break;

          case 401:
            err.message = '未授权，请登录';
            logout();
            break;

          case 403:
            err.message = '拒绝访问';
            break;

          case 404:
            err.message = `请求地址出错: ${err.response.config.url}`;
            break;

          case 408:
            err.message = '请求超时';
            break;

          case 500:
            err.message = '网络请求失败';
            break;

          case 501:
            err.message = '服务未实现';
            break;

          case 502:
            err.message = '网关错误';
            break;

          case 503:
            err.message = '服务不可用';
            break;

          case 504:
            err.message = '网关超时';
            break;

          case 505:
            err.message = 'HTTP版本不受支持';
            break;

          default:
            err.message = '未知错误';
        }
        return Promise.reject(err.message);
      }
    } else {
      return Promise.reject(err);
    }
  }
);

export { api, api_ody, api_ycyl };
