const setRem = () => {
  const htmlWidth =
    (document.body && document.body.clientWidth) || document.getElementsByTagName('html')[0].offsetWidth;
  const htmlDom = document.getElementsByTagName('html')[0];
  htmlDom.style.fontSize = htmlWidth / 3.75 + 'px';
};
setRem();
window.addEventListener('resize', function () {
  setRem();
});
