import selectn from 'selectn';
import { Toast } from 'vant';
import {
  fetchGroupOrderList,
  // startGroupOrder
} from '../api/http_mall';
import * as types from './types';

export default {
  namespaced: true,
  state: {
    loading: false,
    tabActive: 0,
    finishStatus: false,
    pageInfo: {
      page: 1,
      size: 10,
      totalNumber: 0,
      totalPage: 0,
    },
    tabList: [],
    // 购物车总数量
    shoppingCartNumber: 0,
    // tab购物车数量
    cartCount: 0,
    // tab购药清单数量
    demandCount: 0,
    tempTabList: [
      {
        type: 1,
        name: '赞',
        unread: null,
        emptyText: '快来多发动态多多互动吧～',
        list: [],
      },
      {
        type: 2,
        name: '评论与@',
        unread: null,
        emptyText: '快来多发动态多多互动吧～',
        list: [],
      },
      {
        type: 3,
        name: '新增关注',
        unread: null,
        emptyText: '快来多发动态多多互动吧～',
        list: [],
      },
      {
        type: 4,
        name: '邀请回答',
        unread: null,
        emptyText: '暂无邀请，快去社区看看吧~',
        list: [],
      },
    ],
  },
  actions: {
    // async fetchTabList({ commit }) {
    //   try {
    //     const { data } = await messageSercices.fetchCommunityMsgTab();
    //     console.log('fetchListData');
    //     console.log(data);
    //     if (!data.success) {
    //       console.log(data.message || '系统异常，请稍后再试');
    //       Toast(data.message || '系统异常，请稍后再试');
    //       commit(`${types.SET_DEFAULT_TAB_LIST}`);
    //     } else {
    //       console.log(data);
    //       console.log(data.data);
    //       commit(`${types.FETCH_MESSAGE_TAB_LIST_SUCCESS}`, data ? data.data : []);
    //     }
    //   } catch ({ response }) {
    //     console.log(response.message || '系统异常，请稍后再试');
    //     commit(`${types.SET_DEFAULT_TAB_LIST}`);
    //   }
    // },
    async getGroupOrderList({ commit }, payload) {
      try {
        // const { patchGrouponId = '' } = this.$route.query;
        // const { id = '' } = this.$route.params;
        // console.error(this.$route, 'this.$router');
        // console.error(patchGrouponId, id, '活动Id');
        const params = {
          currentPage: 1,
          itemsPerPage: 10,
          patchGrouponThemeId: payload.patchGrouponId || 264, // 拼团活动ID
          mpId: payload.id || 40291, // 商品ID
        };
        const data = await fetchGroupOrderList(params);
        console.log(data, 'data');
        if (data.code != 0) {
          console.log(data, data.message);
          // this.recommendList = [];
          commit(types.RESET_RECOMMEND_LIST, []);
        } else {
          // Toast(data.message || '获取成功');
          console.log('data', data);
          commit(types.SAVE_RECOMMEND_LIST, data);
        }
      } catch ({ response }) {
        console.log('response', response);
        const errorText = response ? response.message : '服务异常，请稍后再试';
        console.log(errorText);
        Toast(errorText);
      }
    },
    // async startGroup({ commit }, options) {
    //   try {
    //     console.warn('options', options);
    //     const params = {
    //       businessType: 1,
    //       grouponId: '', // 拼团活动id
    //       grouponCode: '', // 当前的参团团id，参团时必填，开团时不需要
    //       skus: [],
    //       ...options,
    //     };
    //     const data = await startGroupOrder(params);
    //     console.log("拼团入参",data)
    //     if (data.code == 0) {
    //       sessionStorage.setItem('cartJson', JSON.stringify(params));
    //       this.$router.push({
    //           name: 'confirmOrder',
    //           query:{
    //             ifGrouponOrder: true
    //           }
    //       })
    //     } else {
    //       console.log(data, 'datatatata231321132123312')
    //       Toast(data.message);
    //     }
    //   } catch (err) {
    //     console.log(err, 'errtatata')
    //     const errText = err ? err.message : '服务异常，请稍后再试';
    //     Toast(errText);
    //   }
    // },
  },
  mutations: {
    [types.FETCH_MESSAGE_TAB_LIST_SUCCESS](state, data) {
      console.log(data);
      state.tabList = data && data.length ? data.map((item) => {
        return {
          ...(state.tempTabList.filter(c => c.type == item.type)[0]),
          ...item,
        };
      }) : state.tempTabList.slice(0, -1);
      console.log(state.tabList);
      // 请求完列表数据之后消息数量消失
      setTimeout(() => {
        state.tabList[0].unread = null;
      }, 500);
    },
    [types.SET_DEFAULT_TAB_LIST](state) {
      state.tabList = state.tempTabList.slice(0, -1);
    },
    [types.SAVE_RECOMMEND_LIST](state, data) {
      const recommendList = selectn('data.listObj', data) || [];
      state.recommendList = recommendList;
      const list = recommendList.length ? recommendList.slice(0, 2) : [];
      state.total = selectn('data.total', data) || 0;
      let recommendGroupList = [];
      recommendGroupList = list.map((item, index) => {
        console.log(index, 'index', item);
        const memberAvaArr = item.patchInstInfo && item.patchInstInfo.length ? item.patchInstInfo.map((c) => {
          console.log(c, 'c');
          return {
            content: c.customerAvatarUrl,
          };
        }) : [];
        // console.log(memberAvaArr, 'memberAvaArr');
        // const len = memberAvaArr && memberAvaArr.length ? total - memberAvaArr.length : total;
        // console.log(len, 'len');
        const avatarList = [...memberAvaArr, ...new Array(item.remainQty || 0).fill({ content: '?' })];
        console.log('avatarList', avatarList);
        console.log('avatarList', data.servicesTime);
        return {
          list: [...avatarList],
          maxSize: 2,
          num: item.remainQty,
          finishTime: item.patchGrouponEndTime,
          nowTime: data.servicesTime || new Date().getTime(),
        };
      });
      state.recommendGroupList = [...recommendGroupList];
      console.log('this.recommendGroupList', recommendGroupList);
      state.swiperList = this.splitArr(this.recommendGroupList, 2);
      console.log('swiperList======================', this.swiperList);
    },
    [types.UPDATE_SHOPPING_CART_NUMBER](state, obj) {
      const { count = 0, demandCount = 0, totalNum = 0 } = obj
      state.cartCount = count
      state.demandCount = demandCount
      state.shoppingCartNumber = totalNum
    },
  },
  getters: {}
};
