import * as types from './types';

export default {
  namespaced: true,
  state: {
    doctorInfos: {}
  },
  mutations: {
    [types.UPDATE_DOCTORINFO](state, payload) {
      console.log(state.doctorInfos, payload, 'UPDATE_DOCTORINFO');
      state.doctorInfos = { ...payload };
      console.log(state.doctorInfos, 'UPDATE_DOCTORINFO');
    }
  },
  getters: {}
};
