export default [
  {
    path: '/store/index',
    name: 'StoreIndex',
    component: () => import('@mall/views/store/Index.vue'),
    meta: { title: '店铺首页' }
  },
  {
    path: '/storelist',
    name: 'StoreList',
    component: () => import('@mall/views/store/StoreList.vue'),
    meta: { title: '搜索' }
  },
  {
    path: '/store/search',
    name: 'StoreSearch',
    component: () => import('@mall/views/store/searchResult.vue'),
    meta: { title: '店铺搜索结果页' }
  },
  {
    path: '/store/fail',
    name: 'LocationFail',
    component: () => import('@mall/views/store/LocationFail.vue'),
    meta: { title: '1小时达' }
  },
  {
    path: '/store/forbidden',
    name: 'StoreForbidden',
    component: () => import('@mall/views/store/StoreForbidden.vue'),
    meta: { title: '' }
  },
  {
    path: '/store/storeHomePage',
    name: 'storeHomePage',
    component: () => import('@mall/views/store/storeHomePage'),
    meta: { title: '药店首页' }
  },
  {
    path: '/drugStore',
    name: 'DrugStore',
    component: () => import('@mall/views/drugStore/index.vue'),
    meta: {
      title: '选购药品'
    }
  }
];
