export default [
  {
    path: '/myHealth/addHealthRecord',
    name: 'add-health-record',
    component: () => import('@mall/views/myHealth/index.vue'),
    meta: {
      title: ' '
    }
  }
];
