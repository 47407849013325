import api from './axios';
import { uuid } from '@/utils';
import { getUniChannelCode } from '@/utils/index';

// 医生个人中心
export const search = params => api.post(`/mall/partner/search`, params);

export const partner = params => api.get(`/mall/partner/` + params);
export const partnerAll = () => api.get(`/mall/partner`);

export const partnerDetailQuery = params =>
  api.get(
    `/mall/patient-relationship/detail/query?patientId=${params.patientId}&teamId=${params.teamId}&imType=${params.imType}`
  );

export const completeConsultationPartnerList = params =>
  api.post(`/mall/order/customerOrder/completeConsultationPartnerList?page=${params.page}&size=${params.size}`);

export const authSessionId = params =>
  api.post(`/mall/patient-relationship/archive/auth?sessionId=${params.sessionId}`);

export const queryCommentBaseByDoctorId = (params, supplierId) =>
  api.get(`/mall/order/partnerOrder/partner/queryPartnerCommentDataOnMall?doctorId=${params}&supplierId=${supplierId}`);

export const listCommentByDoctorId = params =>
  api.post(
    `/mall/order/partnerOrder/partner/listCommentByDoctorIdOnMall?doctorId=${params.doctorId}&page=${params.page}&size=${params.size}`
  );

export const area = params => api.get(`/basic/area`, params);

export const standardArea = params => api.get(`/basic/sys/standardArea/` + params);

export const standardDown = params => api.get(`/basic/sys/standardArea/parentCode?parentCode=` + params);

// 科室相关
export const standardDepartment = () => api.get(`/basic/sys/standardDepartment/all`);
export const standardDepartmentParent = params => api.get(`/basic/sys/standardDepartment/parent/` + params);
export const standardDepartmentCommon = () => api.get(`/basic/sys/standardDepartment/query/common`);

// 疾病
export const diseaseByName = () => api.get(`/basic/sys/standardDisease/search`);
// 常见疾病
export const diseaseCommon = channelType => api.get(`/basic/sys/standardDisease/common?channelType=${channelType}`);

// 我的健康模块
export const createBasicInfo = params => api.post(`/health/archive/createBasicInfo`, params);
// 新增修改健康危险因素
export const createRiskFactor = params => api.post(`/health/archive/createRiskFactor`, params);
// 查询健康档案列表
export const queryArchives = () => api.get(`/health/archive/queryArchives?random=${uuid(8, 10)}`);

export const queryBasicInfo = params => api.get(`/health/archive/queryBasicInfo?id=` + params);

export const completePercent = params => api.get(`/health/archive/completePercent/query?id=` + params);

export const queryRiskFactor = params => api.get(`/health/archive/queryRiskFactor?patientId=` + params);

export const queryMedicalHistory = params => api.get(`/health/archive/queryMedicalHistory?patientId=` + params);

// 添加患者/就诊人
export const addPatient = params => api.post(`/health/archive/createBasicInfo`, params);

export const setDefault = params => api.post(`/health/archive/setDefault?id=` + params);

export const createMedicalHistory = params => api.post(`/health/archive/createMedicalHistory`, params);

// 字典
export const dict = params => api.get(`/basic/sys/dict/search/` + params);

// 职称
export const batchQueryPartnerProfessionTitleList = () =>
  api.get(`/medical/partner/profession/batchQueryPartnerProfessionTitleList`);

// 查询用户疾病列表
export const patientConsultation = params => api.post(`/health/patientConsultation/page`, params);

// export const patientConsultationAdd = (params) => api.post(`/health/patientConsultation/add`, params);
export const patientConsultationAdd = params => api.post(`/mall/patient/consultation/addConsultation`, params);

// 添加就诊人病情描述接口(改造)
export const patientConsultationAddNew = params => api.post(`/health/patientConsultation/createByDiagnosis`, params);

// 就诊人搜索历史
export const queryHistory = () => api.get(`/item/searchHistory/history/query`);
export const deleteHistory = () => api.post(`/item/searchHistory/history/delete`);
export const queryHot = () => api.get(`/item/searchHistory/hot/query`);

// 更新患者信息
export const patientUpdate = params => api.post(`/user/patient-relationship/update`, params);

// 医生查看用户随访卡片
export const queryPlanForPatient = id => api.post(`/health/follow/plan/queryPlanForPatient?patientId=${id}`);
// 医生 查询就诊人详情
export const queryDetailByPartner = id => api.get(`health/archive/queryDetailByPartner?id=${id}`);
// 医生查看患者主页-病历本列表
export const listForSeePatientHome = params =>
  api.get(`health/records/listForSeePatientHome?patientId=${params.patientId}`);

// 获取默认就诊人ID
export const queryDefaultPatientId = () => api.get('/health/patient/myself');

// 获取病情描述页的券后价格
export const queryPrice = params => api.post(`/mall/ody/promotion/getDiscountPriceV2`, params);

// 校验是否有未完成快速问诊订单
export const checkStatusByPatientId = id =>
  api.get(`/transaction/cy/order/diagnosis/checkStatusByPatientId?patientId=${id}`);

// 获取常用疾病
export const queryDiseaseTag = params => api.post(`/basic/sys/standardDisease/page`, params);

// 支付相关

// 微信H5支付
// /payment/pay/wxH5Pay
export const wxH5Pay = params => api.post('/payment/pay/wxH5Pay', params);
// 微信jsapi支付
export const wxJsApiPay = params => api.post('/payment/pay/wxJsApiPay', params);

// 获取app下载链接 "101" "用户端ios"、"102" "用户端android"、 "104", "用户端H5" 、 "202", "服务端android"、 "203", "服务端WEB" 、"204", "服务端H5"
export const appVersion = jkAppId => {
  return api.post('/basic/app/version/query/effective', null, {
    headers: {
      'jk-app-id': jkAppId
    }
  });
};
// 图片上传
export const createOssToken = () => api.post(`/basic/oss/createOssToken?random=${uuid(8, 10)}`);

// 获取微信授权签名
export const getSignature = url => api.get(`/auth/wx/createJsapiSignature?url=${url}`);

// 获取全局配置
export const getConfByKey = configKey => api.get(`/basic/sys/global/config/getByKey?configKey=${configKey}`);

// 获取客服电话
export const getCustomServiceNum = configKey =>
  api.get(`/user/contactServiceSupport/getTelPhone?configKey=${configKey}`);

// 二次诊疗常见疾病
export const recommendList = patientId =>
  api.get(`/medical/search/disease/recommendByPatient?patientId=${patientId || ''}`);
// 二次诊疗疾病搜索
export const getDiseaseTags = params => api.post(`/medical/search/disease/pageSearch`, params);

/**
 * 查询就诊人详情
 * @param {*} patientNo
 */
export const queryPatientDetail = patientNo =>
  api.post(`/ddjk-market/patients/queryPatientDetail?patientNo=${patientNo}`, null, {
    headers: {
      current_user_id: JSON.parse(localStorage.getItem('unionLoginData')).loginUserResp.userId,
      channelCode: getUniChannelCode()
    }
  });

/**
 * 保存就诊人信息
 * @param params
 */
export const savePatientInfo = params =>
  api.post(`/ddjk-market/patients/addPatientInfo`, params, {
    headers: {
      current_user_id: JSON.parse(localStorage.getItem('unionLoginData')).loginUserResp.userId,
      channelCode: getUniChannelCode()
    }
  });

/**
 * 查询就诊人列表
 * @param params
 */
export const queryPatientList = () =>
  api.post(`/ddjk-market/patients/queryPatientList`, null, {
    headers: {
      current_user_id: JSON.parse(localStorage.getItem('unionLoginData')).loginUserResp.userId,
      channelCode: getUniChannelCode()
    }
  });

/**
 * 查询当前用户是否存在本人就诊人
 * @param params
 */
export const checkPatientIsSelf = () =>
  api.post(`/ddjk-market/patients/checkPatientIsSelf`, null, {
    headers: {
      current_user_id: JSON.parse(localStorage.getItem('unionLoginData')).loginUserResp.userId,
      channelCode: getUniChannelCode()
    }
  });

// 添加就诊人及过敏史信息
export const addPatientAndAllergyInfo = params => {
  return api.post(`/ddjk-market/patients/addPatientAndAllergyInfo`, params, {
    headers: {
      channelCode: getUniChannelCode()
    }
  });
};

export const fetchPharmacyInfo = ({ prescriptionCode, sysChannel }) =>
  api.get(
    `/medical/prescription/online/queryStoreListByCode?prescriptionCode=${prescriptionCode}&sysChannel=${sysChannel}`
  );

/**
 * H5 获取用户IM信息
 * @returns
 */
export const getCustomIM = () => api.get(`/user/im/customer/query`);
