module.exports = {
  'emoticons': [
    {
      'png': 'icon_wei_xiao',
      'chs': '[微笑]'
    },
    {
      'png': 'icon_zi_ya',
      'chs': '[龇牙]'
    },
    {
      'png': 'icon_wei_xiao_dao_le',
      'chs': '[微笑倒了]'
    },
    {
      'png': 'icon_man_yi',
      'chs': '[满意]'
    },
    {
      'png': 'icon_han_xiao',
      'chs': '[憨笑]'
    },
    {
      'png': 'icon_xiao_ku_le',
      'chs': '[笑哭了]'
    },
    {
      'png': 'icon_tou_xiao',
      'chs': '[偷笑]'
    },
    {
      'png': 'icon_huai_xiao',
      'chs': '[坏笑]'
    },
    {
      'png': 'icon_zhou_mei',
      'chs': '[皱眉]'
    },
    {
      'png': 'icon_ju_sang',
      'chs': '[沮丧]'
    },
    {
      'png': 'icon_qin_qin',
      'chs': '[亲亲]'
    },
    {
      'png': 'icon_mei_wei',
      'chs': '[美味]'
    },
    {
      'png': 'icon_jin_ya',
      'chs': '[惊讶]'
    },
    {
      'png': 'icon_da_ku',
      'chs': '[大哭]'
    },
    {
      'png': 'icon_gan_ga',
      'chs': '[尴尬]'
    },
    {
      'png': 'icon_shi_wang',
      'chs': '[失望]'
    },
    {
      'png': 'icon_ke_shui',
      'chs': '[瞌睡]'
    },
    {
      'png': 'icon_tiao_pi',
      'chs': '[调皮]'
    },
    {
      'png': 'icon_tiao_pi_ya',
      'chs': '[调皮呀]'
    },
    {
      'png': 'icon_ku',
      'chs': '[酷]'
    },
    {
      'png': 'icon_man_yan_ai',
      'chs': '[满眼爱]'
    },
    {
      'png': 'icon_bai_yan',
      'chs': '[白眼]'
    },
    {
      'png': 'icon_nan_guo',
      'chs': '[难过]'
    },
    {
      'png': 'icon_gan_mao',
      'chs': '[感冒]'
    },
    {
      'png': 'icon_yun',
      'chs': '[晕]'
    },
    {
      'png': 'icon_kun',
      'chs': '[困]'
    },
    {
      'png': 'icon_ke_lian',
      'chs': '[可怜]'
    },
    {
      'png': 'icon_jing_kong',
      'chs': '[惊恐]'
    },
    {
      'png': 'icon_shuai',
      'chs': '[衰]'
    },
    {
      'png': 'icon_chao_nan_guo',
      'chs': '[超难过]'
    },
    {
      'png': 'icon_jing_huang',
      'chs': '[惊慌]'
    },
    {
      'png': 'icon_sheng_qi',
      'chs': '[生气]'
    },
    {
      'png': 'icon_xiang_ku',
      'chs': '[想哭]'
    },
    {
      'png': 'icon_fei_wen',
      'chs': '[飞吻]'
    },
    {
      'png': 'icon_ou_tu',
      'chs': '[呕吐]'
    },
    {
      'png': 'icon_xiao_sheng_dian',
      'chs': '[小声点]'
    },
    {
      'png': 'icon_jing_dai_le',
      'chs': '[惊呆了]'
    },
    {
      'png': 'icon_mao_yan_le',
      'chs': '[冒烟了]'
    },
    {
      'png': 'icon_mao_xi_huan',
      'chs': '[猫喜欢]'
    },
    {
      'png': 'icon_mao_kai_xin',
      'chs': '[猫开心]'
    },
    {
      'png': 'icon_sheng_li',
      'chs': '[胜利]'
    },
    {
      'png': 'icon_liang_yan_mao_xing',
      'chs': '[两眼冒星星]'
    },
    {
      'png': 'icon_da_peng_ti',
      'chs': '[打喷嚏]'
    },
    {
      'png': 'icon_wu_lian_nan',
      'chs': '[捂脸男]'
    },
    {
      'png': 'icon_wu_lian_nv',
      'chs': '[捂脸女]'
    },
    {
      'png': 'icon_skr',
      'chs': '[skr]'
    },
    {
      'png': 'icon_si_kao',
      'chs': '[思考]'
    },
    {
      'png': 'icon_da_bao_ya',
      'chs': '[大龅牙]'
    },
    {
      'png': 'icon_liang_ti_wen',
      'chs': '[量体温]'
    },
    {
      'png': 'icon_man_yan_qian',
      'chs': '[满眼钱]'
    },
    {
      'png': 'icon_bi_zui',
      'chs': '[闭嘴]'
    },
    {
      'png': 'icon_huo_che_tou',
      'chs': '[火车头]'
    },
    {
      'png': 'icon_huo_jian',
      'chs': '[火箭]'
    },
    {
      'png': 'icon_kai_yan',
      'chs': '[开眼]'
    },
    {
      'png': 'icon_wu_yan',
      'chs': '[捂眼]'
    },
    {
      'png': 'icon_deng_yan',
      'chs': '[瞪眼]'
    },
    {
      'png': 'icon_sheng_dan_lao_ren',
      'chs': '[圣诞老人]'
    },
    {
      'png': 'icon_cai_hong',
      'chs': '[彩虹]'
    },
    {
      'png': 'icon_ai_ni',
      'chs': '[爱你]'
    },
    {
      'png': 'icon_qin_mi',
      'chs': '[亲密]'
    },
    {
      'png': 'icon_wo_shou',
      'chs': '[握手]'
    },
    {
      'png': 'icon_gan_xie',
      'chs': '[感谢]'
    },
    {
      'png': 'icon_xiao_e_mo',
      'chs': '[小恶魔]'
    },
    {
      'png': 'icon_tian_shi',
      'chs': '[天使]'
    },
    {
      'png': 'icon_wu_zhu',
      'chs': '[无助]'
    },
    {
      'png': 'icon_ok',
      'chs': '[OK]'
    },
    {
      'png': 'icon_bao_bao',
      'chs': '[抱抱]'
    },
    {
      'png': 'icon_bi_shi',
      'chs': '[鄙视]'
    },
    {
      'png': 'icon_bian_bian',
      'chs': '[便便]'
    },
    {
      'png': 'icon_cai_dai',
      'chs': '[彩带]'
    },
    {
      'png': 'icon_ca_jin',
      'chs': '[差劲]'
    },
    {
      'png': 'icon_gan_bei',
      'chs': '[干杯]'
    },
    {
      'png': 'icon_gu_zhang',
      'chs': '[鼓掌]'
    },
    {
      'png': 'icon_kou_bi',
      'chs': '[抠鼻]'
    },
    {
      'png': 'icon_xiang_bing',
      'chs': '[香槟]'
    },
    {
      'png': 'icon_xin_sui',
      'chs': '[心碎]'
    },
    {
      'png': 'icon_ai_xin',
      'chs': '[爱心]'
    },
    {
      'png': 'icon_yi_wen',
      'chs': '[疑问]'
    },
    {
      'png': 'icon_zhu_tou',
      'chs': '[猪头]'
    },
    {
      'png': 'icon_mei_gui',
      'chs': '[玫瑰]'
    },
    {
      'png': 'icon_qi_qiu',
      'chs': '[气球]'
    },
    {
      'png': 'icon_bin_qi_lin',
      'chs': '[冰淇淋]'
    },
    {
      'png': 'icon_ji_rou',
      'chs': '[肌肉]'
    },
    {
      'png': 'icon_yang_guang',
      'chs': '[阳光]'
    },
    {
      'png': 'icon_zuo',
      'chs': '[左]'
    },
    {
      'png': 'icon_you',
      'chs': '[右]'
    },
    {
      'png': 'icon_shang',
      'chs': '[上]'
    },
    {
      'png': 'icon_xia',
      'chs': '[下]'
    },
    {
      'png': 'icon_zan',
      'chs': '[赞]'
    },
    {
      'png': 'icon_jia_you',
      'chs': '[加油]'
    },
    {
      'png': 'icon_you_ling',
      'chs': '[幽灵]'
    },
    {
      'png': 'icon_ji_tui',
      'chs': '[鸡腿]'
    },
    {
      'png': 'icon_shan_dian',
      'chs': '[闪电]'
    },
    {
      'png': 'icon_yu',
      'chs': '[雨]'
    },
    {
      'png': 'icon_yun_1',
      'chs': '[云]'
    },
    {
      'png': 'icon_ku_lou',
      'chs': '[骷髅]'
    },
    {
      'png': 'icon_yue_liang',
      'chs': '[月亮]'
    },
    {
      'png': 'icon_xue_hua',
      'chs': '[雪花]'
    },
    {
      'png': 'icon_di_qiu',
      'chs': '[地球]'
    },
    {
      'png': 'icon_daka_1',
      'chs': '[打卡1]'
    },
    {
      'png': 'icon_daka_2',
      'chs': '[打卡2]'
    },
    {
      'png': 'icon_daka_3',
      'chs': '[打卡3]'
    },
    {
      'png': 'icon_daka_4',
      'chs': '[打卡4]'
    },
    {
      'png': 'icon_daka_5',
      'chs': '[打卡5]'
    }
  ]
}
