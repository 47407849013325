export default {
  namespaced: true,
  state: {
    kpAlives: [],
    isLoading: false,
    returnUrl: ''
  },
  mutations: {
    SET_KEEP_ALIVE: (state, data) => {
      state.kpAlives = data;
    },
    SET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading;
    },
    SET_RETURNURL: (state, data) => {
      state.returnUrl = data;
    }
  },
  getters: {
    GET_KEEP_ALIVE: state => state.kpAlives,
    GET_LOADING: state => state.isLoading,
    GET_RETURNURL: state => state.returnUrl
  }
};
