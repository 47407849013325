// import store from '../store';
import { isInApp, getStatusBarHeight, versions } from '@/utils/index';
// import Global from '@/projects/mall/utils/common.js';

export default {
  data() {
    return {
      ifLoading: false,
      ifShowConsultWrapper: false,
      contactMobile: ''
    };
  },
  // filters: {
  //   // 格式化时间
  //   formatDate(value, pattern) {
  //     if (!value) {
  //       return '';
  //     }
  //     const type = pattern || 'yyyy-MM-dd hh:mm:ss';
  //     return Global.format_date(value, type);
  //   },
  //   formatMoney(value) {
  //     const commonProperty = store.state.common.commonProperty || {};
  //     const { isShowPrice = 1 } = commonProperty;
  //     if (!isShowPrice) return '0.00';
  //     value = parseFloat(value) || 0;
  //     return value.toFixed(2);
  //   },
  //   formatPriceWithOutZero(value) {
  //     if (value) {
  //       const val = ((parseFloat(value) * 100) / 100).toFixed(2);
  //       return val == parseInt(val) ? parseInt(val) : parseFloat(val);
  //     }
  //     return '';
  //   },
  //   yuan(val) {
  //     const commonProperty = store.state.common.commonProperty || {};
  //     const { isShowPrice = 1 } = commonProperty;
  //     if (!isShowPrice) return '0';
  //     if (val) {
  //       var value = parseFloat(val).toFixed(2);
  //       var num = value.toString();
  //       return num.split('.')[0];
  //     } else {
  //       return '0';
  //     }
  //   },
  //   jiao(val) {
  //     const commonProperty = store.state.common.commonProperty || {};
  //     const { isShowPrice = 1 } = commonProperty;
  //     if (!isShowPrice) return '00';
  //     if (val) {
  //       var value = parseFloat(val).toFixed(2);
  //       var num = value.toString();
  //       return num.split('.')[1];
  //     } else {
  //       return '00';
  //     }
  //   },
  //   jiaoZeroSuppression(val) {
  //     if (val) {
  //       var value = parseFloat(val); // .toFixed(2)
  //       var num = value.toString();
  //       return num.indexOf('.') > -1 ? `.${num.split('.')[1]}` : '';
  //     } else {
  //       return '';
  //     }
  //   },
  //   filterTime(val) {
  //     if (val && typeof val === 'string') {
  //       return val.substring(0, len);
  //     } else {
  //       return '00:00';
  //     }
  //   }
  // },
  mounted() {},
  methods: {
    statusBarHeight() {
      if (isInApp()) {
        return getStatusBarHeight();
      } else {
        return 0;
      }
    },
    /* pageFlag: 页面标识 fromPage: 上一个来源页面 */
    fn_backup(pageFlag, fromPage) {
      if (isInApp()) {
        if (pageFlag == 'order_list') {
          this.$bridge.callSyn('orderListToPersonalSet');
        } else if (pageFlag == 'order_details') {
          if (fromPage == 'orderSuccess') {
            this.$bridge.callSyn('closeWebPage');
          } else {
            this.$bridge.callSyn('backup');
          }
        } else if (pageFlag == 'search_result') {
          this.$router.push({
            name: 'orderList'
          });
        } else {
          this.$bridge.callSyn('backup');
        }
      } else {
        if (
          (pageFlag == 'order_details' && (fromPage == 'orderList' || fromPage == 'paywait')) ||
          pageFlag == 'search_result'
        ) {
          this.$router.push({
            path: '/orderList'
          });
        } else if (pageFlag == 'order_list') {
          if (versions().isMiniProgram && pageFlag == 'order_list') {
            window.wx.miniProgram.navigateBack({
              delta: 1
            });
            return;
          }
          this.$router.push('/index');
        } else {
          this.$router.go(-1);
        }
      }
    },
    fn_resizePage() {
      var clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      window.onresize = function () {
        var nowClientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (clientHeight - nowClientHeight > 60) {
          // 因为ios有自带的底部高度
          // 键盘弹出的事件处理
          document.getElementById('applyContent').classList.add('focusState');
        } else {
          // 键盘收起的事件处理
          document.getElementById('applyContent').classList.remove('focusState');
        }
      };
    },
    fn_checkIfLogin(pageFlag) {
      if (!isInApp()) {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo && userInfo != 'undefined' ? JSON.parse(userInfo) : {};
        if (userInfo && userInfo['ut'] && localStorage.getItem('jk-token')) {
          return true;
        } else {
          console.log('跳登录', this.$route.fullPath, pageFlag);
          this.$router.push({
            path: '/login',
            query: {
              backUrl: this.$route.fullPath
            }
          });
          return false;
        }
      }
    },
    fn_formatCoupon(val) {
      let str = `<span class="icon-symbol">¥</span>0<span class="icon-symbol">.00</span>`;
      const amount = parseFloat(val).toFixed(2);
      const amount_arr = amount.toString().split('.');
      const amount_yuan = amount_arr[0];
      const amount_jiao = amount_arr[1];
      if (val) {
        if (parseInt(val) == val) {
          // 没有小数
          str = `<span class="icon-symbol">¥</span>${amount_yuan}`;
        } else {
          str = `<span class="icon-symbol">¥</span>${amount_yuan}<span class="icon-symbol">.${amount_jiao}</span>`;
        }
      }
      return str;
    },
    fn_formatMoney(val) {
      if (val) {
        var amount = parseFloat(val).toFixed(2);
        var amount_arr = amount.toString().split('.');
        var amount_yuan = amount_arr[0];
        var amount_jiao = amount_arr[1];
        var str =
          '<span class="icon-symbol">¥</span>' + amount_yuan + '<span class="icon-symbol">.' + amount_jiao + '</span>';
        return str;
      } else {
        return '<span class="icon-symbol">¥</span>0';
      }
    }
  }
};
