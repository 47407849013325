/* eslint-disable */

/**
 * 判断为空
 */
const isNull = val => {
  var rt = false;
  //val = val.replace(/\s+/g, "");
  if (val == '' || val == null || val == undefined || val == 'null') {
    rt = true;
  }
  return rt;
};

/**
 * 手机号码验证
 */

const isMobile = val => {
  //const MOBILE = /^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6])|(17[0-8])|(18[0-9])|(19[8-9]))\d{8}$/;
  const MOBILE = /^1\d{10}$/;
  let rt = false;
  if (MOBILE.test(val)) {
    rt = true;
  }
  return rt;
};

/**
 * 电话号码有效验证（包含手机、座机）
 */

const isPhoneNum = val => {
  let Phone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
  const MOBILE = /^1\d{10}$/;

  let rt = false;
  if (Phone.test(val) || MOBILE.test(val)) {
    rt = true;
  }
  return rt;
};

/**
 * 发票校验--单位 纳税人识别号
 */
const isTaxpayerNo = val => {
  const amount = /^[A-Z0-9]{15}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
  let rt = false;
  if (amount.test(val)) {
    rt = true;
  }
  return rt;
};

/**
 * 邮箱校验
 */
const isEmail = val => {
  const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
  let rt = false;
  if (reg.test(val)) {
    rt = true;
  }
  return rt;
};

Date.prototype.format = function (format) {
  let date = {
    'M+': this.getMonth() + 1,
    'd+': this.getDate(),
    'h+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3),
    'S+': this.getMilliseconds()
  };

  if (/(y+)/i.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in date) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? date[k] : ('00' + date[k]).substr(('' + date[k]).length)
      );
    }
  }
  return format;
};

/**
 * 时间格式化
 */
const format_date = (date, format) => {
  let len = 1;
  if (date != undefined && date.length == 10) {
    len = 1000;
  }
  let newDate = new Date(date * len);
  return newDate.format(format);
};

/**
 * 获取时间戳
 */
const format_time_stamp = datetime => {
  let newDate = new Date();
  if (datetime) {
    datetime = datetime.replace(/-/g, '/');
    newDate = new Date(datetime);
  }
  let time = Date.parse(newDate).toString(); //获取到毫秒的时间戳，精确到毫秒
  time = time.substr(0, 10); //精确到秒
  return time;
};

/**
 * 获取cookie
 */
const getCookie = sName => {
  var aCookie = document.cookie.split('; ');
  for (var i = 0; i < aCookie.length; i++) {
    var aCrumb = aCookie[i].split('=');
    if (sName == aCrumb[0]) {
      aCrumb.shift();
      return decodeURIComponent(aCrumb.join('='));
    }
  }
  return '';
};

/**
 * 设置cookie
 */
const setCookie = (sName, sValue, dm, sExpires, path) => {
  var sCookie = sName + '=' + encodeURIComponent(sValue),
    dm = dm || '.jk.com',
    sExpires = sExpires || 30,
    path = path || '/';
  if (sExpires != null) {
    var today = new Date(),
      d = new Date(today.getTime() + sExpires * 1000 * 60 * 60 * 24);
    sCookie += ';expires=' + d.toGMTString() + ';domain=' + dm + ';path=' + path + ';';
  }
  document.cookie = sCookie;
};

/**
 * 删除cookie
 */
const removeCookie = (sName, dm, path) => {
  var dm = dm || location.hostname;
  var path = path || '/';
  document.cookie = sName + '=; expires=Fri, 31 Dec 1999 23:59:59 GMT; domain=' + dm + '; path=' + path;

  // document.cookie = sName + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
};

const is_weixin = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
};

/**
 * 电话号码高亮展示
 */
const selectPhoneNumber = (str, color) => {
  if (!str) return '';
  var regx = /(1[3|4|5|7|8][\d]{9}|0[\d]{2,3}-[\d]{7,8}|400[-]?[\d]{3}[-]?[\d]{4})/g;
  var phoneNums = str.match(regx);
  // 电话号码去重
  phoneNums = phoneNums?.filter((item, index, phoneNums) => phoneNums.indexOf(item) === index);
  if (phoneNums) {
    for (var i = 0; i < phoneNums.length; i++) {
      var temp = phoneNums[i];
      // 替换所有电话号码
      str = str.replaceAll(
        phoneNums[i],
        '<a href="tel:' + temp + '" style="textDecoration: underline;color: ' + color + ';">' + temp + '</a>'
      );
    }
  }
  return str;
};

const deepClone = obj => {
  // var newObj = obj instanceof Array ? [] : {};
  // for (var i in obj) {
  //     if (typeof obj[i] == 'object' && obj[i] != null) {
  //         newObj[i] = this.deepClone(obj[i])
  //     } else {
  //         newObj[i] = obj[i]
  //     }
  // }
  // return newObj;

  return JSON.parse(JSON.stringify(obj));
};

export default {
  isNull,
  isMobile,
  format_date,
  format_time_stamp,
  isTaxpayerNo,
  isPhoneNum,
  isEmail,
  getCookie,
  setCookie,
  removeCookie,
  is_weixin,
  selectPhoneNumber,
  deepClone
};
