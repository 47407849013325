/**
 * 接入合力亿捷客服系统
 * 跳转IM中商品卡片、订单卡片
 */
import store from '@/projects/mall/store/index';
import { isHysApp, isMjkApp } from '@/utils/index';

// eslint-disable-next-line max-len
// 品图文演示demo：https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000030555&chatId=052b96f6-a310-45b4-bd41-2e47f6fa5041&visitorId=123456&nickName=Jerry&phone=13899899888&businessParam=json:{"businessType":"1","businessId":"123456","businessUrl":"https://item.jd.com/6356613.html"}&merchantId=68866000

// eslint-disable-next-line max-len
// 订单图文演示demo：https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000030555&chatId=052b96f6-a310-45b4-bd41-2e47f6fa5041&visitorId=123456&nickName=Jerry&phone=13899899888&businessParam=json:{"businessType":"2","businessId":"123456"}&merchantId=68866000

// eslint-disable-next-line max-len
// 幂健康APP-平台客服聊天入口：https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000030555&chatId=e7c470ce-3644-48a5-8975-4ca544d9c75b&visitorId=123456&nickName=Jerry&phone=13899899888

const accountId = process.env.VUE_APP_HELI_ACCOUNT_ID;
let chatId = process.env.VUE_APP_HELI_CHAT_ID;
if (isHysApp()) {
  chatId = process.env.VUE_APP_HELI_CHAT_ID_HYSAPP;
} else if (isMjkApp()) {
  chatId = process.env.VUE_APP_HELI_CHAT_ID_MJKAPP;
}
let heliImUrl = `https://im6a9eefe.7x24cc.com/phone_webChat.html?accountId=${accountId}&chatId=${chatId}`;

/**
 * 跳转合力IM中的商品卡片、订单卡片
 * @param {*} params
 * businessType: 1 商品图文  2 订单图文
 * businessId: businessType=1 商品id   businessType=2 订单ID
 */
export const goIm = params => {
  let url = '';

  const { storeId = '', businessId = '', businessType = '', imUrl = '', source = '' } = params;

  const skuInfo = { businessType, businessId };

  businessType == 1
    ? (skuInfo.bussinessUrl = `${process.env.VUE_APP_HOST}/client/mall/#/goods/${businessId}.html`)
    : '';

  const {
    userId = '',
    userName = '',
    phone = ''
  } = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) || {} : {};
  // eslint-disable-next-line max-len
  url = `${imUrl}&visitorId=${userId}&userName=${userName}&phone=${phone}&merchantId=${storeId}&businessParam=json:${JSON.stringify(
    skuInfo
  )}`;
  const customerUrl = `${imUrl}&visitorId=${userId}&userName=${userName}`;
  console.warn(url, 'goim');
  return source ? customerUrl : url;
};

/**
 * 个人中心页面跳转至联系客服页面
 */
export const userCenterGoIm = () => {
  if (store.state.common.commonProperty && store.state.common.commonProperty.customerServiceUrl) {
    heliImUrl = store.state.common.commonProperty.customerServiceUrl;
  }
  let userUrl = '';
  const loginUserInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  console.log('获取userInfo', loginUserInfo);
  userUrl = `&visitorId=${loginUserInfo.userId}&nickName=${loginUserInfo.userName}`;
  return `${heliImUrl}${userUrl}`;
};
