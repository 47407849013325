export default [
  {
    path: '/serviceCommodity/detail',
    name: 'ServiceCommodityDetail',
    component: () => import('@mall/views/serviceCommodity/commodityDetail'),
    meta: { title: '商品详情' }
  },
  {
    path: '/serviceCommodity/selectStore',
    name: 'CommoditySelectStore',
    component: () => import('@mall/views/serviceCommodity/selectStore'),
    meta: { title: '选择门店' }
  },
  {
    path: '/serviceCommodity/citySearch',
    name: 'ServiceCommodityCitySearch',
    component: () => import('@mall/views/serviceCommodity/citySearch'),
    meta: { title: '选择城市' }
  },
  {
    path: '/serviceCommodity/areaSearch',
    name: 'ServiceCommodityAreaSearch',
    component: () => import('@mall/views/serviceCommodity/areaSearch'),
    meta: { title: '选择城市' }
  },
  {
    path: '/serviceCommodity/onlineService',
    name: 'ServiceCommodityOnlineService',
    component: () => import('@mall/views/serviceCommodity/onlineService'),
    meta: { title: '在线客服' }
  },
  {
    path: '/serviceCommodity/createOrder',
    name: 'ServiceCommodityCreateOrder',
    component: () => import('@mall/views/serviceCommodity/createOrder'),
    meta: { title: '提交订单' }
  },
  {
    path: '/serviceCommodity/mapPath',
    name: 'ServiceCommodityMapPath',
    component: () => import('@mall/views/serviceCommodity/mapPath'),
    meta: { title: '路线规划' }
  },
  {
    path: '/serviceCommodity/orderList',
    name: 'ServiceCommodityOrderList',
    component: () => import('@mall/views/serviceCommodity/orderList'),
    meta: { title: '服务订单列表' }
  },
  {
    path: '/serviceCommodity/orderDetail',
    name: 'ServiceCommodityOrderDetail',
    component: () => import('@mall/views/serviceCommodity/orderDetail'),
    meta: { title: '服务订单详情' }
  }
];
