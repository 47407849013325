import qs from 'qs';
import { api, api_ody, api_ycyl } from './axios';
import store from '../store';
import { UPDATE_SHOPPING_CART_NUMBER } from '../store/types';
import { versions, getChannelCode } from '@/utils/index';

const getLonAndLat = name => {
  // const lat = sessionStorage.getItem('user_latitude') || '';
  // const lon = sessionStorage.getItem('user_longitude') || '';
  const value = sessionStorage.getItem(name) || '';
  return value;
};

/** *** --start 订单类表接口 *****/
// 获取订单数量
export const getOrderStatusNum = params => {
  return api_ody.post(`/oms-api/order/so/my/summary`, params);
};

// 获取订单列表
export const getOrderList = params => {
  const { thirdChannelInfo: { channelCode } = {} } = JSON.parse(localStorage.getItem('unionLoginData') || '{}');
  const channelCodeArr = channelCode && String(channelCode).indexOf(',') > -1 ? channelCode.split(',') : [channelCode];
  return api.post(`/ddjk-market/order/query/list`, { ...params, channelCodes: channelCodeArr });
};

/**
 * 根据订单状态查询订单
 * @param {*} params
 * @returns
 */
export const getOrderByStatus = params => {
  return api_ody.post(`/oms-api/order/query/listFotDiseaseCenter`, params);
};

// 删除订单
export const deleteOrder = params => {
  return api_ody.post(`/oms-api/order/so/delete`, params, 'application/x-www-form-urlencoded');
};

// 取消订单
export const cancelOrder = params => {
  return api_ody.post(`/oms-api/so/cancel`, params);
};

// 确认收货
export const confirmReceiptOrder = params => {
  return api_ody.post(`/oms-api/order/so/confirmReceived`, params, 'application/x-www-form-urlencoded');
};

// 获取订单详情
export const getOrderDetail = params => {
  return api.post(`/ddjk-market/order/queryOrderDetail`, params);
};

// 再次购买
export const bugAgain = params => {
  return api.post(`/api/my/order/getOrderStockState`, params);
};

// 校验是否混合支付
export const checkIfMixedPay = params => {
  return api_ody.post(`/api/payment/checkPay`, params);
};

/** *** --start 购物车相关 接口 *****/

// 添加购物车
export const addCart = params => {
  return api_ody.post(`/trade/shopcart/v2/shopcarts`, params, 'application/json');
};

// 获取购物车列表
export const getCartList = params => {
  return api.post(`/api/cart/list`, params);
};

// 修改购物车数量
export const updateGoodsNum = params => {
  return api.post('/api/cart/editItemNum', params);
};

// 选中或取消
export const changeGoodsSelect = params => {
  return api.post('/api/cart/editItemCheck', params);
};

// 删除或批量删除
export const deleteCartItem = params => {
  return api.post('/api/cart/removeItemBatch', params);
};

// 清空失效商品
export const clearDisabledCart = params => {
  return api.post('/api/cart/clearFailure', params);
};

// 结算页清除下架、缺货商品

export const clearDisabledSettlePage = params => {
  return api.post('/api/checkout/removeProductOrder', params);
};

// 获取商品规格信息
export const getGoodsSpec = params => {
  return api_ody.fetch('/item/adapter/v1/pharmacy/sku/specification/' + params.channelSkuId);
};

// 获取疗程购信息
export const getTreatmentList = params => {
  return api_ody.fetch('/item/adapter/v1/item/expand/' + params.channelSkuId);
};

// 购物车切换规格
export const submitSpecChange = params => {
  return api_ody.put('/trade/shopcart/v2/shopcarts/spec', params);
};

// 购物车数量重选
export const resetCartNum = params => {
  return api.post('/api/cart/repack', params);
};

// 购物车去结算
export const submitCart_check = params => {
  return api.post('/api/cart/verify', params);
};

// o2o购物车起送门槛校验
export const submitCart_freightVerify = params => {
  return api.post('/api/cart/freightVerify', params);
};

// 初始化提交订单
export const initOrder = params => {
  return api.post('/api/checkout/initOrder', params);
};
// 提交订单页面修改商品数量
export const changeNum = params => {
  return api.post('/api/checkout/saveOrderMpNum', params);
};
// 预保存配送方式
export const saveDeliveryMode = params => {
  return api_ody.post('/api/checkout/saveDeliveryMode', params);
};

// 获取购物车总数量
export const getCartNums = params => {
  return api_ody.fetch('/trade/shopcart/v2/shopcarts/item/catalog/count', params);
};

/** *** --start 地址相关接口 *****/

// 获取地址列表
export const getAddressList = params => {
  // return api_ody.post('/ouser-web/address/queryUserChannelAddressList',params, 'application/x-www-form-urlencoded')
  return api.post('/ddjk-market/market/userAddress/getUserAddressList', params);
};

// 新增地址
export const addAddress = params => {
  // return api_ody.post('/ouser-web/address/saveUserAddress', params, 'application/x-www-form-urlencoded')
  return api.post('/ddjk-market/market/userAddress/saveUserAddress', params);
};

// 修改地址
export const editAddress = params => {
  return api.post('api/checkout/updateUserAddress', params);
};

// 删除地址
export const deleteAddress = params => {
  return api_ody.post('/api/checkout/deleteUserAddress', params);
};

// 获取省市区数据
export const getAreaData = () => {
  // return api_ody.post('/api/location/areaList')
  return api.post('/ddjk-market/location/areaList');
};

// 获取四级地址数据
export const getAreaByCode = params => {
  // return api_ody.post('/api/location/getAreaListByParentCode',params,'application/x-www-form-urlencoded')
  return api.post('/ddjk-market/location/getAreaListByParentCode', params, 'application/x-www-form-urlencoded');
};

// 获取四级地址数据
export const getStreeByCode = topId => {
  // return api_ody.post('/api/location/getAreaListByParentCode',params,'application/x-www-form-urlencoded')
  return api.post(`/ddjk-market/location/getAreaListByParentCode?topId=${topId}`);
};
// 新增O2O地址检查电子围栏
export const checkAddress = params => {
  // return api_ody.post('/ouser-web/address/checkAddressInElectricFence.do',params,'application/x-www-form-urlencoded')
  return api.post('/ddjk-market/market/storeInfo/checkAddressInElectricFence', params);
};
// 地址预保存
export const saveReceiver = params => {
  return api.post('/api/checkout/saveReceiver', params);
};
/** *** --end 地址相关接口 *****/

// 发票补开
export const supplementalInvoice = params => {
  return api_ody.post('/oms-api/order/invoice/reopen', params);
};
// 通过订单查询发票接口
export const detailsInvoice = params => {
  return api_ody.post('/oms-api/order/invoice/lookup', params);
};

// 正常商品立即购买
export const buyNow = params => {
  return api_ody.post('/trade/shopcart/v2/shopcarts/buy', params);
};

// 提交订单
export const submitOrder = params => {
  console.log(getLonAndLat('user_latitude'), 'lat');
  console.log(getLonAndLat('user_longitude'), 'lon');
  return api.post(`/api/checkout/submitOrder`, params, '', {
    'jk-user-lat': getLonAndLat('user_latitude') || '',
    'jk-user-lon': getLonAndLat('user_longitude') || '',
    orderChannel: '124'
  });
};

// 预保存保险类信息(调结算接口之前调用)奔思
export const saveInsurance = params => {
  return api.post(`/api/checkout/saveInsurance`, params);
};

// 预保存处方笺信息（调结算接口之后调用）奔思
export const saveOrderRxCustom = params => {
  return api.post(`/api/checkout/saveOrderRx`, params);
};

// 结算、提交订单扩展返回接口(调结算、提交订单接口之后调用)奔思
export const resultExtend = params => {
  return api.post(`/api/checkout/resultExtend`, params);
};

// 拼团结算初始化接口
export const groupBuyOrder = params => {
  return api.post(`/api/checkout/initGroupBuyOrder`, params);
};
// 拼团结算提交订单接口
export const submitGroupBuyOrder = params => {
  return api_ody.post(`/api/checkout/submitGroupBuyOrder`, params);
};

// 待收货-->查看物流
export const logistics = params => {
  return api_ody.post(
    `/oms-api/order/so/newOrderMessage?orderCode=${params.orderCode}&packageCode=${params.packageCode}`,
    params
  );
};
// 支付页面初始化获取支付方式
export const getPayGateway = params => {
  return api.post('/api/checkout/getPayGateway', params);
};
// 发起支付
export const createPay = params => {
  return api.post('/api/cashier/createPay', params);
};
// 预保存发票
export const saveOrderInvoice = params => {
  return api.post('/api/checkout/saveOrderInvoice', params);
};
// 查支付状态
export const queryPayStatus = params => {
  return api.post('/api/payment/frontier/queryPayOrder', params);
};
// 获取app下载链接
export const downloadUrl = params => {
  return api_ody.post('/ouser-web/app/download/url', params);
};
// 处方筏预保存接口
export const saveOrderRx = params => {
  return api.post('/api/checkout/saveOrderRx', params);
};
// 获取appid
export const getAppId = params => {
  if (versions().isMiniProgram) return Promise.resolve({});
  return api.post('/opay-web/getWxpayAppId.do', params);
};

/**
 * 获取拼团商品列表数据
 * @param {*} params
 */
export const fetchGroupList = params => {
  return api_ody.post(
    `/api/patchgroupon/getAllPatchGrouponInfoList?${qs.stringify(params)}`,
    null,
    'application/x-www-form-urlencoded'
  );
};

/**
 * 获取当前商品正在进行中的团单列表
 * @param {*} params
 */
export const fetchGroupOrderList = params => {
  return api_ody.post(`/api/patchgroupon/getGrouponInstInfo?${qs.stringify(params)}`);
};

/**
 * 获取拼团详情
 * @param {*} id
 */
export const fetchGroupOrderDetail = id => {
  return api_ody.post(`/api/patchgroupon/getGrouponInstanceInfoById?instanceId=${id}`);
};

/**
 * 校验拼团活动有效性
 * @param {*} params
 */
export const checkGroupBuyIsValid = params => {
  return api_ody.fetch(`/api/patchgroupon/checkPatchGrouponValidate?${qs.stringify(params)}`);
};

/**
 * 下拼团订单
 * @param {*} params
 */
export const startGroupOrder = params => {
  return api.post('/api/checkout/initGroupBuyOrder', params);
};

/**
 * 获取拼团首页数据
 * @param {*} options
 * 平台id 1 PC 2 H5 3 APP 4 TV 5 Applet 6 POS
 */
export const fetchAdData = options => {
  const queryParams = {
    platform: 2,
    pageCode: 'PINTUAN_PAGE',
    adCode: 'P0101,P0102,P0103,P0104,P0105,P0106,P0107,P0108,P0109,P0110',
    ...options
  };
  // const params = {
  //   ...options,
  // };
  return api_ody.post('/ad-whale-web/dolphin/getAdSource', queryParams);
};

// 24小时拼团预告列表--商品列表
export const trailerList = params => {
  return api.post('/ddjk-market/search/customerSearch/noticeGroupwork', params);
};

/**
 * 获取微信公众号签名
 * mb2c-mall.test.jk.com/third-auth/wechat/getJsApiConfig?appId=wx8668c5cfd0049dc1&url=http://mb2c-mall.test.jk.com
 */

export const getWxOfficialAccount = params => {
  console.log(process.env.VUE_APP_HOST, 'process.env.VUE_APP_HOST');
  // const params = {
  //   appId: wxAccountId,
  //   url: host,
  // };
  return api_ody.post('/third-auth/wechat/getJsApiConfig', params);
};
export const getWxOfficialAccounts = url => {
  console.log(url);
  //   return api_ody.fetch(`/api/weixin/getSign?url=${url}`);
  return api.get(`/ddjk-market/market/wechat/getSign?url=${url}`);
};

/** *** --start 商品详情接口 *****/

export const getGoodsInfo = params => {
  return api.post('api/checkout/promotionAndProductByID', params);
};

// 查询价格库存
export const getGoodsPrice = params => {
  console.log('wdssadsa', params);
  // return api_ody.fetch('/api/realTime/getPriceStockList',params)
  return api.post('/ddjk-market/price/getPriceStockList', params);
};

// 服务门店的省市区
export const getShopCodeList = params => {
  return api_ody.post('/back-product-web2/extra/serviceShop/getshopAddressCodeList.do', params);
};

// 服务门店列表
export const getServiceShopList = params => {
  return api_ody.post('/back-product-web2/extra/serviceShop/getShopListByParam.do', params);
};

// 加入购物车
export const btnAddToCart = params => {
  return api.post('/api/cart/addItem', params);
};

// 获取购物车数量
export const getCartNum = params => {
  return api.post('/api/cart/demand/count', params).then(res => {
    store.commit(`groupBuy/${UPDATE_SHOPPING_CART_NUMBER}`, res.data);
    return Promise.resolve(res);
  });
};
// 获取购物车数量(旧接口)
export const getCartNumOld = params => {
  return api.post('/api/cart/count', params).then(res => {
    return Promise.resolve(res);
  });
};

// 获取店铺运费
export const getShopFreight = params => {
  return api_ody.fetch('/oms-api/freight/queryFreight', params);
};

// 拼团商品详情
// export const getPatchGrouponInfo = (params) => {
//     return api_ody.fetch(`/api/patchgroupon/getPatchGrouponInfoById?${qs.stringify(params)}`)
// }

// 拼团商品详情
export const getPatchGrouponInfo = params => {
  return api.get(`/ddjk-market/promotion/groupon/getPatchGrouponInfoById?${qs.stringify(params)}`);
};

// 获取优惠券列表
export const getCouponList = params => {
  return api.post(`/ddjk-market/promotion/coupon/couponThemeListMpidsReceive`, params);
};

// 获取配送地址
export const getProductAddress = params => {
  return api.post(`/ddjk-market/market/userAddress/queryProductDetailAddressList`, params);
};

// 检验当前配送地址是否在范围
export const checkProductAddress = params => {
  // return api_ody.post(`/api/social/read/product/salesArea/isAllow`,params)
  return api.post(`/ddjk-market/market/product/salesArea/isAllow`, params);
};

// 疗程购
export const getProductSpecList = params => {
  // return api_ody.post(`/api/realTime/getSerialProductByParam.do`,params)
  return api.post(`/ddjk-market/market/realTime/getSerialProductByParam`, params);
};

// 获取购物车优惠券列表
export const getCartCouponList = params => {
  return api.post(`/ddjk-market/promotion/coupon/couponThemeListStoreIdsReceive`, params);
};

// 领取优惠价
export const receiveCoupon = params => {
  return api.post(`/ddjk-market/promotion/coupon/receiveCoupon`, params);
};

// 24小时拼团预告列表--广告轮播
export const trailerAd = params => {
  return api_ody.post('/ad-whale-web/dolphin/getAdSource', params);
};

/**
 * 选中优惠券
 * @param {*} ids // 多个用,分隔
 */
export const selectCoupon = ids => {
  return api.post('/api/checkout/saveCoupon', { couponIds: ids });
};

/**
 * 结算页购物车查询
 * @param {*} options
 * @returns
 */
export const showOrder = params => {
  // 因中台接口文档给错参数，2021.06.09修改
  // {"businessType":1,"grouponId":235,"receiverId":"2104080000005350","skus":[\{"mpId":40382,"num":1,"serviceShopId":0,"storeId":4}]}
  return api.post('/api/checkout/showOrder', params);
};

/**
 * 登出
 * @returns
 */
export const odyLogout = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
  const ut = Object.keys(userInfo).length ? userInfo['ut'] : '';
  const params = {
    ut
  };
  return api_ody.post('/ouser-web/mobileLogin/exit.do', params);
};

// 一级类目查询分类
export const searchOneClassList = params => {
  return api_ody.post(`/back-product-web2/extra/category/listChildrenCategoryWithNologin.do`, params);
};
// o2o店铺相关接口
// getCartCouponList 获取优惠券
// fetchStoreDetail  店铺信息
// getShopFreight    获取运费
// getCategory       全部分类   http.js
// getCartList       购物车列表
// searchOneHour     一小时达搜索  http.js
// btnAddToCart      加入购物车
// deleteCartItem    删除或批量删除
// updateGoodsNum    修改购物车数量
// changeGoodsSelect 选中或取消

/**
 * 获取店铺详情
 * @param {*} id 店铺id
 * @returns
 */
export const fetchStoreDetail = (id = '') => {
  const params = {
    storeId: id
  };
  return api.post('/ddjk-market/market/storeInfo/getStoreOrgDetailById', params);
};

/**
 * 处方药合规
 * @returns
 */
// 获取药品对应疾病标签
export const queryDiseaseBySpuIds = params => {
  return api_ycyl.get(`/ddapi/external/queryDiseaseBySpuIds?${qs.stringify(params)}`);
};
// 疾病搜索
export const queryDiseaseByName = params => {
  return api_ycyl.get(`/ddapi/external/queryDiseaseByName?${qs.stringify(params)}`);
};
// 获取链接
export const fetchUrlByOrderId = (code, inquiryId) => {
  if (inquiryId) {
    return api_ycyl.get(`/ddapi/external/queryUrlByOrderCode?orderCode=${code}&businessChannelId=${inquiryId}`);
  }
  return api_ycyl.get(`/ddapi/external/queryUrlByOrderCode?orderCode=${code}`);
};

// 秒杀活动详情-获取时间段
export const timeListApi = params => {
  return api.get(`/ddjk-market/promotion/secondkill/timeList?${qs.stringify(params)}`);
};

// 秒杀活动详情-获取时间段内活动商品
export const killListApi = params => {
  return api.get(`/ddjk-market/promotion/secondkill/killList?${qs.stringify(params)}`);
};

// 预保存推广引流（地推）
export const saveDistribution = params => {
  return api.post(`/api/checkout/saveDistribution`, params);
};
// 记录疫情信息登记接口
export const recordEdipemicInfo = params => {
  //   return api_ody.post('/api/checkout/queryEpidemicDrugUseInfo',params)
  return api_ody.post('/api/checkout/saveEpidemicDrugUseInfo', params);
};
// 查询疫情信息登记接口
export const fetchEdipemicInfo = id => {
  return api_ody.post(`/api/checkout/getEpidemicDrugUseInfoVo?id=${id}`);
};
// 购物车添加赠品
export const updateGift = params => {
  return api.post(`/api/cart/updateGift`, params);
};
export const getPromotionDetail = params => {
  return api.get(`/ddjk-market/promotion/promotionDetail?${qs.stringify(params)}`);
};

export const getCartExt = params => {
  return api.post(`api/cart/ext`, params);
};
export const batchGetStorePromotionsIconText = params => {
  return api.post(`/ddjk-market/promotion/batchGetStorePromotionsIconText`, params);
};
// 商详页促销聚合页搜索
export const getPromotionAggList = params => {
  if (params.type && params.type === 'internal') {
    return api.post('searchmain/market/internalAggList', params, {
      headers: {
        'jk-app-id': '124'
      }
    });
  }
  return api.post('searchmain/market/promotionAggList', params, {
    headers: {
      'jk-app-id': '124'
    }
  });
};
// 查询优惠券
export const fetchCouponThemeListDiseaseReceive = params => {
  return api_ody.post('/api/promotion/coupon/couponThemeListDiseaseReceive', params);
};
// 一键领取优惠券
export const receiveCouponList = params => {
  return api_ody.post('/api/promotion/coupon/receiveCouponList', params);
};

// 订单支付结果(查支付状态）
export const queryPayOrder = params => {
  return api.post(`/api/payment/frontier/queryPayOrder.do`, params);
};
// 合规证件照接口
export const getMerchantOrgCertificate = params => {
  return api_ody.post(`/back-product-web2/extra/serviceShop/getMerchantOrgCertificate`, params);
};

/**
 * 普康宝-联合登录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const unionLogin = params => {
  return api.post(`/open-mall/open/mall/auth/unionLogin`, params);
};
/**
根据tokenKey换取登录信息
* @param tokenKey
*/
export const tokenLogin = params => {
  return api.get(`/open-mall/open/mall/auth/getLoginData?tokenKey=${params}`);
};
export const loginByTokenKey = params => {
  return api.get(`/open-mall/open/mall/auth/getLoginData?tokenKey=${params}`);
};

/**
 * 根据accessKey换取登录信息
 * @param accessKey
 */
export const accessLogin = accessKey => {
  return api.get(`/insurances/cloud/gate/middlePageLogin/get/${accessKey}`);
};

/**
 * 根据处方单号反查initOrder参数信息
 * @param orderKey
 */
export const getPrescriptionInfo = params => {
  return api.post(`/open-mall/open/mall/prescription/getPrescriptionInfo`, params);
};

/**
 * 获取全局配置
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCommonProperty = params => {
  return api.post(`/open-mall/open/mall/common/getCommonProperty`, params);
};
/**
 * 确认订单页
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPrescriptionInfoUrl = params => {
  return api.post(`/open-mall/open/mall/prescription/getPrescriptionInfoUrl`, params);
};

// 疫情药登记字典省市区列表
export const epidemicAreaList = provinceCode => {
  // return api_ody.post(`/api/location/epidemicAreaList?provinceCode=${provinceCode}`)
  return api.post(`/ddjk-market/location/epidemicAreaList?provinceCode=${provinceCode}`);
};
// 疫情药登记根据code查询下级code
export const epidemicAreaListByParentCode = topId => {
  // return api_ody.post(`/api/location/getEpidemicAreaListByParentCode?topId=${topId}`)
  return api.post(`/ddjk-market/location/getEpidemicAreaListByParentCode?topId=${topId}`);
};

export const temporarySaveToken = params => {
  return api.post('auth/miniapp/temporarySaveToken', params);
}; // 获取短链token

// o2o自提获取默认地址手机号
export const getUserO2ODefaultAddress = topId => {
  // return api_ody.post(`/api/realTime/getUserO2ODefaultAddress`)
  return api.post(`/ddjk-market/market/userAddress/getUserO2ODefaultAddress`);
};

// 定位中全部城市带字母分类A、B、C
export const allCity = () => {
  return api.get('basic/sys/standardArea/allCity');
};
// 热门城市
export const hotCity = () => {
  return api.get(`medical/appointment/hospital/hotCity`);
};
// 前缀匹配城市
export const preMatch = name => {
  return api.get(`basic/sys/standardArea/queryCityByPrefix?name=${name}`);
};

// 小程序对接支付-结算页查询当前渠道三方小程序所对应的结算中间页地址
// /open-mall/open/mall/config/getConfigInfo
export const getThirdPayMidPage = params => {
  return api.post('/open-mall/open/mall/config/getConfigInfo', params);
};

// 购药清单列表获取
export const drugList = params => api.post(`/api/prescription/drugList`, params);

// 问诊开方
export const interview = params => api.post(`/api/prescription/interview`, params);

// 获取订单结算参数信息
export const getOrderParamInfo = params => api.post(`/open-mall/open/mall/order/getOrderParamInfo`, params);

// 获取处方图片
export const getRxImg = id => {
  return api.post(`/ddjk-market/prescription/getPrescriptionInfoUrl`, { prescriptionId: id });
};

// 获取问诊消息列表
export const BuyMedicineMessageList = params =>
  api.post(`/community/messageBox/queryInquiryBuyMedicineMessageList`, params);

// 查询消息盒子总的未读数量
export const querySumUnreadByTemplateTypes = params =>
  api.post(`/community/messageBox/querySumUnreadByTemplateTypes`, params);

// 根据流水号查询url
export const queryUrlByOrderCode = params =>
  api_ycyl.get(`/ddapi/external/queryUrlByOrderCode?${qs.stringify(params)}`);

/**
 * 根据应用获取渠道
 * @param {*} params
 * @returns
 */
export const getApplicationInfo = params => {
  // {
  //   "appKey": "",
  //   "sign": "",
  //   "time": ""
  // }
  return api.post(`/open-mall/open/mall/auth/getApplicationInfo`, params);
};

// 售后列表
export const soReturnList = params =>
  api.post(`/oms-api/order/soReturn/list`, params, {
    headers: {
      channelCodes: getChannelCode().split(',')
    }
  });

// B2C申请售后列表页
export const applyList = params => api.post(`/oms-api/order/soReturn/applyList`, params);

// 查询订单余额支付金额
export const getBalance = params => {
  return api.get(`/open-mall/open/mall/order/query/pay/balance/amount?orderCode=${params}`);
};

// 1仅退款未发货的售后原因，2仅退款已发货的售后原因，3退款退货的售后原因，4仅退款已发货-已收到货
export const getReturnReason = params => api.get(`/oms-api/front/dictBuConfig/reason/item/list?typeCode=${params}`);

// 提交售后单
export const addReturn = params => api.post(`/oms-api/order/soReturn/addReturn`, params);

// 上传申请售后图片
export const commonUploadFile = (key, file) => {
  return api.post(`/common-image/front/upload/file?bucket=ddjk-resource-public&key=${key}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
      sysCode: 'YW01'
    }
  });
};

// 售后工单详情查询/售后工单(退货)
export const applyDetail = params => api.post(`/oms-api/order/soReturn/detail`, params);

// 售后工单撤销
export const soReturnCancel = params => api.get(`/oms-api/soReturn/cancel?id=${params}`);

// 售后评价
export const commentEvaluate = params => api.post(`/oms-api/order/soReturn/comment`, params);

// 售后协商历史查询
export const handleHistory = params => api.post(`/oms-api/order/soReturn/handleHistory`, params);

// 售后物流公司信息
export const getLogisticsCompany = () => api.get(`/oms-api/public/order/code/listByCategory/EXPRESS_CONFIG`);

// 售后工单填写回寄信息提交
export const editLogistics = params => api.post(`/oms-api/order/soReturn/editLogistics`, params);

// 校验开方药品库存
export const checkProduct = params => api.post(`/api/prescription/checkProduct`, params);
export const haveAdded = params => api.post(`/ddjk-market/kf/workOrder/haveAdded`, params);
// 邮费规则
export const cartListDetail = params => {
  return api.post(`/api/cart/listDetail`, params);
};

// 首页导航获取最近的处方
export const getLatestPrescriptionInfo = params =>
  api.post(`/ddjk-market/prescription/getLatestPrescriptionInfo`, params);

// 获取APPId对应渠道
export const getChannelInfoByAppKey = params =>
  api.get(`/open-mall/open/mall/common/getChannelInfoByAppKey?appKey=` + params);

// 获取用户协议
export const getUserAgreement = params => api.post(`/cms/contract/query`, params);
// 优惠券促销商品聚合页v2
export const searchCouponV2 = params => api.post(`/searchmain/market/searchCouponV2`, params);

// 获取用户地址
export const getUserAddressList = params => api.post(`/ddjk-market/market/userAddress/getUserAddressList`, params);

// log日志
export const addApiInterfaceInvokeRecord = params => {
  return api.post(`/open-mall/open/mall/interfaceRecord/addApiInterfaceInvokeRecord`, params);
};

/**
 * 查询就诊人列表
 */
export const getPatientList = () => {
  const userInfo = JSON.parse(localStorage.getItem('unionLoginData')) || {};
  const userId = userInfo?.loginUserResp?.userId || '';
  return api.post(`/ddjk-market/patients/queryPatientList`, null, {
    headers: {
      current_user_id: userId
    }
  });
};

// 三方余额支付（星藤）
export const queryThirdOrderPay = params => {
  return api.post(`/open-mall/open/mall/order/third/order/pay`, params);
};

// 保单详情--统计、就诊人、保险说明
export const insurancePolicyDetail = params => {
  return api.get(`/api/trade/insurance/policy/detail?policyCode=${params}`);
};

// 门诊险-问诊开方
export const insurancePolicyStart = params => {
  return api.post(`/api/trade/insurance/inquiry/start`, params);
};

// 门诊险-便捷购药记录/问诊记录查询
export const insurancePolicyRecord = params => {
  return api.post(`/api/trade/insurance/policy/record`, params);
};

// 查询类目信息（三级）
export const childrenCategoryList = params => api.get(`/ddjk-market/store/category/list?storeId=${params}`);

// 查询类目下商品
export const listCategoryProduct = params => api.post(`/ddjk-market/market/realTime/listCategoryProduct`, params);

// 门诊险-便捷购药 更新购物车商品选中状态接口
export const editCartItemCheck = params => {
  return api.post(`/api/trade/insurance/cart/editItemCheck`, params);
};

// 门诊险-便捷购药 更新购物车商品数量接口
export const editCartItemNum = params => {
  return api.post(`/api/trade/insurance/cart/editItemNum`, params);
};

// 门诊险-便捷购药 查看购物车列表接口
export const insuranceCartList = params => {
  return api.post(`/api/trade/insurance/cart/list`, params);
};

// 门诊险-便捷购药 风控接口用户是否可“便捷购药
export const quickPurchase = params => {
  return api.get(`/open-mall/open/mall/insurance/policy/risk/quick/purchase?policyCode=${params}`);
};

// 门诊险-便捷购药 风控详情查询
export const policyRiskDetail = params => {
  return api.get(`/open-mall/open/mall/insurance/policy/risk/detail?policyCode=${params}`);
};

// 门诊险-便捷购药 提交订单
export const insuranceSubmitOrder = params => {
  return api.post(`/api/trade/insurance/submitOrder`, params);
};

// 查询星藤账号的余额
export const queryThirdPayBalance = params => {
  return api.get(`/open-mall/open/mall/order/query/third/pay/balance?cardNo=${params}`);
};

// 根据订单号查询星藤支付信息（获取现金支付金额）
export const queryThirdOrderRecord = (orderCode, cardNo) => {
  return api.get(`/open-mall/open/mall/order/validate/third/pay?orderCode=${orderCode}&cardNo=${cardNo}`);
};

// 获取德华安顾渠道用户商城集合信息
export const getMallList = params => {
  return api.post(`/open-mall/open/mall/insurance/dhag/query/mall`, params);
};

// 获取德华安顾渠道用户健康直付卡余额
export const getDHAGUserBalance = params => {
  return api.post(`/open-mall/open/mall/insurance/dhag/get/policy/balance`, params);
};

// 德华安顾渠道用户健康直付卡余额支付
export const getDHAGBalancePay = params => {
  return api.post(`/open-mall/open/mall/insurance/dhag/order/pay`, params);
};
