<template>
  <div v-if="show" class="jk-head">
    <!-- 定位头会一直存在 -->
    <van-nav-bar
      v-if="$attrs.slideHead"
      class="fixedHead"
      v-bind="$attrs"
      fixed
      :style="{ opacity: opacity, '--height': height + 'px' }"
      v-on="$listeners"
    >
      <template v-if="$slots.fixedLeft" #left>
        <slot name="fixedLeft"></slot>
      </template>
      <template v-else #left>
        <slot name="left"></slot>
      </template>
      <!-- 定位头部左插槽，未传与非定位头部保持一致 -->
      <template v-if="$slots.fixedTitle" #title>
        <slot name="fixedTitle"></slot>
      </template>
      <template v-else #title>
        <slot name="title"></slot>
      </template>
      <!-- 定位头部中插槽，未传与非定位头部保持一致 -->
      <template v-if="$slots.fixedRight" #right>
        <slot name="fixedRight"></slot>
      </template>
      <template v-else #right>
        <slot name="right"></slot>
      </template>
      <!-- 定位头部右插槽，未传与非定位头部保持一致 -->
    </van-nav-bar>

    <van-nav-bar v-bind="$attrs" class="normalHead" :style="{ '--height': height + 'px' }" v-on="$listeners">
      <template #left>
        <slot name="left"></slot>
      </template>
      <template #title>
        <slot name="title"></slot>
      </template>
      <template #right>
        <slot name="right"></slot>
      </template>
    </van-nav-bar>
    <div v-if="$attrs.fixed === '' || $attrs.fixed" class="fillDiv" :style="{ '--height': height + 46 + 'px' }"></div>
    <!-- 非定位头部 -->
  </div>
</template>

<script>
import { NavBar, Icon } from 'vant';
import { getStatusBarHeight, versions } from '@/utils';
import { navBack } from '@/utils/bridge';
export default {
  name: 'JkHeader',
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon
  },
  props: {
    initStatusBarHeight: {
      type: [Function, Number],
      default: getStatusBarHeight()
    },
    hideFromTopDistance: {
      type: Number,
      default: 90,
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return value >= 66; // 最小头部高度
      }
    },
    gradualChange: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    // 解决地址栏传了hideTitle，双导航头隐藏，导航头中有搜索框header被隐藏
    hideTitleShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      opacity: 0,
      height: 20, // 填充信号栏高度
      show: true
    };
  },
  mounted() {
    // if (this.showHeader) {
    //   this.show = true;
    // } else if (!this.showHeader) {
    //   if (versions().isInApp) {
    //     this.show = true;
    //   } else if (versions().isWeChat || !versions().isNormalApp) {
    //     this.show = false;
    //   }
    // } else {
    //   this.show = true;
    // }
    // 三方app双头问题
    const queryHideTitle = localStorage.getItem('hideTitle') || '';
    const hideTitle = (queryHideTitle != 'undefined' && queryHideTitle != 'null' && queryHideTitle) || '';
    if (this.hideTitleShow) {
      this.show = true;
    } else if (hideTitle) {
      this.show = false;
    } else if (this.showHeader || versions().isInApp) {
      this.show = true;
    } else if (versions().isWeChat || versions().isAliApp || versions().isDingTalk || !versions().isNormalApp) {
      this.show = false;
    }
    this.navBack(this.$listeners['click-left']);
    typeof this.initStatusBarHeight === 'function'
      ? (this.height = this.initStatusBarHeight())
      : (this.height = this.initStatusBarHeight);
    if (this.$attrs.slideHead) {
      if (this.className) {
        document.querySelector('.' + this.className).addEventListener('scroll', this.handelScroll);
      } else {
        document.addEventListener('scroll', this.handelScroll);
      }
    }
  },
  destroyed() {
    document.removeEventListener('scroll', this.handelScroll);
  },
  methods: {
    navBack,
    versions,
    getStatusBarHeight,
    handelScroll() {
      // const scrollHeight = document.body.scrollHeight || document.documentElement.scrollHeight;
      // const clientHeight = document.body.clientHeight || document.documentElement.clientHeight;
      // if (scrollHeight <= clientHeight) {
      //   this.opacity = 0;
      //   return;
      // }

      let scrollTop = 0;
      if (this.className) {
        scrollTop = document.querySelector('.' + this.className).scrollTop;
      } else {
        scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      }
      // const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const initHeight = this.height + 46;
      // if (this.hideFromTopDistance <= initHeight) {
      //   this.opacity = 1;
      //   this.$emit("opacity", this.opacity);
      //   return;
      // }
      if (scrollTop >= this.hideFromTopDistance) {
        this.opacity = 1; // hideFromTopDistance  定位头完全显示
      } else if (scrollTop > initHeight && scrollTop < this.hideFromTopDistance) {
        this.opacity = this.gradualChange ? (scrollTop - initHeight) / (this.hideFromTopDistance - initHeight) : 0;
      } else {
        this.opacity = 0;
      }
      // console.log(scrollTop, this.opacity, this.hideFromTopDistance, "this.hideFromTopDistance");
      this.$emit('opacity', this.opacity); // 父组件需要隐藏元素时用到
    }
  }
};
</script>

<style lang="scss" scoped>
.jk-head {
  box-sizing: unset;
  ::v-deep .fixedHead {
    padding-top: var(--height);
    .van-nav-bar__content {
      width: 100%;
      background: #f2f2f2;
      .van-nav-bar__left {
        i {
          width: 10px;
        }
        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3a3a3a;
        }
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .van-nav-bar__right {
        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3a3a3a;
        }
      }
    }
  }
  ::v-deep .normalHead {
    background: transparent;
    padding-top: var(--height);
    z-index: 101;
    .van-nav-bar__content {
      width: 100%;
      .van-nav-bar__left {
        padding: 0 12px;
        i {
          width: 10px;
        }
        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3a3a3a;
        }
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333;
      }
      .van-nav-bar__right {
        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3a3a3a;
        }
      }
    }
  }
  ::v-deep .van-nav-bar--fixed {
    background: #f2f2f2;
    width: 100%;
    z-index: 100;
    .van-nav-bar__content {
      .van-nav-bar__title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333;
      }
    }
  }
  .fillDiv {
    // height: 46px;
    height: var(--height);
    box-sizing: unset;
  }
  // 修改分享按钮大小
  ::v-deep .icon-fenxiang {
    font-size: 22px;
  }
}
::v-deep .van-nav-bar {
  line-height: unset;
}
</style>
