import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';
import '@/projects/mall/filter';
import '@/projects/mall/assets/style/reset.scss';
import '@/projects/mall/assets/style/common.scss';
import '@/assets/style/index.scss';
import '@/utils/rem';
import '@/utils/adaptInput';
import { jkRxMark, jkOtcMark, jkMarketingCampaign, jkInternalPurchasePrice } from 'jk-ui';
import commonUtilsMixin from '@/projects/mall/mixins/commonUtils.js';
import Global from '@/projects/mall/utils/common.js';
Vue.prototype.Global = Global;

import Bridge from '@/projects/mall/utils/dsbridge.js';
Vue.prototype.$bridge = Bridge;

import { Loading } from 'vant';

Vue.use(Loading);

// 数据字典

import { Toast, Icon } from 'vant';
Toast.setDefaultOptions('loading', { forbidClick: true, loadingType: 'spinner' });
Vue.use(Toast);
Vue.use(Icon);

import Header from '@/components/jk-header';
import JkImage from '@/components/jk-image/index.vue';
// import { JkImage } from 'jk-ui';
// window.createScript("https://res.wx.qq.com/open/js/jweixin-1.6.0.js");

if (navigator.userAgent.indexOf('AlipayClient') > -1) {
  // 支付宝小程序的 JS-SDK 防止 404 需要动态加载，如果不需要兼容支付宝小程序，则无需引用此 JS 文件。
  window.createScript('https://appx/web-view.min.js');
}

JkImage.install = Vue => {
  Vue.component(JkImage.name, JkImage);
};
Vue.use(Header);
Vue.use(JkImage);
Vue.component('internalPurchasePrice', jkInternalPurchasePrice);
Vue.component('RxMark', jkRxMark);
Vue.component('OtcMark', jkOtcMark);
Vue.component('marketingCampaign', jkMarketingCampaign);
import loginPop from '@/components/LoginPop';
Vue.use(loginPop);

import { initStorageMethods } from '@/utils/storage.js';
initStorageMethods();

// 神策埋点
import Sensors from '@/projects/mall/utils/sensors.js';
Vue.prototype.$sensors_ddjk = Sensors(Vue);

Vue.config.productionTip = false;

Vue.mixin(commonUtilsMixin);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
