/* eslint-disable */
// import { Toast } from 'vant';
import wx from 'weixin-js-sdk';
const wxApi = wx || window.wx;
console.log('wxApi', wxApi);
import { versions } from '@/utils/index';
import { getSignature } from '@/api';
import { getWxOfficialAccounts } from '@/projects/mall/api/http_mall';

// import { upload, dataURLtoBlob } from '@/utils/upload';

const wxAccountId = process.env.VUE_APP_WX_APP_ID;
const host = encodeURIComponent(location.href.split('#')[0]);
const params = {
  url: host,
  appId: wxAccountId
};
console.log('params', params);

export const initWXConfig = async () => {
  if (!versions().isWeChat) return false;
  //兼容处理
  const fn = location.host.search(/(test3|test2)/g) > -1 ? getWxOfficialAccounts : getSignature;

  try {
    const res = await fn(host);
    const { data } = res.data;
    console.log('getWXConfig-------------------------------', data);
    wxApi.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: wxAccountId, // 必填，公众号的唯一标识
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonceStr, // 必填，生成签名的随机串
      signature: data.signature, // 必填，签名
      jsApiList: [
        'onMenuShareAppMessage',
        'updateAppMessageShareData',
        'onMenuShareTimeline',
        'chooseImage',
        'hideAllNonBaseMenuItem',
        'hideMenuItems',
        'uploadImage',
        'getLocalImgData',
        'showMenuItems',
        'updateTimelineShareData',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'pauseVoice',
        'stopVoice',
        'onVoicePlayEnd',
        'uploadVoice',
        'downloadVoice',
        'previewImage',
        'downloadImage',
        'translateVoice',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'showMenuItems',
        'showAllNonBaseMenuItem',
        'closeWindow',
        'scanQRCode',
        'chooseWXPay',
        'openProductSpecificView',
        'addCard',
        'chooseCard',
        'openCard'
      ] // 必填，需要使用的JS接口列表
    });
  } catch (error) {
    console.log('error', error);
  }
};

export default async function initWxShare(options1, options2) {
  // if (!versions().isWeChat && getQueryVariable('isDebug') != 'true') return false;
  console.log(options1, 'options1');
  console.log(options2, 'options2');

  await initWXConfig();

  wxApi.ready(function () {
    wxApi.miniProgram.getEnv(function (res) {
      if (!res.miniprogram) {
        // 隐藏右上角分享菜单 朋友圈/QQ/收藏/QQ空间/safari浏览器/复制链接 按钮
        // const hideList = ['menuItem:share:qq', 'menuItem:share:QZone','menuItem:openWithSafari',"menuItem:copyUrl"]
        const hideList = ['menuItem:copyUrl'];
        wxApi.hideMenuItems({
          // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
          menuList: hideList
        });

        //非小程序环境的分享
        // 【仅剩1个名额】我2.6元拼了999感冒灵 12*18袋。
        wxApi.updateAppMessageShareData({
          title: options1.title, // 分享标题
          desc: options1.desc, // 分享描述
          link: options1.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: options1.imgUrl, // 分享图标
          success: function (data1) {
            // 设置成功
            // alert('分享成功',data1);
            console.warn('分享到朋友圈成功返回的信息为:============', data1);
          },
          fail: function (error, data) {
            // alert('分享失败',error, data);
            console.error('error', error, data);
          },
          complete: function (res) {
            console.log(res, 'res');
          }
        });

        // 【多点拼购】2人成团仅需2.6元。999感冒灵12*18袋
        wxApi.onMenuShareTimeline({
          title: options2.title, // 分享标题
          desc: options2.desc, // 分享描述
          link: options2.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: options2.imgUrl, // 分享图标
          success: function (data, data2) {
            // 设置成功
            alert('分享成功', data, data2);
            console.log('分享到朋友圈成功返回的信息为:', res);
            // Toast("设置成功!");
          },
          fail: function (error, data) {
            console.log('分享失败', error, data);
          },
          complete: function (res) {
            console.log(res, 'res');
          }
        });
      }
    });
  });
  wxApi.error(function (error) {
    console.log(error, 'wxerrrrrr');
  });
}

// export async function chooseWxImage(fn) {
//   await initWXConfig();
//   const imageUrl = [];
//   let count = 0;
//   wxApi.chooseImage({
//     count: 9, // 默认9
//     sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
//     sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
//     success: async (res) => {
//       const localIds = res.localIds;
//       for (let id in localIds) {
//         wxApi.getLocalImgData({
//           localId: localIds[id], // 图片的localID
//           success: async (res) => {
//             var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
//             if (localData.indexOf('data:image') != 0) {
//               //判断是否有这样的头部
//               localData = 'data:image/jpeg;base64,' + localData;
//             }
//             localData = localData.replace(/\r|\n/g, '').replace('data:image/jpg', 'data:image/jpeg');
//
//             const blobData = dataURLtoBlob(localData);
//             const file = await upload(blobData, 'image');
//             count++;
//             imageUrl.push(file.signatureUrl);
//             if (count === localIds.length) {
//               fn(imageUrl, blobData);
//             }
//           },
//         });
//       }
//     },
//   });
// }

export async function hideMenuItems(other) {
  await initWXConfig();
  console.log('hideMenuItems');
  wxApi.ready(function () {
    const hideList = [
      'menuItem:share:timeline',
      'menuItem:share:qq',
      'menuItem:favorite',
      'menuItem:share:QZone',
      'menuItem:openWithSafari',
      'menuItem:copyUrl'
    ];
    const menuList = other ? ['menuItem:share:appMessage', ...hideList] : ['menuItem:copyUrl'];
    wxApi.hideMenuItems({
      // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
      menuList: menuList
    });
    wxApi.onMenuShareAppMessage({
      //分享给朋友
      title: '固定分享地址',
      desc: '测试分享朋友单独',
      link: location.href,
      imgUrl: '',
      success: function () {},
      cancel: function () {}
    });
  });
}
/**
 * 隐藏所有非基础类功能的入口
 */
export async function hideAllNonBaseMenuItem() {
  await initWXConfig();
  console.log('hideAllNonBaseMenuItem');
  wxApi.ready(() => {
    wxApi.hideAllNonBaseMenuItem();
  });
}

export async function showShareMenuItems() {
  await initWXConfig();
  console.log('showShareMenuItems');
  wxApi.ready(() => {
    wxApi.showMenuItems({
      //显示分享给朋友或者分享到朋友圈
      menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline']
    });
  });
}

/**
 * 隐藏复制链接按钮
 */
export async function hideShareMenuItems() {
  await initWXConfig();
  console.log('hideMenuItems--------------12');
  wxApi.ready(function () {
    wxApi.hideMenuItems({
      // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
      menuList: [
        'menuItem:share:timeline',
        'menuItem:share:qq',
        'menuItem:favorite',
        'menuItem:share:QZone',
        'menuItem:openWithSafari',
        'menuItem:copyUrl'
      ]
    });
  });
}
