export default {
  namespaced: true,
  state: {
    userLocation: {
      longitude: 116.2,
      latitude: 39.56,
      change: false
    },
    // 用户选择的城市
    userCity: {
      areaName: '北京市',
      areaCode: '110100000000'
    },
    // 用户定位的城市
    userLocationCity: {
      areaName: '北京市',
      areaCode: '110100000000'
    },
    isClickCity: false,
    // 用于存储当前店铺&店铺商品id
    saveStore: {
      storeId: '',
      productId: '',
      selected: false
    },
    storeCanUseNum: 0,
    commodityOrderTab: 0,
    isVoluntarily: true // 自动定位时是否赋值给用户选择的城市
    // channelCode: localStorage.getItem('channelCode') || '0000010005',
  },
  mutations: {
    SET_USER_LOCATION(state, data) {
      if (data) {
        state.userLocation = data;
      } else {
        state.userLocation = {
          longitude: 116.2,
          latitude: 39.56,
          change: true
        };
      }
    },
    SET_USER_CITY(state, data) {
      if (data) {
        state.userCity = data;
      } else {
        state.userCity = {
          areaName: '北京市',
          areaCode: '110100000000'
        };
      }
    },
    CHANGE_SAVESTORE: (state, data) => {
      state.saveStore = data;
    },
    SET_STORE_CAN_USE_NUM: (state, data) => {
      state.storeCanUseNum = data;
    },
    CHANGE_COMMODITYORDERTAB: (state, data) => {
      state.commodityOrderTab = data;
    },
    CHANGE_IS_CLICK_CITY: (state, data) => {
      state.isClickCity = data;
    },
    CHANGE_USER_LOCATION_CITY: (state, data) => {
      if (data) {
        state.userLocationCity = data;
      } else {
        state.userLocationCity = {
          areaName: '北京市',
          areaCode: '110100000000'
        };
      }
    },
    CHANGE_VOLUNTARILY: (state, data) => {
      state.isVoluntarily = data;
    }
    // SET_CHANNEL_CODE(state, value) {
    //   state.channelCode = value
    //   localStorage['channelCode'] = value
    // },
  },
  getters: {}
};
