export default [
  {
    path: '/coupon/list',
    name: 'CouponList',
    component: () => import('@mall/views/coupons/ProductCoupon.vue'),
    meta: {
      title: '优惠券'
    }
  },
  {
    path: '/coupon/invalid',
    name: 'InvalidCouponList',
    component: () => import('@mall/views/coupons/InvalidCoupon.vue'),
    meta: {
      title: '失效券'
    }
  },
  {
    path: '/couponPolymeride',
    name: 'CouponPolymeride',
    component: () => import('@mall/views/couponPolymeride/index'),
    meta: { title: '优惠券聚合页' }
  },
  {
    path: '/coupon/addressList',
    name: 'couponAddressList',
    component: () => import('@mall/views/couponPolymeride/addressList'),
    meta: { title: '选择收货地址' }
  },
  {
    path: '/coupon/storeList',
    name: 'couponStoreList',
    component: () => import('@mall/views/couponPolymeride/storeList'),
    meta: { title: '选择门店' }
  }
];
